import Header from '../front_page/header_front';
import Footer from '../front_page/footer'

import axios from 'axios'
import Select from 'react-select'
import { useState,useEffect } from 'react'
import { Journal_list } from '../front_page/Main'
const Publication_ethics = ()=>{
    let options=[
        {
            label:'All',value:'All'
        }
    ];
    let repeated_index = []

    const [indexing_obj,set_indexing_obj] = useState([])
    const[cat_array,set_cat_array] = useState([]);
    const [journal_obj,set_journal_obj] = useState([]);
    const [filter_cat,set_filter_cat] = useState('All');
    useEffect(()=>{
        window.show_loader()
        axios.all([
            axios.get(window.server_url+'/cats'),
            axios.get(window.server_url+'/journals/journal_intro'),
            axios.get(`${window.server_url}/indexing`)

        ])
        .then(axios.spread((...responses)=>{
            set_cat_array(responses[0].data);
            set_journal_obj(responses[1].data);
            set_indexing_obj(responses[2].data);
            window.hide_loader()

        }))
        .catch(error=>console.log(error))
    },[])
    cat_array.map(obj=>{
        let option_obj={};
        option_obj.label=obj.category;
        option_obj.value=obj.category;
        options.push(option_obj);
    })
    return(
        <div>
            <Header/>

            <div id="main_tile" className="is-parent is-ancestor tile">
            <div className='tile is-3 is-parent is-vertical'>
                    <div id='main_first_tile_child' className='notification is-white is-child box'>
                        <h1>Open Access Journals</h1>
                        <Select id='main_Select' options={options} onChange={e=>{
                            if(e.value==='All')
                                set_filter_cat(e.value)
                            else{
                                for(let i =0;i<cat_array.length;i++){
                                    if(e.value===cat_array[i].category){
                                        set_filter_cat(cat_array[i].id)
                                    }
                                }
                            }
                        } 
                        }/>
                        {
                        // displaying journal names with icons in a loop gotten from the data
                            journal_obj.map(obj=>{
                                if(filter_cat==='All')
                                    return <Journal_list key={obj.id} journal_icon={obj.id} journal_abbrevation={obj.journal_abbrevation} journal_slug={obj.journal_slug}/>      
                                else if(filter_cat===obj.journal_cat_id)
                                    return <Journal_list key={obj.id} journal_icon={obj.id} journal_abbrevation={obj.journal_abbrevation} journal_slug={obj.journal_slug}/>      
                        })
                            
                    }
                </div>
                <div id="indexing_container" className="is-child notification is-white box">
                    {
                        indexing_obj.length>0&&indexing_obj.map((index_obj,index)=>{
                            let unique_url = true
                            if(index!==0){
                                for(let i = 0;i<repeated_index.length;i++){
                                    if(repeated_index[i]===index_obj.url){
                                        unique_url = false
                                        break
                                    }
                                }
                            }
                            if(unique_url){
                                repeated_index.push(index_obj.url)
                                return(
                                    <a key={`${index} indexing`} href={index_obj.url}>
                                        <img src={`/resources/journal_indexing/${index_obj.file_name}`} alt="" />
                                    </a>
                                )
                            }
                        })
                    }
                </div>
            </div>
                <div className="tile is-parent">
                <div id="plagiarism_container">
            <div id="plagiarism_policy_container">
                <h1>PUBLICATION ETHICS</h1>
                <p>
                    <ul className='text_justify' id = 'bullet_list'>
                        <li>Addaiyan Journal of Medical Research is committed to following the code of conduct and best practice guidelines of COPE. It is the duty of editors to follow the COPE guidance.</li>
                        <li>Editors and peer-reviewers must follow COPE ethical guidelines for peer-reviews.  </li>
                        <li>Experimental research connecting human or animals, Institutional review board or ethics committee should approved by authors and this information should be mentioned in the paper including the name of the board/committee that contributed the approval.</li>
                        <li>If the manuscript contains photos or parts of photos of patients, informed consent from each patient should be obtained. </li>
                        <li>Patient's identities and privacy should be carefully protected in the manuscript. </li>
                        <li>If your research consent form did not specifically state this, please get it or remove the identifying material. </li>
                        <li>The editors will require that the benefits potentially derived from any research causing harm to animals are significant concerning any cost endured by animals and that procedures followed are unlikely to cause offense to the majority of readers.</li>
                    </ul>

                </p>
            </div>

        </div>
                </div>
            </div>
            <Footer/>

        </div>
        
    )
}

export default Publication_ethics