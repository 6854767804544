import axios from 'axios';
import {AiFillDelete} from 'react-icons/ai';
import { useEffect,useState } from 'react';

const Admin_menu = ()=>{
    sessionStorage.setItem('sub_page','admin_menu');
    const [user_data,set_user_data] = useState([]);
    const [list_update,set_list_update] = useState('');

    useEffect(()=>{
    window.show_loader();

        axios.get(window.server_url+'/users')
        .then(response=>{
            set_user_data(response.data);
        })
        .catch(error=>console.log(error));
        window.hide_loader(true);
    },[list_update])

    return(
        <div id="back_admin_user_container" >
            <form style={{backgroundColor:'#ef233c'}} onSubmit={(e)=>change_admin_pw(e,set_list_update)} id="admin_change_pw">
                <h1 className='is-size-4-mobile is-size-2-tablet'>Change Admin Password</h1>
                <input required={true} placeholder='new password' type="password" name="" id="" />
                <input required={true} placeholder='re-enter password' type="password" name="" id="" />
                <input className='button is-info' type="submit" value="Submit" />
                <div id="password_mismatch">Password does not match!</div>

            </form>
            <form onSubmit={e=>add_user(e,set_list_update)}  id="admin_change_pw">
                <h1 className='is-size-4-mobile is-size-2-tablet'>Add Users</h1>
                <input placeholder='user name' type="text" name="" id="" />
                <input placeholder='new password' type="password" name="" id="" />
                <input className='button is-info' type="submit" value="Submit" />

            </form>

            <div className='back_table_cont'>
                <h1 className='is-size-4-mobile is-size-2-tablet'>User List</h1>
                <table className='back_table'>
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Password</th>
                            <th colSpan='2'>Options</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            user_data.map((data,index)=>{
                                if(index===0){
                                    return(
                                        <tr key={`tr_${data.id}`}>
                                            <td>{data.user_name}</td>
                                            <td><input id={`input_${data.id}`}readOnly={true} type="password" value={data.password} /></td>
                                            <td onClick={(e)=>show_hide_pw(e,data.id)}><i className='fa fa-eye'></i> </td>
                                        </tr>
                                    )
                                }
                                else{
                                    return(
                                        <tr key={`tr_${data.id}`}>
                                        <td>{data.user_name}</td>
                                        <td><input id={`input_${data.id}`}readOnly={true} type="password" value={data.password} /></td>
                                        <td onClick={(e)=>show_hide_pw(e,data.id)}><i className='fa fa-eye'></i> </td>
                                        <td><button onClick={()=>remove_user(data.id,set_list_update)} className='button is-danger'><AiFillDelete/></button></td>
                                        </tr>
                                    )
                                }
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default Admin_menu;

function add_user(e,set_list_update){
    window.show_loader();
    e.preventDefault();
    const user_name = e.target[0].value;
    const password = e.target[1].value;
    const obj = {
        user_name:user_name,
        password:password
    }
    axios.post(window.server_url+'/users',obj)
    .then(response=>{
        e.target[0].value=null;
        e.target[1].value=null;
        set_list_update(response.data);
    })
    .catch(error=>console.log(error))
}
function remove_user(id,set_list_update){
    let is_delete = window.confirm('Do you really wanna delete?')
    if(is_delete){
        window.show_loader();

        axios.delete(`${window.server_url}/users/${id}`)
        .then(response=>{
            set_list_update(response.data.status);
        })
        .catch(error=>console.log(error));
    }
   
}
function change_admin_pw(e,set_list_update){
    e.preventDefault();
    const password = e.target[0].value;
    const re_enter = e.target[1].value;
    if(password!==re_enter){
        document.getElementById('password_mismatch').style.display='block';
        return 0;
    }
    window.show_loader();

    axios.put(window.server_url+'/users',{
        pw:password
    })
    .then(response=>{
        e.target[0].value=null;
        e.target[1].value=null;
        set_list_update(response.data);
    })
    .catch(err=>console.log(err)); 
}
function show_hide_pw(e,input_id){
    //e.target selects the child content of an element
    let current_icon = e.target.className;
    let input = document.getElementById(`input_${input_id}`);
    if(current_icon==='fa fa-eye'){
        e.target.className='fa fa-eye-slash';
        input.setAttribute('type','text');
        console.log('eye1')

    }
    else if(current_icon==='fa fa-eye-slash'){
        e.target.className='fa fa-eye';
        input.setAttribute('type','password');
    }
}