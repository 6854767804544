import axios from 'axios';
import '../../../css/back_office/journal_cats.css';
import {AiFillDelete,AiFillEdit} from 'react-icons/ai';
import { useEffect,useState } from 'react';



const Journal_cats = ()=>{

    const[cats,set_cats] = useState([]);
    const[cat_update,set_cat_update] = useState('');
    const[edit_cat_id,set_edit_cat_id] = useState();
    useEffect(()=>{
    window.show_loader();

        axios.get(window.server_url+'/cats')
        .then(response=>{
            set_cats(response.data);
            window.hide_loader(true);
        })
        .catch(error=>console.log(error))
    },[cat_update])
    return(
        <section id='journal_cats'>
            <form onSubmit={e=>add_cat(e,set_cat_update)} style={{backgroundColor:'#007f5f'}} id="admin_change_pw">
                <h1 className='is-size-4-mobile is-size-2-tablet'>Add Journal Categories</h1>
                <input placeholder='Journal categorie name' type="text" name="" id="" />
                <input style={{backgroundColor:'#E8FCCF',color:'#007f5f'}} className='button' type="submit" value="Submit" />

            </form>
            <div id='journal_cat_table' className='back_table_cont'>
                <h1 className='is-size-4-mobile is-size-2-tablet'>Categories List</h1>
                <table className='back_table'>
                    <thead>
                        <tr>
                            <th>id</th>
                            <th>Categorie</th>
                            <th colSpan='2'>Options</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            cats.map(obj=>(
                                <tr key={`journal_cat ${obj.id}`}>
                                    <td>{obj.id}</td>
                                    <td>{obj.category}</td>
                                    <td>
                                        <button onClick={()=>{
                                            set_edit_cat_id(obj.id)
                                            document.getElementById('cat_edit').style.display='block';
                                        }} style={{margin:'0px 3px'}}className='button is-success'><AiFillEdit/></button>
                                        <button style={{margin:'0px 3px'}}onClick={()=>remove_cat(obj.id,set_cat_update)} className='button is-danger'><AiFillDelete/></button>
                                    </td>   
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            
            <form onSubmit={(e)=>edit_cat(e,set_cat_update,edit_cat_id)} style={{backgroundColor:'#007f5f'}} id="cat_edit">
                <h1 className='is-size-4-mobile is-size-2-tablet'>Edit Category</h1>
                <input placeholder='Journal categorie name' type="text" name="" id="" />
                <input style={{backgroundColor:'#E8FCCF',color:'#007f5f'}} className='button' type="submit" value="Submit" />

            </form>
        
        </section>
    )
}
function edit_cat(e,set_cat_update,edit_cat_id){
    e.preventDefault();
    let to_update_cat = e.target[0].value;
    axios.put(`${window.server_url}/cats`,{cat:to_update_cat,id:edit_cat_id})
    .then(response=>{
        e.target[0].value=null;
        set_cat_update(response.data)
        document.getElementById('cat_edit').style.display='none'
    })
    .catch(err=>console.log(err))
}
function add_cat(e,set_cat_update){
    window.show_loader();
    e.preventDefault();
    const cat = e.target[0].value;
    axios.post(window.server_url+'/cats',{
        cat:cat
    })
    .then(response=>{
        e.target[0].value=null;
        set_cat_update(response.data);
    })
    .catch(error=>console.log(error));
}
function remove_cat(id,set_cat_update){
    let is_delete = window.confirm('Do you really wana delete?')
    if(is_delete){
        window.show_loader();
        axios.delete(`${window.server_url}/cats/${id}`)
        .then(response=>{
            set_cat_update(response.data);
        })
        .catch(error=>console.log(error));
    }
    

}

export default Journal_cats;