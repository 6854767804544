import '../../../css/books_main.css'
import axios from 'axios'
import { useState,useEffect } from 'react'
import {AiFillDelete,AiFillEdit,AiOutlineCloseCircle} from 'react-icons/ai';
import JoditEditor from "jodit-react";

let books_top_content,books_publication_content;
const Books_main = () =>{
    const [books_pages,set_books_pages] = useState([])
    const [book_update,set_book_update] = useState()
    const [is_visible_edit_page,set_is_visible_edit_page] = useState('modal')
    const [edit_page_id,set_edit_page_id] = useState()
    const [re_render,set_re_render] = useState()
    
    let page_content_add,page_content_edit;
    const config = {
        uploader:{
            "insertImageAsBase64URI": true
        },
        toolbarAdaptive:false,
        toolbarSticky:false
        // toolbarStickyOffset:200
    }
    
    useEffect(()=>{
        window.show_loader()
        axios.get(`${window.server_url}/books_pages`)
        .then(response=>{
            set_books_pages(response.data)
            window.hide_loader()
        })
        .catch(err=>console.log(err))
        axios.get(window.server_url+'/books_main')
        .then(response=>{
            books_top_content = response.data[0].books_top_content;
            books_publication_content = response.data[0].books_publication_content;
            set_re_render(1)
        })
        .catch(err=>console.log(err))
    },[book_update])

    const AddBpage = ()=>{
        return(
            <div id='add_book_page_container' className="modal">
                <div className="modal-background"></div>
                <div id='modal_card' className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Add Book Page !</p>
                        <button onClick={()=>{
                            document.getElementById('page_title').value="";
                            page_content_add=""
                            set_book_update(Math.floor(Math.random()*100))
                            document.getElementById('add_book_page_container').classList.remove('is-active')
                        }} className="delete" aria-label="close"></button>
                    </header>
                    <section className="modal-card-body">
                        {/* <!-- Content ... --> */}
                        <form className='page_form'>
                            <label htmlFor="">Page Title</label>
                            <input type="text" className='input' id="page_title" />
                            <label htmlFor="">Page Content</label>
                            <JoditEditor
                                config={config}
                                onChange={e=>page_content_add=e}
                                value={page_content_add}
                
                            />
                        </form>
                    </section>
                    <footer className="modal-card-foot">
                            <button onClick={(e)=>addPage(e)} className="button is-success">Save changes</button>
                            <button onClick={()=>{
                                document.getElementById('page_title').value="";
                                page_content_add=""
                                set_book_update(Math.floor(Math.random()*100))

                                document.getElementById('add_book_page_container').classList.remove('is-active')
                        }}  className="button">Cancel</button>
                    </footer>
                </div>
            </div>
          
        )
        function addPage(e){
            e.preventDefault()
            window.show_loader()
            let page_title = document.getElementById('page_title').value;

            let json_data ={
                page_title:page_title,
                page_content:page_content_add
            }

            axios.post(window.server_url+'/books_pages',json_data)
            .then(response=>{
                set_book_update(response.data)

                let success_btn = document.getElementById('success_submit');
                success_btn.style.zIndex=100
                setTimeout(()=>success_btn.style.zIndex=-100,3200)


            })
            .catch(err=>console.log(err))

        }
    }
    function deletePage(id){
        if(window.confirm('Are you sure you want to delete this books page?')){
            window.show_loader()
            axios.delete(window.server_url+'/books_pages/'+id)
            .then(response=>{
                set_book_update(response.data)
            })
            .catch(err=>console.log(err))
        }
    }
    const EditBookPage = ({id})=>{
        let edit_page_title;

        for(let i = 0;i<books_pages.length;i++){
            if(books_pages[i].id===id){
                edit_page_title=books_pages[i].page_title;
                page_content_edit = books_pages[i].page_content;
            }
        }
        return(
            <div id='edit_journal_page_container' className={is_visible_edit_page}>
                <div className="modal-background"></div>
                <div id='modal_card' className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Edit Book Page !</p>
                        <button onClick={()=>{
                            set_is_visible_edit_page('modal')
                        }} className="delete" aria-label="close"></button>
                    </header>
                    <section className="modal-card-body">
                        {/* <!-- Content ... --> */}
                        <form className='page_form'>
                            <label htmlFor="">Page Title</label>
                            <input defaultValue={edit_page_title}  type="text" className='input' id="page_title_edit" />
                            <label htmlFor="">Page Content</label>
                            <JoditEditor
                                config={config}
                                onChange={e=>page_content_edit=e}
                                value={page_content_edit}
                
                            />

                        </form>
                    </section>
                    <footer className="modal-card-foot">
                            <button onClick={(e)=>editPage(e)} className="button is-success">Save changes</button>

                            <button onClick={()=>{
                                set_is_visible_edit_page('modal')
                                
                        }}  className="button">Cancel</button>
                    </footer>
                </div>
            </div>
          
        )
        function editPage(e){
            e.preventDefault()
            window.show_loader()
            let page_title = document.getElementById('page_title_edit').value;

            let json_data = {
                page_title:page_title,
                page_content:page_content_edit
            }
            axios.put(window.server_url+'/books_pages/'+id,json_data)
            .then(response=>{
                set_book_update(response.data)
                let success_btn = document.getElementById('success_submit');
                success_btn.style.zIndex=100
                setTimeout(()=>success_btn.style.zIndex=-100,3200)
            })
            .catch(err=>console.log(err))
        }
    }
    function submit_books(e){
        e.preventDefault()
        window.show_loader()
        let json_data = {
            books_top_content:books_top_content,
            books_publication_content:books_publication_content
        }

        axios.put(window.server_url+'/books_main',json_data)
        .then(response=>{
            set_book_update(response.data)

            let success_btn = document.getElementById('success_submit');
            success_btn.style.zIndex=100
            setTimeout(()=>success_btn.style.zIndex=-100,3200)


        })
        .catch(err=>console.log(err))
    }
    
    return(
        <div id='books_main_cont'>
            <div id={`success_submit`} className="button is-large is-success">Successfully Uploaded!!</div>

            <button onClick={()=>{
                document.getElementById('add_book_page_container').classList.add('is-active')
            }} id="add_new_journal_page" className='button is-warning'>
                Add New Books Page
            </button>
            <table className="back_journal_table">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Title/Url</th>
                        <th>Options</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        books_pages.map(obj=>(
                            <tr key={obj.id}>
                                <td>{obj.id}</td>
                                <td>{obj.page_title}</td>
                                <td>
                                    <button onClick={()=>{
                                        set_edit_page_id(obj.id)
                                        set_is_visible_edit_page('modal is-active')
                                        }} className="button is-success">
                                        <AiFillEdit/>
                                    </button>
                                    <button onClick={()=>deletePage(obj.id)} className="button is-danger" >
                                        <AiFillDelete/>
                                    </button>
                                </td>
                            </tr>
                        ))
                    }    
                </tbody>
            </table>
            <form action="">
                <label className="books_back_label">Books Top Content</label>
                <div className='books_back_editor'>
                <JoditEditor
                    config={config}
                    value={books_top_content}
                    onChange={e=>books_top_content=e}
                />
                </div>
               

                <label className="books_back_label">Books Publication Content</label>
                <div className='books_back_editor'>
                <JoditEditor
                    config={config}
                    value={books_publication_content}
                    onChange={e=>books_publication_content=e}
                />
                </div>
                <button onClick={(e)=>submit_books(e)} className="button is-medium ml-3 is-warning">Submit</button>
            </form>
            
            <AddBpage/>
           <EditBookPage
                id={edit_page_id}
           
            />

        </div>
    )
}

export default Books_main;