import Header from './front_page/header_front';
import Search from './front_page/search';
import Footer from './front_page/footer';
import { useEffect,useState } from 'react';
import axios from 'axios';
import '../css/article_main.css'
import { Carousel } from 'react-responsive-carousel';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';


const Article_main = ({id})=>{
    const [article_obj,set_article_obj] = useState([]);
    const [selected_item,set_selected_item] = useState(null);
    const [slider_name_array,set_slider_name_array] = useState([]);
    const [individual_slider,set_individual_slider] = useState(null)
    
    useEffect(()=>{
    window.show_loader()                  
        axios.get(`${window.server_url}/article_db/match${id}`)
        .then(response=>{
            set_article_obj(response.data)
            window.hide_loader()
        })
        .catch(err=>console.log(err))
    },[])
    let slider_urls=[];

    if(article_obj.length){
        for(let i =0;i<article_obj[0].slider_count;i++){
            slider_urls.push(`${article_obj[0].doi_number}.${i}.png`)
        }
    }
    return(
        <div id="article_main_container">
            <div id='big_screen_carrow'>
                <div id="big_screen_carrow_container">
                    <div id="main_close_btn" onClick={()=>{
                        document.getElementById('big_screen_carrow').style.display='none';
                    }}>
                        <AiOutlineCloseCircle size={'40px'} color={'white'}/>
                    </div>
                    <Carousel selectedItem={selected_item} showIndicators={false} showThumbs={false}>
                        {
                            slider_urls.length>0 ? slider_urls.map((slider_name,index)=>(
                                <div key={`main ${index}`}>
                                    <div className='big_img_article'>
                                        <img src={`/resources/article_slides/${slider_name}`}/>
                                    </div>
                                    <p style={{color:'white',fontSize:'1.3rem',fontWeight:'400',marginTop:'20px'}}>{`Figure ${index+1}`}</p>
                                </div>
                            )):
<div key={`not found`}>
                            <div className='big_img_article'>
                                <img src={`/not_found.png`}/>
                            </div>
                            <p style={{color:'#d90429',fontSize:'1.5rem',fontWeight:'400',marginTop:'20px'}}>{`No Figures`}</p>
                        </div>
                        }
                  
                    </Carousel>
                </div>
            </div>
            <div id="individual_carrow">
                <div id="individual_carrow_container">
                    <div id="main_close_btn" onClick={()=>{
                        document.getElementById('individual_carrow').style.display='none';
                        }}>
                        <AiOutlineCloseCircle size={'40px'} color={'white'}/>
                    </div>
                    <Carousel showIndicators={false} showThumbs={false}>
                        {
                            <div>
                                <div className='big_img_article'>
                                    <img src={individual_slider}/>
                                </div>
                                {/* <p style={{color:'white',fontSize:'1.3rem',fontWeight:'400',marginTop:'20px'}}>{`Figure ${1}`}</p> */}
                            </div>
                        }
                    </Carousel>
                </div>
            </div>
            <Header/>
            <Search btn_color='#4f5671' menu_color='white'/>
            {
                article_obj.length && 
                <Article_child
                    set_individual_slider={set_individual_slider}
                    title={article_obj[0].article_title}
                    authors={article_obj[0].author_name}
                    date={article_obj[0].article_date.substr(0,10)}
                    doi_number = {article_obj[0].doi_number}
                    abstract = {convertToPlain(article_obj[0].abstract_content)}
                    slider_name_array={slider_urls}
                    set_selected_item = {set_selected_item}
                    set_slider_name_array={set_slider_name_array}
                    html_content = {article_obj[0].html_content}
                    article_file_name = {article_obj[0].article_file_name}
                    page_no = {article_obj[0].page_no}
                    article_abbrevation={article_obj[0].article_abbrevation}
                    volume = {article_obj[0].volume}
                    issue = {article_obj[0].issue}
                />
            }
            <Footer/>
        </div>
    )
}

const Article_child = ({volume,issue,page_no,article_abbrevation,set_individual_slider,title,authors,doi_number,date,abstract,slider_name_array,set_selected_item,set_slider_name_array,html_content,article_file_name}) =>{
        //setting event handlers for images
    return(
        <div id='article_child1'>
            <div id="article_child_nav1">
                <h5>Open Access</h5>
                <h4>Article</h4>
                
            </div>
            <a id='main_journo_title1' href='#'>
                {title}
            </a>
            <h6>by</h6>
            <span id='main_author'>{authors}</span>
            <h2 id='article_child_h2'>
                ({volume})({issue})&nbsp;
                <a href={`https://doi.org/${doi_number}`}>
                    {`https://doi.org/${doi_number} `}
                </a>
                 - {date}
                <br/>
                <span id="page_nuumber_main">{article_abbrevation}&nbsp; &nbsp;{page_no}</span>

            </h2>
            <button onClick={()=>{
                    let article_html_content = document.getElementById('article_html_content');
                    if(article_html_content.style.display==='block'){
                        article_html_content.style.display='none';
                        document.getElementById('inline_caro').style.display='block'
                    }
                    else{
                        article_html_content.style.display='block';
                        document.getElementById('inline_caro').style.display='none'


                    }
                    
                    increase_view_count(doi_number);
                }
            } className="article_btn">View Full-Text</button>
           
            
            <button className="article_btn"><Link onClick={()=>increase_download_count(doi_number)} to={`/resources/article_documents/${article_file_name}`} target="_blank" style={{color:'white'}}>Download PDF</Link></button>
            <button onClick={()=>document.getElementById('big_screen_carrow').style.display='flex'} className="article_btn">Browse Figures</button>

            <h3 id='abstract_title'>Abstract</h3>
            <p className="text_justify">
                {abstract}
                <span onClick={()=>{
                    let article_html_content = document.getElementById('article_html_content');
                    if(article_html_content.style.display==='block'){
                        document.getElementById('inline_caro').style.display='block'
                        article_html_content.style.display='none';

                    }
                    else{
                        document.getElementById('inline_caro').style.display='none'
                        article_html_content.style.display='block';

                    }
                    
                    increase_view_count(doi_number);
                }}>View full-text</span>
            </p>
            <div id="article_child_caro_whole_container">
                <a onClick={(e)=>{
                    if(e.target.parentNode.children[1].style.display==='none')
                        e.target.parentNode.children[1].style.display='block'
                    else
                        e.target.parentNode.children[1].style.display='none'

                }}>Show Figures</a>
                <div id='inline_caro' style={{marginTop:'10px',display:'block'}}>
                    <Carousel showIndicators={false} showThumbs={false}>
                        {
                            slider_name_array.map((slider_name,index)=>(
                                <div key={`main ${slider_name}`}>
                                    <div onClick={(e)=>{
                                        document.getElementById('big_screen_carrow').style.display='flex';
                                        set_selected_item(index);
                                        set_slider_name_array(slider_name_array);

                                    }} className='caro_img_article'>
                                        <img src={`/resources/article_slides/${slider_name}`}/>
                                    </div>

                                    <p>{`figure ${index+1}`}</p>
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
            </div>
                        
            <div style={{display:'none'}} onLoad={()=>image_nodes(set_individual_slider)} id="article_html_content">
                {parse(html_content)}
            </div>
        </div>
    )

}

//add event handlers to image 
function image_nodes(set_individual_slider){
    let image_nodes =document.getElementById('article_html_content').getElementsByTagName('img')
    for (let i=0;i<image_nodes.length;i++){
        image_nodes[i].style.cursor = 'pointer'
        image_nodes[i].addEventListener('click',()=>{
            set_individual_slider(image_nodes[i].getAttribute('src'))
            document.getElementById('individual_carrow').style.display='block'
            })
            
        }
}

function increase_download_count(doi_number){
    axios.put(`${window.server_url}/article_db/downloads${doi_number}`)
    .catch(error=>console.log(error))
}
let view_limiter = false;
function increase_view_count(doi_number){
    if(!view_limiter){
        axios.put(`${window.server_url}/article_db/views${doi_number}`)
        .catch(error=>console.log(error))
        view_limiter=true;
    }
   
}
function convertToPlain(html){

    // Create a new div element
    var tempDivElement = document.createElement("div");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element 
    return tempDivElement.textContent || tempDivElement.innerText || "";
}

export default Article_main;