import '../../css/back_office/back_office_index.css';
import axios from 'axios';
import {useState,useRef} from 'react';
import Admin_page from './admin_page/admin_page';
import User_page from './user_page/user_page';

const Back_office_index = ()=>{
    const [manual_render,set_manual_render]=useState();
    const auth = sessionStorage.getItem('user');

    if(auth==='admin')
        return <Admin_page/>
    else if(auth==='user')
        return <User_page/>
    else{

        return(
            <div id='back_container'>
                <form onSubmit={e=>check_login_cred(e,set_manual_render)} autoComplete='off' id ='back_form'>
                    <h1>Sign in</h1>
                    <div id="back_login_failed">Username or Password was incorrect!</div>
                    <input required={true} type="text" id="back_username" placeholder='User Name'/>
                    <input required={true} type="password" placeholder="password" id='back_password'/>
                    <input className='button is-large' type="submit" value="Sign In" />
                </form>
            </div>
        )
    }
}
const check_login_cred = (e,set_manual_render)=>{
    e.preventDefault();
    const username = document.getElementById('back_username').value;
    const password = document.getElementById('back_password').value;
    const form_obj = {
        username:username,
        password:password
    }
    axios.post(window.server_url+'/login_check',form_obj)
    .then(response=>{
        const auth_obj = response.data;
        if(!auth_obj.login){
            const login_failed = document.getElementById('back_login_failed');
            login_failed.style.display='block';
        }
        else if(auth_obj.admin){
            sessionStorage.setItem('user','admin');
            set_manual_render(null);
        }
        else{
            sessionStorage.setItem('user','user');
            set_manual_render(null);
        }
    })
    .catch(error=>console.log(error))
}

export default Back_office_index;