import Header from '../front_page/header_front';
import Footer from '../front_page/footer';

import axios from 'axios'
import Select from 'react-select'
import { useState,useEffect } from 'react'
import { Journal_list } from '../front_page/Main'
const For_editor = () =>{
    let options=[
        {
            label:'All',value:'All'
        }
    ];
    let repeated_index = []

    const [indexing_obj,set_indexing_obj] = useState([])
    const[cat_array,set_cat_array] = useState([]);
    const [journal_obj,set_journal_obj] = useState([]);
    const [filter_cat,set_filter_cat] = useState('All');
    useEffect(()=>{
        window.show_loader()
        axios.all([
            axios.get(window.server_url+'/cats'),
            axios.get(window.server_url+'/journals/journal_intro'),
            axios.get(`${window.server_url}/indexing`)

        ])
        .then(axios.spread((...responses)=>{
            set_cat_array(responses[0].data);
            set_journal_obj(responses[1].data);
            set_indexing_obj(responses[2].data);
            window.hide_loader()

        }))
        .catch(error=>console.log(error))
    },[])
    cat_array.map(obj=>{
        let option_obj={};
        option_obj.label=obj.category;
        option_obj.value=obj.category;
        options.push(option_obj);
    })
    return(
        <div>
            <Header/>
            <div id="main_tile" className="is-ancestor is-parent tile">
                <div className='tile is-3 is-parent is-vertical'>
                    <div id='main_first_tile_child' className='notification is-white is-child box'>
                        <h1>Open Access Journals</h1>
                        <Select id='main_Select' options={options} onChange={e=>{
                            if(e.value==='All')
                                set_filter_cat(e.value)
                            else{
                                for(let i =0;i<cat_array.length;i++){
                                    if(e.value===cat_array[i].category){
                                        set_filter_cat(cat_array[i].id)
                                    }
                                }
                            }
                        } 
                        }/>
                        {
                        // displaying journal names with icons in a loop gotten from the data
                            journal_obj.map(obj=>{
                                if(filter_cat==='All')
                                    return <Journal_list key={obj.id} journal_icon={obj.id} journal_abbrevation={obj.journal_abbrevation} journal_slug={obj.journal_slug}/>      
                                else if(filter_cat===obj.journal_cat_id)
                                    return <Journal_list key={obj.id} journal_icon={obj.id} journal_abbrevation={obj.journal_abbrevation} journal_slug={obj.journal_slug}/>      
                        })
                            
                    }
                </div>
                <div id="indexing_container" className="is-child notification is-white box">
                    {
                        indexing_obj.length>0&&indexing_obj.map((index_obj,index)=>{
                            let unique_url = true
                            if(index!==0){
                                for(let i = 0;i<repeated_index.length;i++){
                                    if(repeated_index[i]===index_obj.url){
                                        unique_url = false
                                        break
                                    }
                                }
                            }
                            if(unique_url){
                                repeated_index.push(index_obj.url)
                                return(
                                    <a key={`${index} indexing`} href={index_obj.url}>
                                        <img src={`/resources/journal_indexing/${index_obj.file_name}`} alt="" />
                                    </a>
                                )
                            }
                        })
                    }
                </div>
            </div>
                <div className="tile is-parent">
                    <div id="plagiarism_container">
                        <div id="plagiarism_policy_container">
                            <h1>EDITORS RESPONSIBILITIES</h1>
                            <p>
                                <ul className='text_justify' id ='bullet_list'>
                                    <li>The editor of the journal is responsible for accepting, rejecting, and requesting to changes and modifies the submitted manuscript to the journal. In some cases editor may require to do multiple rounds of review and modifications. In a timely manner, the editors communicate the review results. The editor has right to edit, illuminate and shorten the manuscript as deemed necessary.</li>
                                    <li>The editor must ensure that each submitted manuscript should be reviewed for its intellectual content in-spite of the author's race, gender, sexual orientation, religious beliefs, ethnic origin, and citizenship. Information about manuscripts submitted by authors must be kept confidential by the editor and editorial staff.</li>
                                    <li>Without the author's explicit written consent, the editor and the editorial board member of this journal may not use unpublished materials disclosed in a submitted manuscript with their own research.</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
        
      
    )
}

export default For_editor;