import '../../css/front_page/search.css';
import { useState, useEffect } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';


//options for the search tag
const article_type_options = [
    { value: 'All Article Types', label: 'All Article Types' },
    { volume: 'All', label: 'All' },
    { volume: 'Article', label: 'Article' },
    { volume: 'Types', label: 'Types' },
    { volume: 'Article', label: 'Article' },
    { volume: 'Review', label: 'Review' },
    { volume: 'Communication', label: 'Communication' },
    { volume: 'Editorial', label: 'Editorial' },
    { volume: 'Addendum', label: 'Addendum' },
    { volume: 'Book', label: 'Book' },
    { volume: 'Review', label: 'Review' },
    { volume: 'Brief', label: 'Brief' },
    { volume: 'Report', label: 'Report' },
    { volume: 'Case', label: 'Case' },
    { volume: 'Report', label: 'Report' },
    { volume: 'Comment', label: 'Comment' },
    { volume: 'Commentary', label: 'Commentary' },
    { volume: 'Concept', label: 'Concept' },
    { volume: 'Paper', label: 'Paper' },
    { volume: 'Conference', label: 'Conference' },
    { volume: 'Report', label: 'Report' },
    { volume: 'Correction', label: 'Correction' },
    { volume: 'Creative', label: 'Creative' },
    { volume: 'Data', label: 'Data' },
    { volume: 'Descriptor', label: 'Descriptor' },
    { volume: 'Discussion', label: 'Discussion' },
    { volume: 'Entry', label: 'Entry' },
    { volume: 'Erratum', label: 'Erratum' },
    { volume: 'Essay', label: 'Essay' },
    { volume: 'Expression', label: 'Expression' },
    { volume: 'Concern', label: 'Concern' },
    { volume: 'Extended', label: 'Extended' },
    { volume: 'Abstract', label: 'Abstract' },
    { volume: 'Guidelines', label: 'Guidelines' },
    { volume: 'Hypothesis', label: 'Hypothesis' },
    { volume: 'Interesting', label: 'Interesting' },
    { volume: 'Images', label: 'Images' },
    { volume: 'Letter', label: 'Letter' },
    { volume: 'New', label: 'New' },
    { volume: 'Book', label: 'Book' },
    { volume: 'Received', label: 'Received' },
    { volume: 'Obituary', label: 'Obituary' },
    { volume: 'Opinion', label: 'Opinion' },
    { volume: 'Perspective', label: 'Perspective' },
    { volume: 'Proceedings', label: 'Proceedings' },
    { volume: 'Project', label: 'Project' },
    { volume: 'Report', label: 'Report' },
    { volume: 'Protocol', label: 'Protocol' },
    { volume: 'Registered', label: 'Registered' },
    { volume: 'Report', label: 'Report' },
    { volume: 'Reply', label: 'Reply' },
    { volume: 'Retraction', label: 'Retraction' },
    { volume: 'Short', label: 'Short' },
    { volume: 'Note', label: 'Note' },
    { volume: 'Study', label: 'Study' },
    { volume: 'Protocol', label: 'Protocol' },
    { volume: 'Systematic', label: 'Systematic' },
    { volume: 'Review', label: 'Review' },
    { volume: 'Technical', label: 'Technical' },
    { volume: 'Note', label: 'Note' },
    { volume: 'Tutorial', label: 'Tutorial' },
    { volume: 'Viewpoint', label: 'Viewpoint' },
]
const Search = (props) => {
    // let [advanced_search_bool, set_advanced_search_bool] = useState(false);
    const [journal_intro_obj,set_journal_intro_obj] = useState([]);
    useEffect(() => {
    window.show_loader()
        axios.get(window.server_url+'/journals/journal_intro')
            .then(response => {
                set_journal_intro_obj(response.data)
                window.hide_loader()
            })
            .catch(error => console.log(error))
    }, [])

    // if (advanced_search_bool) {
    //     return <Search_advanced journal_intro_obj={journal_intro_obj} selected_journal={selected_journal} set_selected_journal={set_selected_journal} btn_color={props.btn_color} menu_color={props.menu_color} advanced_search_bool={advanced_search_bool} set_advanced_search_bool={set_advanced_search_bool} />
    // }
    return <Search_basic journal_intro_obj={journal_intro_obj} btn_color={props.btn_color} menu_color={props.menu_color}/>
}
export default Search
const Search_basic = ({ journal_intro_obj,btn_color, menu_color}) => { //if you need advanced search put in advanced_search_bool and set_advanced _search bool in arguments
    const custom_style = {
        button: { backgroundColor: btn_color },
        menu: { backgroundColor: menu_color }
    }

    return (
        <div style={custom_style.menu} id="search_container" className="level">
            <h5 id="semi_text" className="level-item">Search:</h5>
            <h5 id="full_text" className="level-item">Search for Articles:</h5>
            <form onSubmit={(e)=>search_now(e,false,journal_intro_obj)} method='get' id="search_form" action="" className="level-item">
                <input  type="text" name="" id="" className='input' placeholder='Keyword / Title' />
                <input  className='input' placeholder='Author / Affiliation' type="text" name="" id="" />
                <select className='search_select'>
                    <option value="0">All Journals</option>
                    {
                        journal_intro_obj.map(obj=>(
                            <option key={`${obj.id}`} value={obj.id}>{obj.journal_name}</option>
                        ))
                    }
                </select>
                <select className='search_select'>
                    <option value="All articles">All articles</option>
                    {
                        article_type_options.map((obj,index)=>(
                            <option key={`art_options${index}`} value={obj.volume}>{obj.volume}</option>
                        ))
                    }
                </select>
                <input style={custom_style.button} type="submit" value="Search" />
                {/* <a onClick={() => {
                        set_advanced_search_bool(!advanced_search_bool);
                        set_selected_journal('all')
                        }} className="level-item">Advanced</a> */}
            </form>

        </div>
    )
}

// const Search_advanced = ({journal_intro_obj,selected_journal,set_selected_journal, btn_color, menu_color, advanced_search_bool, set_advanced_search_bool }) => {
//     const custom_style = {
//         button: { backgroundColor: btn_color },
//         menu: { backgroundColor: menu_color }
//     }

//     return (
//         <div style={custom_style.menu} id="advanced_search_container">
//             <h5>Search for Articles</h5>
//             <div className="continer">
//                 <form onSubmit={e=>search_now(e,selected_journal,true,journal_intro_obj)} id="advanced_search_form" className="columns is-vcentered is-multiline">
//                     <div className="column is-one-fifth">
//                         <h6 className='h_six'>Keyword / Title</h6>
//                         <input placeholder='Keyword / Title' className='input' type="text" name="" id="" />
//                         <h6 className='h_six'>Section</h6>
//                         <Select placeholder='--' options={no_options} />
//                     </div>
//                     <div className="column is-one-fifth">
//                         <h6 className='h_six'>Author / Affiliation</h6>
//                         <input placeholder='Author / Affiliation' className='input' type="text" name="" id="" />
//                         <h6 className='h_six'>Special Issue</h6>
//                         <Select placeholder='--' options={no_options} />
//                     </div>
//                     <div className="column is-one-fifth">
//                         <h6 className='h_six'>Journal</h6>
//                         <Select onChange={e=>set_selected_journal(e.value)} placeholder='All Journals' options={journal_options} />
//                         <div className="columns">
//                             <div className="column is-half">
//                                 <h6 className='h_six'>Volume</h6>
//                                 <input className='input' type="text" name="" id="" />
//                             </div>
//                             <div className="column is-half">
//                                 <h6 className='h_six'>Issue</h6>
//                                 <input className='input' type="text" name="" id="" />
//                             </div>
//                         </div>
//                     </div>
//                     <div className="column is-one-fifth">
//                         <h6 className='h_six'>Article Type</h6>
//                         <Select placeholder='All Article Types' options={article_type_options} />
//                         <div className="columns">
//                             <div className="column">
//                                 <h6 className='h_six'>Number</h6>
//                                 <input className='input' type="text" name="" id="" />
//                             </div>
//                             <div className="column">
//                                 <h6 className='h_six'>Page</h6>
//                                 <input className='input' type="text" name="" id="" />
//                             </div>
//                         </div>
//                     </div>
//                     <div className="column columns is-one-fifth">
//                         <div className="column is-half">
//                             <input style={custom_style.button} type="submit" value="Search" />
//                         </div>
//                         <div className="column is-half">
//                             <a onClick={() => {
//                                         set_advanced_search_bool(!advanced_search_bool);
//                                         set_selected_journal('all');
//                                     }}>Advanced</a>
//                         </div>
//                     </div>

//                 </form>
//             </div>
//         </div>
//     )
// }

function search_now(e){
    e.preventDefault();
    
    let journal_id = e.target[2].value;
    let title = e.target[0].value;
    let author = e.target[1].value;
    if(!title.length)
        title='all';
    if(!author.length)
        author='all'
        
    window.open(`/search?title=${title}&author=${author}&journal_id=${journal_id}`,'_self')
}

