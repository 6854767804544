import Header from '../front_page/header_front';
import '../../css/dynamic_journal/home.css';
import { useState,useEffect } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import Footer from '../front_page/footer';
import {GoCloudDownload} from 'react-icons/go';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import {Fragment} from 'react';
import {FaPenAlt,FaExclamationCircle} from 'react-icons/fa'
import {Article_child} from '../front_page/Main'

let limit_index=0,limit_no=10;
const Home = ({current_volume,current_issue,id,journal_name,chief_editor,issn,frequency,language,origin,publisher,impact_factor}) =>{
    const [content_selector,set_content_selector] = useState();
    const [volume_issue_obj,set_volume_issue_obj] = useState([]);
    const [article_obj,set_article_obj] = useState([]);
    const [slider_name_array,set_slider_name_array] = useState([]);
    const [selected_item,set_selected_item] = useState(null);
    const [journal_obj,set_journal_obj] = useState([]);
    const [journal_editors_obj,set_journal_editors_obj] = useState([]);
    const [indexing_obj,set_indexing_obj] = useState([])
    const [indexing_obj1,set_indexing_obj1] = useState([])
    const [journal_page_obj,set_journal_page_obj] = useState([])
    const [dyno_page_content,dyno_set_page_content] = useState('<h1>404 NOT FOUND!</h1>')
    
    

    let repeated_index = []
    
    useEffect(()=>{
    limit_index=0
    limit_no=10
    let url_split = window.location.href.split('/')
    let url_focused_element = url_split[url_split.length-1]
    console.log(url_focused_element)
    
    
    window.show_loader()
        axios.all([
            axios.get(`${window.server_url}/journal_volume_and_issue_info/${id}`),
            axios.get(`${window.server_url}/article_db/journal_based_articles^${id}^${limit_index}^${limit_no}`),
            axios.get(`${window.server_url}/journals/${id}`),
            axios.get(`${window.server_url}/journal_editors/${id}`),
            axios.get(`${window.server_url}/indexing/${id}`),
            axios.get(`${window.server_url}/indexing`),
            axios.get(`${window.server_url}/journal_page/${id}`)

        ])
        .then(axios.spread((...responses)=>{
            set_volume_issue_obj(responses[0].data)
            set_article_obj(responses[1].data)
            set_journal_obj(responses[2].data)
            set_journal_editors_obj(responses[3].data)
            set_indexing_obj(responses[4].data)
            set_indexing_obj1(responses[5].data)
            set_journal_page_obj(responses[6].data)

            //url loading focus (#)
            if(url_focused_element==='Home'){
                set_content_selector(0)
                btn_active(0)
            }
            else if(url_focused_element==='Description'){
                set_content_selector(1)
                btn_active(1)

            }
            else if(url_focused_element==='Archive'){
                set_content_selector(2)
                btn_active(2)

            }
            else if(url_focused_element==='EditorialBoard'){
                set_content_selector(3)
                btn_active(3)

            }
            else if(url_focused_element==='Indexing'){
                set_content_selector(4)
                btn_active(4)

            }
            else if(url_focused_element==='ManuscriptGuideline'){
                set_content_selector(5)
                btn_active(5)

            }
            else{
                btn_active(null)

                for(let i =0;i<responses[6].data.length;i++){
                    if(responses[6].data[i].page_title===url_focused_element.replaceAll('-',' ')){
                        dyno_set_page_content(responses[6].data[i].page_content)
                    }
                }
                set_content_selector('dynamic')
            }
            window.hide_loader()
        }))
        .catch(error=>console.log(error))
    },[])

    const Journal_pages = ({page_content})=>{
        return(
            <div>
                {parse(page_content)}
            </div>
        )
    }
    function btn_active(btn_no){
        if(btn_no!==null){
            let buttons = document.getElementsByClassName('home_styled_btn')
            buttons[btn_no].id='home_btn_active'
        }

    }
    return(
        <div>
            <Header/>
            <div id='success_submit' className="button is-large is-success">Successfully Uploaded!!</div>

            {/* big screen carrow is here  */}
            <div id='big_screen_carrow'>
                <div id="big_screen_carrow_container">
                    <div onClick={()=>{
                        document.getElementById('big_screen_carrow').style.display='none';
                    }} id="main_close_btn">
                        <AiOutlineCloseCircle size={'40px'} color={'white'}/>
                    </div>
                    <Carousel selectedItem={selected_item} showIndicators={false} showThumbs={false}>
                        {
                            slider_name_array.map((slider_name,index)=>(
                                <div key={`main ${index}`}>
                                    <div className='big_img_article'>
                                        <img src={`/resources/article_slides/${slider_name}`}/>
                                    </div>
                                    <p style={{color:'white',fontSize:'1.3rem',fontWeight:'400',marginTop:'20px'}}>{`Figure ${index+1}`}</p>
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
            </div>
            <div id="home_journal_intro">
                
                <img src={`/resources/journal_icons/${id}.png`} />

                <div id='home_journal_text'>
                    <h1 className='is-size-4 is-size-7-mobile'>{journal_name}</h1>

                    <span className="home_journal_text_semi">
                        <h6>ISSN</h6>
                        <h6>:</h6>
                        <h6>{issn}</h6>
                    </span>
                    <span className="home_journal_text_semi">
                        <h6>Frequency</h6>
                        <h6>:</h6>
                        <h6>{frequency}</h6>
                    </span>
                    <span className="home_journal_text_semi">
                        <h6>Language</h6>
                        <h6>:</h6>
                        <h6>{language}</h6>
                    </span>
                    <span className="home_journal_text_semi">
                        <h6>Chief Editor</h6>
                        <h6>:</h6>
                        <h6>{chief_editor}</h6>
                    </span>
                    <span className="home_journal_text_semi">
                        <h6>Origin</h6>
                        <h6>:</h6>
                        <h6>{origin}</h6>
                    </span>
                    <span className="home_journal_text_semi">
                      
                        <h6>Publisher</h6>
                        <h6>:</h6>
                        <h6>{publisher}</h6>
                    </span>
                    
                    
                </div>
                <div className="speech_bubble">
                    Impact Factor<br/>
                    {impact_factor}
                </div>
            </div>
            <div id='main_tile' className="tile is-ancestor is-vertical">
                <div id='home_columns_column1' className="tile is-parent">
                    <div id='home_columns_column1_child' className="is-child tile">
                            <a href='Home'  className='home_styled_btn'>Home</a>
                            <a href="Description" className='home_styled_btn' >Description</a>
                            <a href='Archive' className='home_styled_btn'>Archive</a>
                            <a href='EditorialBoard' className='home_styled_btn'>Editorial Board</a>
                            <a href='Indexing' className='home_styled_btn'>Indexing</a>
                            <a href='ManuscriptGuideline' className='home_styled_btn'>Manuscript Guideline</a>
                    </div>
                </div>
                <div className="tile is-white notification is-parent">
                    <div style={{padding:'0 .32rem 0 0'}} className="is-3 is-child tile is-white box notification">
                        <div id="author_instruction_cont">
                            <h1>Author Instructions</h1>
                            <ul>
                                {
                                    journal_page_obj.map((obj,index)=>{
                                        if(obj.is_external_website){
                                            return(
                                                <li key={'li'+index}>
                                                    <a target='_blank' href={obj.page_title}>
                                                        <div>{obj.page_title}</div>
                                                    </a>
                                                </li>
                                            )
                                        }
                                        else{
                                            return(
                                                <li  key={'li'+index}>
                                                    <a href={`${obj.page_title.replaceAll(' ','-')}`}><div>{obj.page_title}</div></a>
                                                </li>
                                            )
                                        }
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="is-child tile is-white box notification">
                        {
                            content_selector===0&&article_obj.length>0&&<Home_home journal_id={id} set_article_obj={set_article_obj} article_object={article_obj} set_slider_name_array={set_slider_name_array} set_selected_item={set_selected_item}/>
                        }
                        {
                            content_selector===1&&journal_obj.length>0&&<Description description={journal_obj[0].journal_description}/>
                        }
                        {
                            content_selector===2&&volume_issue_obj.length>0&&<Archive object={volume_issue_obj} journal_id={id}/>
                        }
                        {
                            content_selector===3&&journal_editors_obj.length>0&&<Editorial_board obj={journal_editors_obj}/>
                        }
                        {
                            content_selector===4&&indexing_obj.length>0&&<Indexing obj={indexing_obj}/>

                        }
                        {
                            content_selector===5&&journal_obj.length>0&&<Manuscript_guideline manuscript_guideline={journal_obj[0].journal_manuscript_guideline}/>
                        }
                        {
                            content_selector==='dynamic'&& <Journal_pages page_content={dyno_page_content} />
                        }
                    </div>
                    <div id='home_columns_column2' className="tile is-child is-3 is-parent is-vertical">
                            <form onSubmit={(e)=>submit_newsletter(e)} id = 'main_newsletter' className="is-child notification is-white box">
                                <h1 style={{marginBottom:'-7px'}} className='block'>E-Mail Alert</h1>
                                <p className='block'>Enter your email address to recieve forthcoming issues of the article.</p>
                                <div className="field">
                                    <label className="label">Name</label>
                                    <div className="control">
                                    <input required={true} id='newsletter_name' className="input" type="text" placeholder="Name"/>
                                    </div>
                                    <label className="label">Email</label>
                                    <div className="control">
                                    <input required={true} id='newsletter_email' className="input" type="email" placeholder="Email"/>
                                    </div>
                                </div>
                                <button id='main_newsletter_btn' className="button is-medium is-primary">Submit Now</button>
                            </form>      
                            <div className="is-child notification is-white box">
                                <ul>
                                    <li>
                                        <FaExclamationCircle color='#76c893'/>
                                        <a href={`${window.client_url}/articles?journal_id=${id}&volume=${current_volume}&issue=${current_issue}`} className='ml-2'>Current Issue</a>

                                    </li>
                                    <br/>
                                    <li>
                                    <FaPenAlt color='#168aad'/>
                                    <Link className='ml-2' to='/join-as-editor'>Join As Editor</Link>

                                    </li>
                                </ul>

                            </div>
                    
                            <div id="indexing_container" className="is-child notification is-white box">
                            {
                                indexing_obj.length>0&&indexing_obj.map((index_obj,index)=>{
                                    let unique_url = true
                                    if(index!==0){
                                        for(let i = 0;i<repeated_index.length;i++){
                                            if(repeated_index[i]===index_obj.url){
                                                unique_url = false
                                                break
                                            }
                                        }
                                    }
                                    if(unique_url){
                                        repeated_index.push(index_obj.url)
                                        return(
                                            <a key={`${index} indexing`} target='_blank' href={index_obj.url}>
                                                <img src={`/resources/journal_indexing/${index_obj.file_name}`} alt="" />
                                            </a>
                                        )
                                    }
                                })
                            }
                            </div>
                    
                        </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
function submit_newsletter(e){
    e.preventDefault()
    let newsletter_email = document.getElementById('newsletter_email').value
    let newsletter_name = document.getElementById('newsletter_name').value

    let jsondata = {
        newsletter_email:newsletter_email,
        newsletter_name:newsletter_name
    }
    axios.post(`${window.server_url}/newsletter`,jsondata)
    .then(()=>{
        document.getElementById('success_submit').style.zIndex=100
        setTimeout(()=>document.getElementById('success_submit').style.zIndex=-100,3200)
        document.getElementById('newsletter_name').value=""
        document.getElementById('newsletter_email').value=""
    })
    .catch(err=>console.log(err))

}


const Home_home = ({journal_id,set_article_obj,article_object,set_selected_item,set_slider_name_array})=>{
    function load_more(article_obj,set_article_obj,journal_id){
        let current = [...article_obj]
        limit_index+=limit_no;
        axios.get(`${window.server_url}/article_db/journal_based_articles^${journal_id}^${limit_index}^${limit_no}`)
        .then(response=>{
            let new_current = response.data
            for(let i =0;i<new_current[0].length;i++){
                current[0].push(new_current[0][i])
            }
            current[1] = new_current[1]
            set_article_obj(current)
        })
        .catch(err=>console.log(err))


    }
    if(article_object==null || article_object.length===0)
        return null;
    else{
        return article_object[0].map((obj,index)=>{
            let slider_urls=[];
            for(let i =0;i<obj.slider_count;i++){
                slider_urls.push(`${obj.doi_number}.${i}.png`)
            }
            if(article_object[0].length-1===index){
                return(
                    <Fragment key={`key mate ${index}`}>
                        <Article_child
                            title = {obj.article_title}
                            authors = {obj.author_name}
                            date = {obj.article_date.substr(0,10)}
                            abstract = {convertToPlain(obj.abstract_content)}
                            slider_name_array={slider_urls}
                            set_selected_item = {set_selected_item}
                            set_slider_name_array={set_slider_name_array}
                            doi_number ={obj.doi_number}
                            article_file_name={obj.article_file_name}
                            download_icon_size='25px'
                            volume = {obj.volume}
                            issue = {obj.issue}
                            id={obj.id}
                            page_no={obj.page_no}
                            article_abbrevation={obj.article_abbrevation}
                            
                        />
                        {article_object[1].length>0&&<a onClick={()=>load_more(article_object,set_article_obj,journal_id)} id='load_more_article'>More Articles...</a>}
                    </Fragment>
                    
                
            )
            }
            else{
                return(
                    <Article_child
                        key={`key mate ${index}`}
                        title = {obj.article_title}
                        authors = {obj.author_name}
                        date = {obj.article_date.substr(0,10)}
                        abstract = {convertToPlain(obj.abstract_content)}
                        slider_name_array={slider_urls}
                        set_selected_item = {set_selected_item}
                        set_slider_name_array={set_slider_name_array}
                        doi_number ={obj.doi_number}
                        article_file_name={obj.article_file_name}
                        download_icon_size='25px'
                        volume = {obj.volume}
                        issue = {obj.issue}
                        id={obj.id}
                        page_no={obj.page_no}
                        article_abbrevation={obj.article_abbrevation}
                    
                    />
                
            )
            }
            
           
        })
    }
    
}
const Description = ({description})=>{
    return(
        <div style={{margin:'10px'}}>
            {parse(description)}
        </div>
    )
}
const Editorial_board = ({obj})=>{
    return(
        <section style={{marginTop:'20px'}} className='columns is-variable is-0 is-multiline' id="editorial_board">
            <h1 id='editorial_board_head'>Editorial Board</h1>
            {
                obj.map((obj,index)=>{

                    if(index===0){
                        return(
                            <div  key={`editorial key${index}`} className='column is-half '>
                                <div className='editorial_box_size'>
                                <h1 id='chief_editor_head'>Chief Editor</h1>
                                <div  id = 'journal_editor_image_container'>
                                    <img style={{maxWidth:'200px'}} id='journal_editors_img' src={`/resources/journal_editors/${obj.file_name}`} alt="" />
                           
                                </div>
                                <div>
                                    <h2 id='journal_editor_name'>{obj.editor_name}</h2>
                                    <p id = 'journal_editor_description'>{obj.editor_description}</p>
                                </div>
                                </div>
                                
                        
                            </div>
                        )
                    }
                    else if(index===1){
                        return(
                            <div key={`editorial key${index}`} className='column is-half'>
                                <div className="editorial_box_size">
                                <h1 id="managing_editor_head">Managing Editor</h1>
                                <div  id = 'journal_editor_image_container'>
                                    <img style={{maxWidth:'200px'}} id='journal_editors_img' src={`/resources/journal_editors/${obj.file_name}`} alt="" />
                           
                                </div>
                                <div>
                                    <h2 id='journal_editor_name'>{obj.editor_name}</h2>
                                    <p id = 'journal_editor_description'>{obj.editor_description}</p>
                                </div>
                                </div>
                                
                        
                            </div>
                        )
                    }
                    else if(index===2){
                        return(
                            <Fragment key={`editorial key${index}`}>
                                <h1 id="associate_editors">Associate Editors</h1>
                                <div className='column is-half'>
                                    <div className="editorial_box_size">
                                    <div  id = 'journal_editor_image_container'>
                                        <img style={{maxWidth:'200px'}} id='journal_editors_img' src={`/resources/journal_editors/${obj.file_name}`} alt="" />
                                    </div>
                                    <div>
                                        <h2 id='journal_editor_name'>{obj.editor_name}</h2>
                                        <p id = 'journal_editor_description'>{obj.editor_description}</p>
                                    </div>
                                    </div>
                                    
                        
                                </div>
                            </Fragment>
                           
                        )
                    }
                    else{
                        return(
                            <div key={`editorial key${index}`} className='column is-half'>
                                <div className="editorial_box_size">
                                <div  id = 'journal_editor_image_container'>
                                    <img style={{maxWidth:'200px'}} id='journal_editors_img' src={`/resources/journal_editors/${obj.file_name}`} alt="" />
                           
                                </div>
                                <div>
                                    <h2 id='journal_editor_name'>{obj.editor_name}</h2>
                                    <p id = 'journal_editor_description'>{obj.editor_description}</p>
                                </div>
                                </div>
                               
                        
                            </div>
                        )
                    }
                    
                })
            }
        </section>
    )
}
const Archive = ({object,journal_id})=>{
    let year_change = null;
    let issue_change = null;
    return (
        object.map((obj,index)=>{
            if(obj.year!==year_change){
                year_change=obj.year;
                issue_change=obj.issue;
                return(
                    <Fragment key={`${index}${obj.year}${obj.issue}`}>
                        <div id="home_archive_year">{obj.year}</div>
                        <Link target="_blank" to={`/articles?journal_id=${journal_id}&volume=${obj.volume}&issue=${obj.issue}`} id="home_archive_issue">{obj.month}</Link>

                    </Fragment>
                )
            }
            else if(obj.issue!==issue_change){
                issue_change=obj.issue;
                return(
                    <Link target="_blank" key={`${obj.volume}${obj.issue}`} to={`/articles?journal_id=${journal_id}&volume=${obj.volume}&issue=${obj.issue}`} id="home_archive_issue">{obj.month}</Link>

                )
            }
        })
    )
   
}
function increase_download_count(doi_number){
    axios.put(`${window.server_url}/article_db/downloads${doi_number}`)
    .catch(error=>console.log(error))
}
const Indexing = ({obj})=>{
    return(
        <section id='indexing_section' className='columns is-multiline'>
            {
                obj.map((obj,index)=>(
                    <div key={`${obj.url}${index}`} className="column is-one-third">
                        <a style={{textDecoration:'none'}} target='_blank' href={obj.url}>
                            <img src={`/resources/journal_indexing/${obj.file_name}`} alt="" />
                            <h2>{obj.label}</h2>
                        </a>
                        
                    </div>
                ))
            }
        </section>
    )
}
const Manuscript_guideline = ({manuscript_guideline})=>{
    return(
        <div style={{margin:'10px'}}>
            {parse(manuscript_guideline)}
        </div>
    )
}
function convertToPlain(html){

    // Create a new div element
    var tempDivElement = document.createElement("div");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element 
    return tempDivElement.textContent || tempDivElement.innerText || "";
}

export default Home;