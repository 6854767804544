import '../../../css/back_office/impact_factor_back.css'
import JoditEditor from 'jodit-react';
import axios from 'axios';
import { useState,useEffect } from 'react';

const Impact_factor_back = ()=>{
    const config = {
        uploader:{
            "insertImageAsBase64URI": true
        },
        toolbarAdaptive:false
    }
    const [impact_factor_page,set_impact_factor_page] = useState('');
    useEffect(()=>{
    window.show_loader()

        axios.get(`${window.server_url}/impact_factor_page`)
        .then(response=>{
            set_impact_factor_page(response.data[0].content);
            window.hide_loader()
        })
        .catch(err=>console.log(err));

    },[])
    return(
        <div id='impact_factor_back_container'>
            <div id="success_submit" className="button is-success is-large">Successfully Uploaded</div>
            <h1 id='impact_back_h1'>Create Impact Factor Page!</h1>
            <div id='impact_back_jodit_cont'>
                <JoditEditor
                    config={config}
                    onChange={e=>editor_content=e}
                    value={impact_factor_page}
                />
            </div>
            <div className='has-text-centered '>
                <button onClick={()=>submit()} className="button is-centered">Submit</button>
            </div>
            
        </div>
    )
}
let editor_content;

function submit(){
    window.show_loader();
    console.log(editor_content);
    let editor_obj = {
        editor_content:editor_content
    }
    axios.put(`${window.server_url}/impact_factor_page`,editor_obj)
    .then(()=>{
        window.hide_loader()
        document.getElementById('success_submit').style.zIndex=100
                setTimeout(()=>document.getElementById('success_submit').style.zIndex=-100,3200)
    }
    )
    .catch(err=>console.log(err))
}


export default Impact_factor_back;