import '../../../css/editor_requests.css'
import { useState,useEffect } from 'react';
import axios from 'axios';
import {GrOverview} from 'react-icons/gr'
import { AiFillDelete } from 'react-icons/ai';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

const Editor_requests = ()=>{
    let session = sessionStorage.getItem('sub_page');
    const [editor_request_obj,set_editor_request_obj] = useState([])
    const [re_render,set_re_render] = useState(null)
    const [show_editor,set_show_editor] = useState(-1)

    useEffect(()=>{
        window.show_loader()
        axios.get(window.server_url+'/editor_requests')
        .then(response=>{
            set_editor_request_obj(response.data)
            window.hide_loader()
        })
        .catch(err=>console.log(err))
    },[re_render])
    return(
        <div id="editor_request_cont">
            <h1 className='has-text-centered has-text-white is-size-2 is-underlined'>Editors List</h1>
            <Editor_list
                editor_obj={editor_request_obj}
                set_re_render={set_re_render}
                set_show_editor={set_show_editor}
            />
            {show_editor>=0&&<Editor_details
                                           editor_obj={editor_request_obj}
                                           show_editor={show_editor}         
            
            
            />}
        </div>
    )
}

const Editor_details = ({editor_obj,show_editor})=>{
    return(
        <div id="editor_details">
            <h2 className='has-text-centered has-text-white is-size-3 is-underlined'>Editor Details</h2>
            <table className="back_journal_table">
                <thead>
                    <tr>
                        <th>Heading</th>
                        <th>Detail</th>
                        
                    </tr>
                </thead>
                <tbody>
                    {
                        editor_obj.map(obj=>{
                            if(obj.id==show_editor){
                                let aip;
                                if(obj.is_aip_interacted==1)
                                    aip='Yes'
                                else
                                    aip='No'
                                return(
                                    <Fragment key={`some key ${obj.id}`}>
                                        
                                        <tr>
                                            <td>Id</td>
                                            <td>{obj.id}</td>
                                        </tr>
                                        <tr>
                                            <td>First Name</td>
                                            <td>{obj.first_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Last Name</td>
                                            <td>{obj.last_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td>{obj.email}</td>
                                        </tr>
                                        <tr>
                                            <td>Affiliation</td>
                                            <td>{obj.affiliation}</td>
                                        </tr>
                                        <tr>
                                            <td>Journal name</td>
                                            <td>{obj.journal_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Profile Page</td>
                                            <td>{obj.profile}</td>
                                        </tr>
                                        <tr>
                                            <td>Publication Record</td>
                                            <td>{obj.publication_record}</td>
                                        </tr>
                                        <tr>
                                            <td>Interacted with A.I.P before?</td>
                                            <td>{aip}</td>
                                        </tr>
                                        <tr>
                                            <td>Area of Research</td>
                                            <td>{obj.aoe}</td>
                                        </tr>
                                        <tr>
                                            <td>Why editor is interested</td>
                                            <td>{obj.why_interested}</td>
                                        </tr>
                                        
                                    </Fragment>
                                    
                                )
                            }
                        })
                    }
                </tbody>
            </table>
            {
                editor_obj.map(obj=>{
                    if(obj.id===show_editor){
                        console.log('we are here',obj.file_name)
                        return(
                            <Link target='_blank' download to={`/resources/editor_requests/${obj.file_name}`}>
                                <div className="has-text-centered">
                                <button className="button is-normal is-success">Download C.V</button>

                                </div>
                            </Link>

                        )
                        
                    }
                        
                })
            }
        </div>
    )
}
const Editor_list = ({editor_obj,set_re_render,set_show_editor})=>{
    return(
        <table className="back_journal_table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Date</th>
                    <th>First name</th>
                    <th>Options</th>
                </tr>
            </thead>
            <tbody>
                {
                    editor_obj.map(obj=>(
                        <tr key={`editor_req ${obj.id}`}>
                            <td>
                                {obj.id}
                            </td>
                            <td>{obj.date}</td>
                            <td>
                                {obj.first_name}
                            </td>
                            <td>
                                <button onClick={()=>set_show_editor(obj.id)} className='button is-info'><GrOverview size={'20px'}/></button>
                                <button onClick={()=>delete_editor(obj.id)} className="button is-danger"><AiFillDelete/></button>
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
    function delete_editor(id){
        let is_delete = window.confirm('Do you really wanna delete?')
        if(is_delete){
            axios.delete(window.server_url+'/editor_requests/'+id)
            .then(response=>set_re_render(response.data))
            .catch(err=>console.log(err))
        }
    }
}

export default Editor_requests;