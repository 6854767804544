import '../../../css/back_office/back_journal_archive.css'
import {useState,useEffect} from 'react';
import axios from 'axios';
import {AiFillDelete,AiFillEdit} from 'react-icons/ai';

const Back_journal_archive = ({journal_id})=>{
    const [article_obj,set_article_obj] = useState([]);
    const [art_update,set_art_update] = useState(null);
    
    let referrer = document.referrer;
    useEffect(()=>{
        window.show_loader()

        axios.get(`${window.server_url}/article_db/back_journal_archive${journal_id}`)
        .then((response)=>{
            set_article_obj(response.data)
            window.hide_loader();
        })
        .catch(eror=>console.log(eror))
    },[art_update])
    
    // if(referrer.length===0){
    //     return(
    //         <h1 style={{fontSize:'100px'}}>error 403! Forbidden</h1>
    //     )
    // }
    return(
        <section id="back_journal_archive">
            <table className="back_journal_table" >
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Article Name</th>
                        <th>options</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        article_obj.map((obj)=>{
                            return(
                                <tr key={`key ${obj.id}`}>
                                <td>{obj.id}</td>
                                <td>{obj.article_title}</td>
                                <td>
                                    <button onClick={()=>window.open(`${window.client_url}/back_art_edit?id=${obj.id}`)} className="button is-success"><AiFillEdit/></button>
                                    <button onClick={()=>delete_article(obj.id,set_art_update)} className='button is-danger'><AiFillDelete/></button>
                                </td>
                            </tr>
                            )
                           
                        })
                    }
                </tbody>
            </table>
        </section>
    )
}
function delete_article(id,set_art_update){
    let is_delete = window.confirm('Do you really wanna delete?')
    if(is_delete){
        window.show_loader();
        axios.delete(`${window.server_url}/article_db/${id}`)
        .then(response=>set_art_update(response.data))
        .catch(error=>console.log(error))
    }

}
export default Back_journal_archive;