import Header from '../front_page/header_front';
import Footer from '../front_page/footer'

import axios from 'axios'
import Select from 'react-select'
import { useState,useEffect } from 'react'
import { Journal_list } from '../front_page/Main'
const Authors_rights = ()=>{
    let options=[
        {
            label:'All',value:'All'
        }
    ];
    let repeated_index = []

    const [indexing_obj,set_indexing_obj] = useState([])
    const[cat_array,set_cat_array] = useState([]);
    const [journal_obj,set_journal_obj] = useState([]);
    const [filter_cat,set_filter_cat] = useState('All');
    useEffect(()=>{
        window.show_loader()
        axios.all([
            axios.get(window.server_url+'/cats'),
            axios.get(window.server_url+'/journals/journal_intro'),
            axios.get(`${window.server_url}/indexing`)

        ])
        .then(axios.spread((...responses)=>{
            set_cat_array(responses[0].data);
            set_journal_obj(responses[1].data);
            set_indexing_obj(responses[2].data);
            window.hide_loader()

        }))
        .catch(error=>console.log(error))
    },[])
    cat_array.map(obj=>{
        let option_obj={};
        option_obj.label=obj.category;
        option_obj.value=obj.category;
        options.push(option_obj);
    })
    return(
        <div>
            <Header/>

            <div id="main_tile" className="tile is-parent is-ancestor">
            <div className='tile is-3 is-parent is-vertical'>
                    <div id='main_first_tile_child' className='notification is-white is-child box'>
                        <h1>Open Access Journals</h1>
                        <Select id='main_Select' options={options} onChange={e=>{
                            if(e.value==='All')
                                set_filter_cat(e.value)
                            else{
                                for(let i =0;i<cat_array.length;i++){
                                    if(e.value===cat_array[i].category){
                                        set_filter_cat(cat_array[i].id)
                                    }
                                }
                            }
                        } 
                        }/>
                        {
                        // displaying journal names with icons in a loop gotten from the data
                            journal_obj.map(obj=>{
                                if(filter_cat==='All')
                                    return <Journal_list key={obj.id} journal_icon={obj.id} journal_abbrevation={obj.journal_abbrevation} journal_slug={obj.journal_slug}/>      
                                else if(filter_cat===obj.journal_cat_id)
                                    return <Journal_list key={obj.id} journal_icon={obj.id} journal_abbrevation={obj.journal_abbrevation} journal_slug={obj.journal_slug}/>      
                        })
                            
                    }
                </div>
                <div id="indexing_container" className="is-child notification is-white box">
                    {
                        indexing_obj.length>0&&indexing_obj.map((index_obj,index)=>{
                            let unique_url = true
                            if(index!==0){
                                for(let i = 0;i<repeated_index.length;i++){
                                    if(repeated_index[i]===index_obj.url){
                                        unique_url = false
                                        break
                                    }
                                }
                            }
                            if(unique_url){
                                repeated_index.push(index_obj.url)
                                return(
                                    <a key={`${index} indexing`} href={index_obj.url}>
                                        <img src={`/resources/journal_indexing/${index_obj.file_name}`} alt="" />
                                    </a>
                                )
                            }
                        })
                    }
                </div>
            </div>
                <div className="tile is-parent">
                <div id="plagiarism_container">
            <div id="plagiarism_policy_container">
                <h1 id='authors_right_h1' >AUTHOR'S RIGHTS</h1>
                <p>
                    <ul className='text_justify' id="bullet_list">
                        <li>The authors may withdraw their submitting manuscript before publication. If they do so they must fill in the “Withdrawal Form” and sent for approval. After getting approval by the Editorial Board, author can be sent to other journals for publication. The authors have the right to appeal against the editorial board decisions or review reports. If they do so they must justify their objection and provide the necessary documents or evidence to their application. If the editor comes to the point, a fresh review may be commencing</li>
                    </ul>
                </p>
                <h1 id='authors_right_h2'>AUTHOR'S DUTIES</h1>
                <p>
                    <ul className='text_justify' id="bullet_list">
                        <li>Authors must ensure that the submitted work is their own and it is completely unique. The authors must certify that the submitted manuscript has never been published elsewhere before and authors should properly cite or quote others person work. Authors sign a declaration stating that the manuscript and illustrations contained therein are original, or that they have taken all necessary steps to ensure that the manuscript and illustrations contained therein are original.</li>
                        <li>If the authors have used of other persons work, that should be appropriately cited.</li>
                        <li>Authors must ensure that the submitted manuscript is not currently being considered for publication in any other Journal. It is unethical publishing behaviour to submit the same paper to multiple journals.</li>
                        <li>When an author discovers a significant error or inaccuracy in his or her own published work, it is the author's responsibility to notify the journal editor or publisher as soon as possible and cooperate with the editor to retract or correct the paper.</li>
                        <li>Author must be ensuring that at the time of submission, authors should double-check that the author group, the Corresponding Author, and the author order are all correct. Adding and/or removing authors during the revision process are generally not allowed, but it may be necessary in some cases. The reasons for authorship changes should be explained in detail. Please keep in mind that after your manuscript has been accepted, you will not be able to change your authorship. </li>
                    </ul>
                </p>
            </div>

        </div>
                </div>
            </div>
            <Footer/>

        </div>
        
      
    )
}

export default Authors_rights