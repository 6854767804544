import Header from './header_front'
import Title from './title'
import Search from './search'
import {Main} from './Main'
import Footer from './footer'

const Front_page = ()=>{
    return(
        <div>
            <Header/>
            <Title/>
            <Search btn_color='#4f5671' menu_color='white'/>
            <Main/>
            <Footer/>
        </div>
        
    )
}

export default Front_page