import logo from '../../img/logo.png'
import '../../css/front_page/header_front.css'
import {useState,useEffect} from 'react'
import axios from 'axios';
// icon imports
import {SiHomeassistant} from 'react-icons/si';
import {FaJournalWhills} from 'react-icons/fa';
import {FaInfo} from 'react-icons/fa';
import {FaUser} from 'react-icons/fa';
import {SiOpenapiinitiative} from 'react-icons/si';
import {GrInfo} from 'react-icons/gr';
import {MdPayment} from 'react-icons/md';
import {Link} from 'react-router-dom';
import {ImBooks} from 'react-icons/im';


const article_type_options=[
    {value:'All Article Types',label:'All Article Types'},
    {volume:'All',label:'All'},
    {volume:'Article',label:'Article'},
    {volume:'Types',label:'Types'},
    {volume:'Article',label:'Article'},
    {volume:'Review',label:'Review'},
    {volume:'Communication',label:'Communication'},
    {volume:'Editorial',label:'Editorial'},
    {volume:'Addendum',label:'Addendum'},
    {volume:'Book',label:'Book'},
    {volume:'Review',label:'Review'},
    {volume:'Brief',label:'Brief'},
    {volume:'Report',label:'Report'},
    {volume:'Case',label:'Case'},
    {volume:'Report',label:'Report'},
    {volume:'Comment',label:'Comment'},
    {volume:'Commentary',label:'Commentary'},
    {volume:'Concept',label:'Concept'},
    {volume:'Paper',label:'Paper'},
    {volume:'Conference',label:'Conference'},
    {volume:'Report',label:'Report'},
    {volume:'Correction',label:'Correction'},
    {volume:'Creative',label:'Creative'},
    {volume:'Data',label:'Data'},
    {volume:'Descriptor',label:'Descriptor'},
    {volume:'Discussion',label:'Discussion'},
    {volume:'Entry',label:'Entry'},
    {volume:'Erratum',label:'Erratum'},
    {volume:'Essay',label:'Essay'},
    {volume:'Expression',label:'Expression'},
    {volume:'Concern',label:'Concern'},
    {volume:'Extended',label:'Extended'},
    {volume:'Abstract',label:'Abstract'},
    {volume:'Guidelines',label:'Guidelines'},
    {volume:'Hypothesis',label:'Hypothesis'},
    {volume:'Interesting',label:'Interesting'},
    {volume:'Images',label:'Images'},
    {volume:'Letter',label:'Letter'},
    {volume:'New',label:'New'},
    {volume:'Book',label:'Book'},
    {volume:'Received',label:'Received'},
    {volume:'Obituary',label:'Obituary'},
    {volume:'Opinion',label:'Opinion'},
    {volume:'Perspective',label:'Perspective'},
    {volume:'Proceedings',label:'Proceedings'},
    {volume:'Project',label:'Project'},
    {volume:'Report',label:'Report'},
    {volume:'Protocol',label:'Protocol'},
    {volume:'Registered',label:'Registered'},
    {volume:'Report',label:'Report'},
    {volume:'Reply',label:'Reply'},
    {volume:'Retraction',label:'Retraction'},
    {volume:'Short',label:'Short'},
    {volume:'Note',label:'Note'},
    {volume:'Study',label:'Study'},
    {volume:'Protocol',label:'Protocol'},
    {volume:'Systematic',label:'Systematic'},
    {volume:'Review',label:'Review'},
    {volume:'Technical',label:'Technical'},
    {volume:'Note',label:'Note'},
    {volume:'Tutorial',label:'Tutorial'},
    {volume:'Viewpoint',label:'Viewpoint'},
]


const Header = ()=>{
    const [is_search,set_is_search] = useState(false);
    const [journal_intro_obj,set_journal_intro_obj] = useState([])
    useEffect(() => {
    window.show_loader()

        axios.get(window.server_url+'/journals/journal_intro')
            .then(response => {
                set_journal_intro_obj(response.data)
                window.hide_loader()
            })
            .catch(error => console.log(error))
    }, [])
    return(
        <section id='front_page_navbar_container'>

        <nav id = 'front_page_navbar' className="navbar is-fixed-top">
            <div id="navbar" className="navbar-brand">
                <Link  to = '/'className="navbar-item">
                    <img id = "logo" alt="logo image" src={logo}/>
                </Link>
                <a onClick={()=>navburger_toggle()} id="navbar-burger" role="button" className="navbar-burger" aria-label="menu">
                    <span></span>
                    <span></span>
                    <span></span>
                </a>
                <span id = "search" className= "navbar-item" href="" onClick={()=>set_is_search(!is_search)}>
                    <i className="material-icons">search</i>
                </span>
            </div>
            <div id = "navbar-menu" className="navbar-menu">
                <div id = "navbar-start" className="navbar-start">
                    <Link to='/' className="navbar-item">
                        <span className='header_front_icon_cont'>
                            <SiHomeassistant color={'#0077b6'}/>
                        </span>
                        Home
                    </Link>
                    <Link to='/journals-section' className="navbar-item">
                        <span className="header_front_icon_cont">
                            <FaJournalWhills color={'#ffba08'}/>
                        </span>
                        Journals
                    </Link>
                    <span className="navbar-item has-dropdown is-hoverable">
                        <a className="navbar-link">
                            <span className="header_front_icon_cont">
                                <FaInfo color={'yellowgreen'}/>
                            </span>
                            Information
                        </a>
          
                        <div className="navbar-dropdown">
                            <Link to='/for-reviewer' className="navbar-item">For reviewer</Link>
                            <Link to='/for-editor' className="navbar-item">For editor</Link>
                            <Link to='/open-access-policy' className="navbar-item">Open Access Policy</Link>
                            <Link to='/peer-review-policy' className="navbar-item">Peer-review Policy</Link>

                        </div>
                    </span>
                    <span className="navbar-item has-dropdown is-hoverable">
                        <a className="navbar-link">
                            <span className="header_front_icon_cont">
                                <FaUser color={'#f94144'}/>
                            </span>
                            For Authors
                        </a>
                        
                        <div className="navbar-dropdown">
                            <Link to='/publication-ethics' className="navbar-item">Research and Publication Ethics</Link>
                            <Link to='/article-processing' className="navbar-item">Article Processing Charge</Link>
                            <Link to='/plagiarism-policy' className="navbar-item">Plagiarism Policy</Link>
                            <Link to='/copyright-policy' className="navbar-item">Copyright Policy</Link>
                            <Link to='/conflicts-of-interest' className='navbar-item'>Conflicts of interest</Link>
                            <Link to='/authors-rights' className='navbar-item'>Author's Right &amp; Duties</Link>
                        </div>
                    </span>
                    <Link to='/thesis' className="navbar-item">
                        <span className="header_front_icon_cont">
                            <SiOpenapiinitiative color={'#c9184a'}/>
                        </span>
                        Thesis
                    </Link>
                    {/* activate to get books menu */}
                    <Link to='/books' className="navbar-item">
                        <span className="header_front_icon_cont">
                            <ImBooks color={'#e76f51'}/>
                        </span>
                        Books
                    </Link>
                    <Link to='/about' className = "navbar-item">
                        <span className="header_front_icon_cont">
                            <GrInfo/>
                        </span>
                        About
                        
                    </Link>
                    <Link to='/payments' className = "navbar-item">
                        <span className="header_front_icon_cont">
                            <MdPayment color='#00b4d8'/>
                        </span>
                        Payments
                    </Link>
                </div>
                <div id="navbar-end" className="navbar-end">
                    <div className="navbar-item">
                        <Link className='button is-success ' to='/submit' id = "submit">
                            Submit Manuscript
                        </Link>
                    </div>
                </div>
            </div>
            
            {journal_intro_obj.length>0&&is_search&&<Search journal_intro_obj={journal_intro_obj} color={'#4f5671'}/>}
            
        </nav>
</section>
    
    )
}

const Search = ({journal_intro_obj,color})=>{
    return(
        <div style={{backgroundColor:color}}id="hf_search_container">
            <div style={{backgroundColor:color}} id="hf_arrow_basic"></div>
            <h1>Search for Articles</h1>
        
            <form onSubmit={e=>search_now(e)} action="" id='hf_form'>
                <span id='hf_basic_span_1'>
                    <input placeholder = 'Title / Keyword' type="text" name="" id="" className="input hf_inp_text" />
                    <input placeholder = 'Author / Affiliation' type="text" name="" id="" className="input hf_inp_text" />  
                </span>
                <select className='hf_select_1'>
                    <option value="0">All Journals</option>
                    {
                        journal_intro_obj.map(obj=>(
                            <option key={`${obj.id}`} value={obj.id}>{obj.journal_name}</option>
                        ))
                    }
                </select>
                <select className='hf_select_2'>
                    <option value="All articles">All Article Types</option>
                    {
                        article_type_options.map((obj,index)=>(
                            <option key={`art_options${index}`} value={obj.volume}>{obj.volume}</option>
                        ))
                    }
                </select>
                <span id='hf_basic_span_2'>
                        <a style={{visibility:'hidden'}}>Advanced Search</a>
                        <input style={{cursor:'pointer'}} type="submit" value="" value='Search' />
                </span>                
            </form>
        </div>
    )
}
function navburger_toggle(){
    let navbar_burger = document.getElementById('navbar-burger');
    let navbar_menu = document.getElementById('navbar-menu');
    if(navbar_burger.className==='navbar-burger'){
        navbar_burger.className = 'is-active navbar-burger';
        navbar_menu.className = 'is-active navbar-menu';
    }
    else{
        navbar_burger.className='navbar-burger';
        navbar_menu.className='navbar-menu';
    }
}
export default Header

function search_now(e){
    e.preventDefault();
    let title = e.target[0].value;
    let author = e.target[1].value;
    let journal_id = e.target[2].value;
    if(!title.length)
        title='all'
    if(!author.length)
        author='all'
    window.open(`/search?title=${title}&author=${author}&journal_id=${journal_id}`,'_self')

}