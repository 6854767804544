import { useState,useEffect } from "react";
import axios from 'axios'
import { AiFillDelete } from "react-icons/ai";
import '../../../css/newsletter.css'

const Newsletter = ()=>{
    const [news_update,set_news_update] = useState()
    const [news_obj,set_news_obj] = useState([])

    useEffect(()=>{
        window.show_loader()
        axios.get(`${window.server_url}/newsletter`)
        .then(response=>{
            set_news_obj(response.data)
            window.hide_loader()
        })
        .catch(eror=>console.log(eror))
    },[news_update])
    return(
        <div id="newsletter_cont">
            <h1 className="pt-5 has-text-centered has-text-white title">Newsletter subscribers</h1>
            <table className="back_journal_table">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Options</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        news_obj.map(obj=>(
                            <tr>
                                <td>{obj.id}</td>
                                <td>{obj.name}</td>
                                <td>{obj.email}</td>
                                <td>
                                    <button onClick={()=>delete_newsletter(obj.id)} className="button is-danger"><AiFillDelete/></button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>

        </div>
    )
    function delete_newsletter(id){
        axios.delete(`${window.server_url}/newsletter/${id}`)
        .then(response=>set_news_update(response.data))
        .catch(eror=>console.log(eror))
    }
}

export default Newsletter;