import Header from '../front_page/header_front'
import { Journal_list } from '../front_page/Main';
import { useState,useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import '../../css/for_authors_pg/article_processing.css';
import Footer from '../front_page/footer'
import { Link } from 'react-router-dom';

const Article_processing = ()=>{
    let options=[
        {
            label:'All',value:'All'
        }
    ];
    let repeated_index = []

    const [indexing_obj,set_indexing_obj] = useState([])
    const[cat_array,set_cat_array] = useState([]);
    const [journal_obj,set_journal_obj] = useState([]);
    const [filter_cat,set_filter_cat] = useState('All');
    useEffect(()=>{
        window.show_loader()
        axios.all([
            axios.get(window.server_url+'/cats'),
            axios.get(window.server_url+'/journals/journal_intro'),
            axios.get(`${window.server_url}/indexing`)

        ])
        .then(axios.spread((...responses)=>{
            set_cat_array(responses[0].data);
            set_journal_obj(responses[1].data);
            set_indexing_obj(responses[2].data);
            window.hide_loader()

        }))
        .catch(error=>console.log(error))
    },[])
    cat_array.map(obj=>{
        let option_obj={};
        option_obj.label=obj.category;
        option_obj.value=obj.category;
        options.push(option_obj);
    })
    return(
        <div id="article_processing_container">
            <Header/>
            <div id="main_tile" className="is-ancestor is-parent tile">
            <div className='tile is-3 is-parent is-vertical'>
                    <div id='main_first_tile_child' className='notification is-white is-child box'>
                        <h1>Open Access Journals</h1>
                        <Select id='main_Select' options={options} onChange={e=>{
                            if(e.value==='All')
                                set_filter_cat(e.value)
                            else{
                                for(let i =0;i<cat_array.length;i++){
                                    if(e.value===cat_array[i].category){
                                        set_filter_cat(cat_array[i].id)
                                    }
                                }
                            }
                        } 
                        }/>
                        {
                        // displaying journal names with icons in a loop gotten from the data
                            journal_obj.map(obj=>{
                                if(filter_cat==='All')
                                    return <Journal_list key={obj.id} journal_icon={obj.id} journal_abbrevation={obj.journal_abbrevation} journal_slug={obj.journal_slug}/>      
                                else if(filter_cat===obj.journal_cat_id)
                                    return <Journal_list key={obj.id} journal_icon={obj.id} journal_abbrevation={obj.journal_abbrevation} journal_slug={obj.journal_slug}/>      
                        })
                            
                    }
                </div>
                <div id="indexing_container" className="is-child notification is-white box">
                    {
                        indexing_obj.length>0&&indexing_obj.map((index_obj,index)=>{
                            let unique_url = true
                            if(index!==0){
                                for(let i = 0;i<repeated_index.length;i++){
                                    if(repeated_index[i]===index_obj.url){
                                        unique_url = false
                                        break
                                    }
                                }
                            }
                            if(unique_url){
                                repeated_index.push(index_obj.url)
                                return(
                                    <a key={`${index} indexing`} href={index_obj.url}>
                                        <img src={`/resources/journal_indexing/${index_obj.file_name}`} alt="" />
                                    </a>
                                )
                            }
                        })
                    }
                </div>
            </div>
                <div id='processing_fees_container' className="tile is-parent">
                    <div className='is-child notification is-white'>
                        <div id='processing_title_box'>
                            <h1>Processing Fees</h1>
                        </div>
                        <p className='text_justify' >
                        To publish an article in open access journal of Addaiyan International Publishers, authors are required to pay an article-processing charge for editorial frameworks and peer-review processes, staff remuneration, copy editing and production, electronic hosting, archiving and marketing related to the promotion of published work. 
                        <br/>
                        <br />
                        <b>The Article processing charge for all published articles is as follows, (GST or local taxes extra) </b>
                        <br />
                        <br />
                        <b>For Foreign Country Authors</b>
                        <ul style={{marginLeft:'13px'}} id="bullet_list">
                            <li>Normal Processing Fee &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$50</li>
                            <li>Fast Track Processing Fee &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   $150</li>
                        </ul>  
                        <br />
                        <b>For Indian Authors</b>
                        <ul>
                            <li>Normal Processing Fee &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;      Rs. 2000/-</li>
                            <li>Fast Track Processing Fee &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Rs. 3000/-</li>
                        </ul>
                        </p>
                        <div id='article_fees_boxes' className="columns">
                            <div id='article_fees_box1' className="column">
                                <div>
                                    <h3>Normal Process</h3>
                                    <h1>$50 or ₹1500</h1>
                                    <h4>Normal peer-review</h4>
                                    <h4>Acknowledgement within 24-48 hours</h4>
                                    <h4>Review Process take upto 10 days</h4>
                                    <h4>Gallery Proof within 7 days after Payment</h4>
                                    <h4>Monthly Publish after Gallery Proof confirmation by author</h4>
                                    <Link style={{textDecoration:'none'}} to='/payments'><button className="is-medium button">Go Normal</button></Link>
                                </div>                           
                            </div>
                            <div id='article_fees_box2' className="column">
                                <div>
                                    <h3>Fast Track Process</h3>
                                    <h1>$100 or ₹3000</h1>
                                    <h4>Rigorous and fast peer-review</h4>
                                    <h4>Acknowledgement within 12 hours</h4>
                                    <h4>Review Process take upto 2 days</h4>
                                    <h4>Gallery Proof within 24 hours after Payment</h4>
                                    <h4>Immediate Publish after Gallery Proof confirmation by author</h4>
                                    <Link style={{textDecoration:'none'}} to='/payments'><button className="is-medium button">Go Fast Track</button></Link>
                                    
                                </div>                           
                            </div>
                        </div>
                        <table id='article_processing_table'>
                            <thead>
                                <tr>
                                    <th><h2>Journal</h2></th>
                                    <th><h2>Normal Fee</h2></th>
                                    <th><h2>Fast Track Fee</h2></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    journal_obj.map(obj=>(
                                        <tr>
                                            <td>{obj.journal_name}</td>
                                            <td>{`${obj.normal_fee} ${obj.normal_fee_usd}`}</td>
                                            <td>{`${obj.fast_track_fee} ${obj.fast_track_fee_usd}`}</td>
                                        </tr>
                                    ))
                                }
                               
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
function show_loader(){
    document.getElementById("loading_container").style.display='block';
    document.getElementById('root').style.display='none';
}
function hide_loader(){
    document.getElementById("loading_container").style.display='none';
    document.getElementById('root').style.display='block';
}
export default Article_processing;