import '../../css/front_page/Main.css'
import Select from 'react-select';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {useState,useEffect} from 'react';
import axios from 'axios';
import {GoCloudDownload} from 'react-icons/go';
import { Link } from 'react-router-dom';
import {AiOutlineCloseCircle} from 'react-icons/ai';

const image_1 = '/resources/front_page/slide_show/1.jpg';
const image_2 = '/resources/front_page/slide_show/2.jpg';
const image_3 = '/resources/front_page/slide_show/3.jpg';
const image_4 = '/resources/front_page/slide_show/4.jpg';
const image_5 = '/resources/front_page/slide_show/5.jpg';

let limit_index = 0
let limit_no = 10

const Main = () => {
    let options=[
        {
            label:'All',value:'All'
        }
    ];
    const[cat_array,set_cat_array] = useState([]);
    const [journal_obj,set_journal_obj] = useState([]);
    const [filter_cat,set_filter_cat] = useState('All');
    const [slide_texts,set_slide_texts] = useState([]);
    //below are ones for setting the big carrow
    const [slider_name_array,set_slider_name_array] = useState([]);
    const [selected_item,set_selected_item] = useState(null);
    const [article_intro,set_article_intro] = useState([]);
    const [box_obj,set_box_obj] = useState([]);
    const [indexing_obj,set_indexing_obj] = useState([])
    useEffect(()=>{
        window.show_loader()
        limit_index = 0
        limit_no = 10
        axios.all([
            axios.get(window.server_url+'/cats'),
            axios.get(window.server_url+'/titles/slide_texts'),
            axios.get(window.server_url+`/article_db/article_intro^${limit_index}^${limit_no}`), //0 and 10 are sql query limit numbers
            axios.get(window.server_url+'/journals/journal_intro'),
            axios.get(`${window.server_url}/boxes`),
            axios.get(`${window.server_url}/indexing`)

        ])
        .then(axios.spread((...responses)=>{
            set_cat_array(responses[0].data);
            set_slide_texts(responses[1].data);
            set_article_intro(responses[2].data);
            set_journal_obj(responses[3].data);
            set_box_obj(responses[4].data);
            set_indexing_obj(responses[5].data);
            window.hide_loader()

        }))
        .catch(error=>console.log(error))
    },[])

    let repeated_index = []
    cat_array.map(obj=>{
        let option_obj={};
        option_obj.label=obj.category;
        option_obj.value=obj.category;
        options.push(option_obj);
    })
    return (
        <div id="main_tile" className="is-ancestor is-parent tile">
            <div id='success_submit' className="button is-large is-success">Successfully Uploaded!!</div>
            
            <div className='tile is-3 is-parent is-vertical'>
                <div id='main_first_tile_child' className='notification is-white is-child box'>
                    <h1>Open Access Journals</h1>
                    <Select id='main_Select' options={options} onChange={e=>{
                            if(e.value==='All')
                                set_filter_cat(e.value)
                            else{
                                for(let i =0;i<cat_array.length;i++){
                                    if(e.value===cat_array[i].category){
                                        set_filter_cat(cat_array[i].id)
                                    }
                                }
                            }
                        } 
                    }/>
                    {
                        // displaying journal names with icons in a loop gotten from the data
                        journal_obj.map(obj=>{
                                if(filter_cat==='All')
                                    return <Journal_list key={obj.id} journal_icon={obj.id} journal_abbrevation={obj.journal_abbrevation} journal_slug={obj.journal_slug}/>      
                                else if(filter_cat===obj.journal_cat_id)
                                    return <Journal_list key={obj.id} journal_icon={obj.id} journal_abbrevation={obj.journal_abbrevation} journal_slug={obj.journal_slug}/>      
                            })
                            
                    }
                </div>
                <div id="indexing_container" className="is-child notification is-white box">
                    {
                        indexing_obj.length>0&&indexing_obj.map((index_obj,index)=>{
                            let unique_url = true
                            if(index!==0){
                                for(let i = 0;i<repeated_index.length;i++){
                                    if(repeated_index[i]===index_obj.url){
                                        unique_url = false
                                        break
                                    }
                                }
                            }
                            if(unique_url){
                                repeated_index.push(index_obj.url)
                                return(
                                    <a key={`${index} indexing`} target='_blank' href={index_obj.url}>
                                        <img src={`/resources/journal_indexing/${index_obj.file_name}`} alt="" />
                                    </a>
                                )
                            }
                        })
                    }
                </div>
            </div>
            {/* end first parent tile */}
            <div id='main_slide_tile' className='tile is-6 is-parent'>
                <div id='main_center_tile_child' className="is-child notification is-white">
                    <Caro slide_text_obj={slide_texts}/>
                    <h1 style={{textAlign:'left',fontSize:'1.4rem',marginTop:'16px',marginLeft:'20px',color:'#444'}}>Recent Articles</h1>      
                    {/* big screen slider show for article */}
                    <div id='big_screen_carrow'>
                        <div id="big_screen_carrow_container">
                            <div onClick={()=>{
                                document.getElementById('big_screen_carrow').style.display='none';
                            }} id="main_close_btn">
                                <AiOutlineCloseCircle size={'40px'} color={'white'}/>
                            </div>
                            <Carousel selectedItem={selected_item} showIndicators={false} showThumbs={false}>
                                {
                                    slider_name_array.map((slider_name,index)=>(
                                        <div key={`main ${index}`}>
                                            <div className='big_img_article'>
                                                <img src={`/resources/article_slides/${slider_name}`}/>
                                            </div>
                                            <p style={{color:'white',fontSize:'1.3rem',fontWeight:'400',marginTop:'20px'}}>{`Figure ${index+1}`}</p>
                                        </div>
                                    ))
                                }
                            </Carousel>
                        </div>
                    </div>  
                    {
                        article_intro.length&&article_intro[0].map(article_obj=>{
                            let slider_urls=[];
                            for(let i =0;i<article_obj.slider_count;i++){
                                slider_urls.push(`${article_obj.doi_number}.${i}.png`)
                            }
                            return(
                                <Article_child
                                key={`${article_obj.doi_number}`}
                                title = {article_obj.article_title}
                                authors = {article_obj.author_name}
                                date = {article_obj.article_date.substr(0,10)}
                                abstract = {convertToPlain(article_obj.abstract_content)}
                                slider_name_array={slider_urls}
                                set_selected_item = {set_selected_item}
                                set_slider_name_array={set_slider_name_array}
                                doi_number ={article_obj.doi_number}
                                article_file_name={article_obj.article_file_name}
                                volume = {article_obj.volume}
                                issue = {article_obj.issue}
                                id = {article_obj.id}
                                page_no = {article_obj.page_no}
                                article_abbrevation={article_obj.article_abbrevation}
                                />
                            )
                          
                        })
                    }
                    {
                       article_intro.length>0&& article_intro[1].length>0 && <a id='load_more_article' onClick={()=>load_more_articles(article_intro,set_article_intro)}>More articles...</a>
                    }
                </div>
            </div>
            <div className='tile is-3 is-parent is-vertical'>
                <form onSubmit={(e)=>submit_newsletter(e)} id = 'main_newsletter' className="is-child notification is-white box">
                    <h1>NEWSLETTER UPDATES</h1>
                    <p>Enter your details below to get notified to our latest journals and articles.</p>
                    <label>Name</label>
                    <input required={true} id='newsletter_name' className="input" type="text" placeholder="Your Name *"/>
                    <label>Email</label>
                    <input required={true} id='newsletter_email' className="input" type="email" placeholder="Email *"/>
                    <h3 className='block'>I accept all the submitting articles</h3>
                    <a>Terms &amp; conditions</a>
                    <br className='block'/>
                    <button id='main_newsletter_btn' className="button is-primary">Submit Now</button>
                </form>                
                <div id='main_nums_container' className="box is-child notification is-white">
                    <h1>Numbers</h1>
                    {
                        box_obj.length && 
                        <>
                            <Nums_individual_box number={`${box_obj[0][0].journals}`} sub_title='Online Journals'/>
                            <Nums_individual_box number={`${box_obj[1][0].articles}`} sub_title='Articles Published'/>
                            <Nums_individual_box number={`${box_obj[2][0].views}`} sub_title='Article Views'/>
                            <Nums_individual_box number={`${box_obj[3][0].users}`} sub_title='Editors'/>
                            <Nums_individual_box number={`${box_obj[4][0].downloads}`} sub_title='Downloads'/>
                            <Nums_individual_box number={`${box_obj[5][0].indexing_count}`} sub_title='Indexing'/>
                        </>
                    }
                   
                </div>
            </div>
        </div>
    )
}
function submit_newsletter(e){
    e.preventDefault()
    let newsletter_email = document.getElementById('newsletter_email').value
    let newsletter_name = document.getElementById('newsletter_name').value

    let jsondata = {
        newsletter_email:newsletter_email,
        newsletter_name:newsletter_name
    }
    axios.post(`${window.server_url}/newsletter`,jsondata)
    .then(()=>{
        document.getElementById('success_submit').style.zIndex=100

        setTimeout(()=>{

            document.getElementById('success_submit').style.zIndex=-100
        },2000)
        document.getElementById('newsletter_name').value=""
        document.getElementById('newsletter_email').value=""
    })
    .catch(err=>console.log(err))
}
function load_more_articles(article_intro,set_article_intro){
    limit_index+=limit_no;
    axios.get(window.server_url+`/article_db/article_intro^${limit_index}^${limit_no}`) //0 and 10 are sql query limit numbers
    .then(response=>{
        let current_article_intro = [...article_intro]
        let new_article_intro = response.data
        
        for (let i =0;i<new_article_intro[0].length;i++){
            current_article_intro[0].push(new_article_intro[0][i])
        }
        current_article_intro[1] = new_article_intro[1]
        set_article_intro(current_article_intro)
    })
    .catch(err=>console.log(err))
}

const Article_child = ({page_no,article_abbrevation,id,volume,issue,download_icon_size='15px',article_child_style={marginLeft:'20px',marginRight:'20px',marginTop:'15px',marginBottom:'15px'},title,authors,date,abstract,slider_name_array,set_selected_item,set_slider_name_array,doi_number,article_file_name}) =>{
    const initial_abstract = abstract.substr(0,340);
    const final_abstract = abstract.substr(340);
    return(
        <div style={article_child_style} id='article_child'>
            <div id="article_child_nav">
                <h5>Open Access</h5>
                <h4>Article</h4>
                <h3>seperator</h3>
                <Link onClick={()=>increase_download_count(doi_number)} target='_blank' download to={`/resources/article_documents/${article_file_name}`}>
                    <GoCloudDownload size={download_icon_size} color='#38F'/>
                </Link>
            </div>
            
            
            <Link to={`/article_id=${id}`}id='main_journo_title'>
                {title}
            </Link>
            <h6>by</h6>
            <span id='main_author'>{authors}</span>
            <h2>{volume}({issue}) <a style={{color:'#1a69ff'}} href={`https://doi.org/${doi_number}`}>https://doi.org/{doi_number}</a> - {date}</h2>
            <h5 id='page_nuumber'>{article_abbrevation} {page_no}</h5>

            <p className='text_justify'>
                <a id='main_abstract' href='#'>Abstract</a>
                {initial_abstract}
                <span id="more">
                    {final_abstract}
                </span>
                <a onClick={(e)=>{
                    e.target.parentNode.children[1].style.display='inline';
                    e.target.style.display='none';
                }} id="read_more">...Read More</a>
            </p>
            <div id="article_child_caro_whole_container">
                <a onClick={(e)=>{
                    if(e.target.parentNode.children[1].style.display==='none')
                        e.target.parentNode.children[1].style.display='block'
                    else
                        e.target.parentNode.children[1].style.display='none'

                }}>Show Figures</a>
                <div style={{marginTop:'10px',display:'none'}}>
                    <Carousel showIndicators={false} showThumbs={false}>
                        {
                            slider_name_array.map((slider_name,index)=>(
                                <div key={`main ${slider_name}`}>
                                    <div onClick={(e)=>{
                                        document.getElementById('big_screen_carrow').style.display='flex';
                                        set_selected_item(index);
                                        set_slider_name_array(slider_name_array);

                                    }} className='caro_img_article'>
                                        <img src={`/resources/article_slides/${slider_name}`}/>
                                    </div>

                                    <p>{`figure ${index+1}`}</p>
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
            </div>
        </div>
    )
    function increase_download_count(doi_number){
        axios.put(`${window.server_url}/article_db/downloads${doi_number}`)
        .catch(error=>console.log(error))
    }

}
export {Article_child};
const Nums_individual_box = ({id='main_numbers_box',number,sub_title}) =>{
    return(
        <div  id={id}>
            <h3>{number}</h3>
            <p>{sub_title}</p>
        </div>
    )
}

const Journal_list = ({journal_icon,journal_abbrevation,journal_slug}) =>{
    return(
        <Link to={`/${journal_slug}/Home`} id='main_journal_cont' className='column columns level'>
                <img src={`/resources/journal_icons/${journal_icon}.png`} alt="" />
                <h4 className='level-item'>{journal_abbrevation}</h4>
        </Link>
    )
}

const Caro = (slide_text_obj) => {
    return (
        <Carousel infiniteLoop={true} autoPlay={true} showThumbs={false}>
            <div>
                <img src={image_1} />
                <p id='main_caro_text' className="legend">{slide_text_obj.slide_text_obj.length && slide_text_obj.slide_text_obj[0].title_content}</p>
            </div>
            <div>
                <img src={image_2} />
                <p id='main_caro_text' className="legend">{slide_text_obj.slide_text_obj.length && slide_text_obj.slide_text_obj[1].title_content}</p>
            </div>
            <div>
                <img src={image_3} />
                <p id='main_caro_text' className="legend">{slide_text_obj.slide_text_obj.length && slide_text_obj.slide_text_obj[2].title_content} </p>
            </div>
            <div>
                <img src={image_4} />
                <p id='main_caro_text' className="legend">{slide_text_obj.slide_text_obj.length && slide_text_obj.slide_text_obj[3].title_content} </p>
            </div>
            <div>
                <img src={image_5} />
                <p id='main_caro_text' className="legend">{slide_text_obj.slide_text_obj.length && slide_text_obj.slide_text_obj[4].title_content} </p>
            </div>
        </Carousel>
    );
}

function convertToPlain(html){

    // Create a new div element
    var tempDivElement = document.createElement("div");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element 
    return tempDivElement.textContent || tempDivElement.innerText || "";
}

export {Main,Journal_list};







