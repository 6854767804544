import Header from './front_page/header_front';
import Footer from './front_page/footer';
import 'react-phone-input-2/lib/style.css';

import '../css/submit_manuscript.css'
import {useState,useEffect} from 'react';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';

const Submit_manuscript = ()=>{
    const [journal_obj,set_journal_obj] = useState([]);
    useEffect(()=>{
    window.show_loader()

        axios.get(`${window.server_url}/journals/journal_name`)
        .then(response=>{
            set_journal_obj(response.data);
            window.hide_loader()
        })
        .catch(err=>console.log(err))
    },[])
    return(
        <div id='submit_manuscript'>
            <div id="success_submit" className="button is-success is-large">Successfully Uploaded!</div>
            <Header/>
            <header>Submit Manuscript</header>
            <main className="is-ancestor is-parent tile">
                <section className="tile is-parent">
                    <div className="notification is-white is-child">
                        <main>
                            <dd>All fields are Mandatory!</dd>
                            <dt><span>Note: </span>Certificte will be generated on the basis of information provided as under. Please verify your details before submitting.</dt>
                            <form onSubmit={(e)=>submit_form(e)}>
                                <label htmlFor="select_processing">Select Processing Option</label>
                                <select id="select_processing">
                                    <option value='Normal Process'>Normal Process</option>
                                    <option value='Fast track Process'>Fast track Process</option>
                                </select>
                                <label htmlFor="manuscript_type">Manuscript Type</label>
                                <select id='manuscript_type'>
                                    <option value="Research Article">Research Article</option>
                                    <option value="Review Article">Review Article</option>
                                    <option value="Creative Writing">Creative Writing</option>
                                    <option value="Case study">Case study</option>
                                    <option value="Short Communication">Short Communication</option>
                                    <option value="Thesis (Master)">Thesis (Master)</option>
                                    <option value="Thesis (Doctoral)">Thesis (Doctoral)</option>
                                </select>
                                <label  htmlFor="Manuscript_title">Manuscript Title</label>
                                <input required={true} className='input' type="text" name="" id="Manuscript_title" />
                                <label htmlFor="stream_name">Stream Name</label>
                                <select  name="" id="stream_name">
                                    {
                                        journal_obj.map((obj,index)=>(
                                            <option key={`manu ${index}`} value={obj.journal_name}>{obj.journal_name}</option>
                                        ))
                                    }
                                </select>
                                <label htmlFor="area_of_res">Area of Research</label>
                                <input required={true} className='input' type="text" name="" id="area_of_res" />
                                <h2>Corresponsing Author's Details</h2>
                                <div>
                                    <input required={true} id='first_name' className='input' type="text" name="" placeholder='First Name'/>
                                    <input required={true} id='last_name' className='input' type="text" name="" placeholder='Last Name'/>
                                </div>
                                <input required={true} id='address_line1' className='input' placeholder='Address Line 1' type="text" name="" />
                                <input id='address_line2' className='input' placeholder='Address Line 2' type="text" name="" />
                                <div>
                                    <input required={true} className='input' type="text" placeholder='city' id="city" />
                                    <input required={true} className='input' type="text" placeholder='State/Province/Region' id="spr" />
                                    <input required={true} className='input' type="text" placeholder='Country' id="country" />
                                    <input required={true} className='input' type="text" placeholder='ZIP/Postal Code' id="zip" />
                                </div>
                                <label htmlFor="email_address">Email Address</label>
                                <input required={true} className='input' type="email" name="" id="email_address" />
                                <div>
                                    <PhoneInput
                                        country='in'
                                        containerStyle={{width:'50%'}}
                                        onChange={(value)=>phone1=value}
                                        required = {true}

                                    />
                                     <PhoneInput
                                        country='in'
                                        containerStyle={{width:'50%'}}
                                        onChange={(value)=>phone2=value}
                                    />
                                </div>
                                <label>Would you like to add Co-Authours</label>
                                
                                <input type="checkbox" name="" id="manu_checkbox" />
                                <label id='manu_checkbox_label' htmlFor="manu_checkbox"></label>
                                <div id='optional_manu'>
                                    <span>
                                        <h4>Name</h4>
                                        <input id='Name1' className='input' placeholder='1.' type="text"/>
                                        <input id='Name2' className='input' placeholder='2.' type="text"/>
                                        <input id='Name3' className='input' placeholder='3.' type="text"/>
                                        <input id='Name4' className='input' placeholder='4.' type="text"/>
                                    </span>
                                    <span>
                                        <h4>Institue/Organization</h4>
                                        <input id='institute/organization1' className='input' placeholder='Institute/Organization' type="text"/>
                                        <input id='institute/organization2' className='input' placeholder='Institute/Organization' type="text"/>
                                        <input id='institute/organization3' className='input' placeholder='Institute/Organization' type="text"/>
                                        <input id='institute/organization4' className='input' placeholder='Institute/Organization' type="text"/>

                                    </span>
                                    <span>
                                        <h4>Email ID</h4>
                                        <input id='email_id1' className='input' placeholder='Email ID' type="email"/>
                                        <input id='email_id2' className='input' placeholder='Email ID' type="email"/>
                                        <input id='email_id3' className='input' placeholder='Email ID' type="email"/>
                                        <input id='email_id4' className='input' placeholder='Email ID' type="email"/>
                                    </span>
                                </div>
                                
                                <label htmlFor="textarea_manu">Anything else you want to say</label>
                                <textarea className='textarea' name="" id="textarea_manu" cols="30" rows="10"></textarea>
                                <label htmlFor="manu_file">Upload Manuscript File</label>
                                <input required={true} className='input' type="file" name="" id="manu_file" />
                                <h3>Please upload in doc,docx,pdf format and maximum file size is 10MB only</h3>
                                <input className='input' type="submit" value="Submit" />
                            </form>
                        </main>
                    </div>
                </section>
                <section id='manu_newsletter' className="tile is-4 is-parent">
                    <div className="is-child notification is-white">
                    <form id = 'main_newsletter' className="is-child notification is-white box">
                        <h1 className='block'>NEWSLETTER UPDATES</h1>
                        <p className='block'>Enter your details below to get notified to our latest journals and articles.</p>
                        <div className="field">
                            <label className="label">Name</label>
                            <div className="control">
                            <input className="input" type="text" placeholder="Your Name *"/>
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Email</label>
                            <div className="control">
                            <input className="input" type="email" placeholder="Email *"/>
                            </div>
                        </div>
                        <h3 className='block'>I accept all the submitting articles</h3>
                        <a>Terms &amp; conditions</a>
                        <br className='block'/>
                        <button id='main_newsletter_btn' className="button is-medium is-primary">Submit Now</button>
                    </form> 
                    </div>
                </section>
            </main>
            <Footer/>
        </div>
        
    )
}

let phone1='nill',phone2='nill';
function submit_form(e){
    e.preventDefault();
    const select_processing = document.getElementById('select_processing').value
    const manuscript_type = document.getElementById('manuscript_type').value
    const manuscript_title = document.getElementById('Manuscript_title').value
    const stream_name = document.getElementById('stream_name').value
    const area_of_res = document.getElementById('area_of_res').value
    const first_name = document.getElementById('first_name').value
    const last_name = document.getElementById('last_name').value
    const address_line1 = document.getElementById('address_line1').value
    const address_line2 = document.getElementById('address_line2').value
    const city = document.getElementById('city').value
    const spr = document.getElementById('spr').value
    const country = document.getElementById('country').value
    const zip = document.getElementById('zip').value
    const email_address = document.getElementById('email_address').value
    const name1 = document.getElementById('Name1').value
    const name2 = document.getElementById('Name2').value
    const name3 = document.getElementById('Name3').value
    const name4 = document.getElementById('Name4').value
    const institute_organization1 = document.getElementById('institute/organization1').value
    const institute_organization2 = document.getElementById('institute/organization2').value
    const institute_organization3 = document.getElementById('institute/organization3').value
    const institute_organization4 = document.getElementById('institute/organization4').value
    const email_id1 = document.getElementById('email_id1').value
    const email_id2 = document.getElementById('email_id2').value
    const email_id3 = document.getElementById('email_id3').value
    const email_id4 = document.getElementById('email_id4').value
    const textarea_manu = document.getElementById('textarea_manu').value
    const manu_file = document.getElementById('manu_file').files[0]

    let pass_file_check = false;
    const allowed_extensions = ['doc','docx','pdf'];
    const file_size = manu_file.size/1024;
    let file_extension = manu_file.name.split('.').reverse()[0];

    //checking the file
    if(file_size<13000){
        for (let i of allowed_extensions){
            if(file_extension===i){
                pass_file_check=true;
                break;
            }
                
        }
    }
    if(!pass_file_check){
        document.getElementById('manu_file').style.boxShadow='red 0 0 5px 1px';
    }
    else{
        window.show_loader()
        let formdata = new FormData();
        formdata.append('processing_option',select_processing)
        formdata.append('manuscript_type',manuscript_type)
        formdata.append('manuscript_title',manuscript_title)
        formdata.append('stream_name',stream_name)
        formdata.append('area_of_research',area_of_res)
        formdata.append('first_name',first_name)
        formdata.append('last_name',last_name)
        formdata.append('address1',address_line1)
        formdata.append('address2',address_line2)
        formdata.append('city',city)
        formdata.append('state',spr)
        formdata.append('country',country)
        formdata.append('zip',zip)
        formdata.append('email',email_address)
        formdata.append('name1',name1)
        formdata.append('name2',name2)
        formdata.append('name3',name3)
        formdata.append('name4',name4)
        formdata.append('organization1',institute_organization1)
        formdata.append('organization2',institute_organization2)
        formdata.append('organization3',institute_organization3)
        formdata.append('organization4',institute_organization4)
        formdata.append('email_id1',email_id1)
        formdata.append('email_id2',email_id2)
        formdata.append('email_id3',email_id3)
        formdata.append('email_id4',email_id4)
        formdata.append('message',textarea_manu)
        formdata.append('file_name',`${first_name.replace(/\//g,'-')}_${manu_file.name.replace(/\//g,'-')}`)
        formdata.append('manuscript_document',manu_file,`${first_name.replace(/\//g,'-')}_${manu_file.name.replace(/\//g,'-')}`)
        formdata.append('phone1',phone1)
        formdata.append('phone2',phone2)

        axios.post(`${window.server_url}/manuscript`,formdata)
        .then(()=>{
            //clear all the fields
            window.hide_loader()
            document.getElementById('success_submit').style.zIndex=100
            setTimeout(()=>document.getElementById('success_submit').style.zIndex=-100,3200)
            document.getElementById('select_processing').value = ""
            document.getElementById('manuscript_type').value = ""
            document.getElementById('Manuscript_title').value = ""
            document.getElementById('stream_name').value = ""
            document.getElementById('area_of_res').value = ""
            document.getElementById('first_name').value = ""
            document.getElementById('last_name').value = ""
            document.getElementById('address_line1').value = ""
            document.getElementById('address_line2').value = ""
            document.getElementById('city').value = ""
            document.getElementById('spr').value = ""
            document.getElementById('country').value = ""
            document.getElementById('zip').value = ""
            document.getElementById('email_address').value = ""
            document.getElementById('Name1').value = ""
            document.getElementById('Name2').value = ""
            document.getElementById('Name3').value = ""
            document.getElementById('Name4').value = ""
            document.getElementById('institute/organization1').value = ""
            document.getElementById('institute/organization2').value = ""
            document.getElementById('institute/organization3').value = ""
            document.getElementById('institute/organization4').value = ""
            document.getElementById('email_id1').value = ""
            document.getElementById('email_id2').value = ""
            document.getElementById('email_id3').value = ""
            document.getElementById('email_id4').value = ""
            document.getElementById('textarea_manu').value = ""
            document.getElementById('manu_file').value = null
        }
           
        )


    }
}
export default Submit_manuscript;