import {AiFillDelete,AiFillEdit,AiOutlineCloseCircle} from 'react-icons/ai';
import {FaBox} from 'react-icons/fa';
import '../../../css/back_office/back_journal.css';
import {useEffect,useState,useRef} from 'react';
import axios from 'axios';
import JoditEditor from 'jodit-react';
import {BiAddToQueue} from 'react-icons/bi'

let description_for_add,manuscript_for_add,description_for_edit,manuscript_for_edit;
const Back_journal = ()=>{
    const [journal_obj,set_journal_obj] = useState([]);
    const [update_journal,set_update_journal] = useState('');
    const cats = useRef([]);
    const journal_id = useRef();
    const [cats_obj,set_cats_obj] = useState([]);
    const [editors_count,set_editors_count] = useState([])
    const [indexing_count,set_indexing_count] = useState([])
    const [journal_editors,set_journal_editors] = useState([])
    const [journal_editor_values,set_journal_editor_values] = useState({editor_names:[],editor_descriptions:[]})
    const [indexing_obj,set_indexing_obj] = useState([])
    const [indexing_values,set_indexing_values] = useState({indexing_labels:[],indexing_urls:[]})
    const [re_render,set_re_render] = useState(null);

    useEffect(()=>{
        window.show_loader();

        axios.all([
            axios.get(window.server_url+'/cats'),
            axios.get(window.server_url+'/journals/all'),
            axios.get(window.server_url+'/journal_editors'),
            axios.get(window.server_url+'/indexing')
        ])
        .then(axios.spread((cats_response,journals_response,journal_editors_response,indexing_response)=>{
            cats.current=cats_response.data;
            set_journal_obj(journals_response.data);
            set_cats_obj(cats_response.data)
            set_journal_editors(journal_editors_response.data)
            set_indexing_obj(indexing_response.data)
            window.hide_loader(true);
        }))
        .catch(error=>console.log(error))
    },[update_journal])
    return(
        <div className='back_journal'>
            <button onClick={()=>{
                                document.getElementById('add').style.display='flex'
                                document.getElementById('edit').style.display='none'
                                set_indexing_count([])
                                set_editors_count([])
                            }} id="add_new_journal">Add New Journal</button>
            <table className="back_journal_table">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Title</th>
                        <th>Options</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        journal_obj.map(obj=>{
                                return(
                                    <tr key={`bak_journal${obj.id}`}>
                                    <td>{obj.id}</td>
                                    <td>{obj.journal_name}</td>
                                    <td>
                                        <button onClick={()=>window.open(`${window.client_url}/manage-pages?journal_id=${obj.id}`)} className='button is-success'>Manage Pages</button>
                                        <button onClick={()=>window.open(`${window.client_url}/journal_id=${obj.id}`)} className="button is-primary"><FaBox/></button>
                                        <button onClick={()=>edit_journal(set_indexing_values,set_indexing_count,indexing_obj,set_journal_editor_values,set_editors_count,journal_editors,cats_obj,obj.journal_name,journal_obj,set_re_render,journal_id)}className="button is-link"><AiFillEdit/></button>
                                        {/* <button className="button is-warning"></button> */}
                                        {/* <button className="button is-success"><ImSun/></button> */}
                                        <button onClick={()=>remove_journal(obj.id,set_update_journal)} className='button is-danger'><AiFillDelete/></button>
                                    </td>
                                </tr>
                                )
                        })
                    }
                </tbody>
            </table>
            <Journal_form indexing_values={indexing_values} set_indexing_count={set_indexing_count} indexing_count={indexing_count} journal_editor_values = {journal_editor_values} editors_count = {editors_count} set_editors_count = {set_editors_count} cats_obj={cats_obj} title='Add New Journal' classext='add' select_options={cats.current} set_update_journal={set_update_journal}/>
            <Journal_form indexing_values={indexing_values} set_indexing_count={set_indexing_count} indexing_count={indexing_count} journal_editor_values = {journal_editor_values} editors_count = {editors_count} set_editors_count = {set_editors_count} cats_obj={cats_obj} title='Edit Journal' classext='edit' select_options={cats.current} set_update_journal={set_update_journal} id={journal_id.current}/>
        </div>
    )
}
function archive(id){
    window.open(`${window.client_url}/back-office/archive/${id}`)
}


function edit_journal(set_indexing_values,set_indexing_count,indexing_obj,set_journal_editor_values,set_editors_count,journal_editors,cats_obj,journal_name,journal_obj,set_re_render,journal_id){
    window.show_loader();
    document.getElementById('edit').style.display='flex';
    document.getElementById('add').style.display='none';
    //change map to for loop and when item found break it as map is really used to return a new element based on the array, and doesn't perform better in performance!!
    journal_obj.map(obj=>{
        if(obj.journal_name===journal_name){
            document.getElementsByClassName(`titleedit`)[0].value=obj.journal_name;
            document.getElementsByClassName(`slugedit`)[0].value=obj.journal_slug;
            document.getElementsByClassName(`frequencyedit`)[0].value=obj.frequency;
            document.getElementsByClassName(`abbrevationedit`)[0].value=obj.journal_abbrevation;
            document.getElementsByClassName(`issnedit`)[0].value=obj.ISSN;
            document.getElementsByClassName(`issn_linkedit`)[0].value=obj.journal_link;
            document.getElementsByClassName(`chiefedit`)[0].value=obj.chief_editor
            document.getElementsByClassName(`languageedit`)[0].value=obj.journal_language;
            document.getElementsByClassName(`originedit`)[0].value=obj.journal_origin;
            document.getElementsByClassName(`publisheredit`)[0].value=obj.journal_publisher;
            document.getElementsByClassName(`normal_feeedit`)[0].value=obj.normal_fee;
            document.getElementsByClassName(`fast_track_feeedit`)[0].value=obj.fast_track_fee;
            document.getElementsByClassName(`normal_fee_usdedit`)[0].value=obj.normal_fee_usd;
            document.getElementsByClassName(`fast_track_fee_usdedit`)[0].value=obj.fast_track_fee_usd;
            //getting journal cat based on journal cat id
            let journal_cattie;
            for(let i =0;i<cats_obj.length;i++){
                if(cats_obj[i].id===obj.journal_cat_id){
                    journal_cattie = cats_obj[i].category;
                }
            }


            document.getElementsByClassName('catedit')[0].value=journal_cattie;
            document.getElementsByClassName('impact_factoredit')[0].value=obj.impact_factor;
            document.getElementsByClassName('dateedit')[0].value=obj.date.substr(0,10);
            description_for_edit=obj.journal_description;
            manuscript_for_edit=obj.journal_manuscript_guideline;
            journal_id.current=obj.id;
            set_re_render(obj.id)
        }
    })
    let arr = []
    let journal_editor_values = {
        editor_names:[],
        editor_descriptions:[]
    }
    for(let i =0;i<journal_editors.length;i++){
        if(journal_editors[i].journal_id == journal_id.current){
            arr.push('yoo')
            journal_editor_values.editor_names.push(journal_editors[i].editor_name)
            journal_editor_values.editor_descriptions.push(journal_editors[i].editor_description)
        }
    }
    set_journal_editor_values(journal_editor_values)
    set_editors_count(arr)
    
    let arr2 = []
    let indexing_values={
        indexing_labels:[],
        indexing_urls:[]
    }
    for(let i =0;i<indexing_obj.length;i++){
        if(indexing_obj[i].journal_id == journal_id.current){
            arr2.push('yoo')
            indexing_values.indexing_labels.push(indexing_obj[i].label)
            indexing_values.indexing_urls.push(indexing_obj[i].url)
        }
    }
    set_indexing_values(indexing_values)
    set_indexing_count(arr2)


    window.hide_loader(true);

}


function remove_journal(id,set_update_journal){
    if(window.confirm('Are you Sure you want to delete this journal?')){
        window.show_loader();
        axios.delete(`${window.server_url}/journals/${id}`)
        .then(result=>{
            set_update_journal(result.data);
        })
        .catch(error=>console.log(error))
    }
   
}

const Journal_form = ({indexing_values,set_indexing_count,indexing_count,journal_editor_values,editors_count,set_editors_count,cats_obj,title,classext,select_options,set_update_journal,id})=>{
    const config = {
        uploader:{
            "insertImageAsBase64URI": true
        },
        toolbarAdaptive:false
        // toolbarStickyOffset:200
    }
    return(
        <form id={classext} onSubmit={e=>submit_journal(cats_obj,e,classext,set_update_journal,id)}>
                <div id={`success_submit${classext}`} className="button is-large is-success">Successfully Uploaded!!</div>
                <h1 className='is-size-4-mobile is-size-3-tablet'>{title}</h1>
                <div onClick={()=>{
                            document.getElementById(classext).style.display='none'
                            set_editors_count([])
                            set_indexing_count([])
                            }} id="close_btn"><AiOutlineCloseCircle color={'#fff'} size={'40px'}/></div>
                <h4>Journal category</h4>
                <select className={`cat${classext}`}name="" id="">
                    {
                        select_options.map((cat_obj,index)=>(
                            <option key={`${index}`} value={cat_obj.category}>{cat_obj.category}</option>
                        ))
                    }
                </select>
                <h4>Journal Title</h4>
                <input required={true}  className={`title${classext}`} type="text" name="" id="" />
                <h4>Journal slug</h4>
                <input  required={true} className={`slug${classext}`}  type="text" name="" id="" />
                <h4>Journal creation date</h4>
                <input required={true} className={`date${classext}`} type="date" name="" id="" />
                <h4>Journal Description</h4>
                <div id="back_editor">
                    {
                        classext==='add'?<JoditEditor
                                        //description for journal edit
                                            onChange={e=>{
                                                description_for_add = e;
                                            }}
                                            config={config}
                                            value={description_for_add}
                                            
                                        />:
                                        <JoditEditor
                                          //description for journal add
                                          onChange={e=>{
                                              description_for_edit = e;
                                          }}
                                          config={config}
                                          value={description_for_edit}
                                          
                                        />
                    }
                </div>
              
                <h4>Frequency</h4>
                <select className={`frequency${classext}`}name="frequency" id="">
                    <option value="Monthly">Monthly</option>
                    <option value="Bio-Monthly">Bio-Monthly</option>
                </select>
                <h4>Journal Abbrevation</h4>
                <input required={true} className={`abbrevation${classext}`} type="text" name="" id="" />
                <h4>Journal ISSN Number</h4>
                <input required={true}  className={`issn${classext}`} type="text" name="" id="" />
                <h4>Journal ISSN Link</h4>
                <input className={`issn_link${classext}`}  type="url" name="" id="" />
                <h4>Journal Chief Editor</h4>
                <input required={true} className={`chief${classext}`}  type="text" name="" id="" />
                <h4>Journal Language</h4>
                <input required={true} className={`language${classext}`}  type="text" name="" id="" />
                <h4>Journal Origin</h4>
                <input required={true} className={`origin${classext}`}  type="text" name="" id="" />
                <h4>Journal Publisher</h4>
                <input  required={true} className={`publisher${classext}`} type="text" name="" id="" />
                <h4>Journal Manuscript Guideline</h4>
                <div id="back_editor">
                    {
                        classext==='add'?
                        <JoditEditor
                        //for add journal
                            onChange={e=>{
                                manuscript_for_add=e;
                            }}
                            config={config}
                            value={manuscript_for_add}
                        
                        />:
                        <JoditEditor
                        //for edit journal 
                            onChange={e=>{
                                manuscript_for_edit=e;
                            }}
                            config={config}
                            value={manuscript_for_edit}
                        
                        />
                    }
                </div>
                <h4>Upload Display Image (upload exactly 443px x 577px image)</h4>
                <input className={`upload_img${classext}`}   type="file" src="" alt=""  />
                <h4>Normal Fee (Rs)</h4>
                <input className={`normal_fee${classext}`}  type="text" name="" id="" />
                <h4>Fast Track Fee (Rs)</h4>
                <input className={`fast_track_fee${classext}`} type="text" name="" id="" />
                <h4>Normal Fee (USD)</h4>
                <input className={`normal_fee_usd${classext}`}type="text" name="" id="" />
                <h4>Fast Track Fee (USD)</h4>
                <input className={`fast_track_fee_usd${classext}`}type="text" name="" id="" />
                <h4>Impact Factor</h4>
                <input className={`impact_factor${classext}`} type="text" name="" id="" />
                
                {/* journal editors add menu */}
                <section>
                    <div id="editor_add">
                        <h2>Add Editors</h2>
                        <button type='button' onClick={()=>add_editor()}>
                            <BiAddToQueue size='40px' color='#90e0ef'/>
                        </button>
                        <button type='button' onClick={()=>delete_editor()}>
                            <AiFillDelete size='40px' color='#ef233c'/>
                        </button>
                    </div>
                    <div id="editor_multiple_cont">
                        {
                            editors_count.map((obj,index)=>{
                                if(classext=='add'){
                                    if(index==0){
                                        return(
                                            <span key={`add${index}`}>
                                                <h2>Chief Editor</h2>
                                                <input type="text" placeholder='Editor Name' className={`editor_names${classext}`}/>
                                                <textarea className={`editor_descriptions${classext}`} placeholder='Editor Description' cols="60" rows="10"></textarea>
                                                <input className={`editor_order_no${classext}`} type="hidden" value={index} />
                                                
                                                <input className={`editor_images${classext}`} placeholder='Editor Image' type="file"/>
                                           </span>
                                        )
                                    }
                                    else if(index==1){
                                        return(
                                            <span key={`add${index}`}>
                                                <h2>Managing Editor</h2>
                                                <input type="text" placeholder='Editor Name' className={`editor_names${classext}`}/>
                                                <textarea className={`editor_descriptions${classext}`} placeholder='Editor Description' cols="60" rows="10"></textarea>
                                                <input className={`editor_order_no${classext}`} type="hidden" value={index} />
                                                
                                                <input className={`editor_images${classext}`} placeholder='Editor Image' type="file"/>
                                           </span>
                                        )
                                    }
                                    else{
                                        return(
                                            <span key={`add${index}`}>
                                                <h2>Associate Editor {index-1}</h2>
                                                <input type="text" placeholder='Editor Name' className={`editor_names${classext}`}/>
                                                <textarea className={`editor_descriptions${classext}`} placeholder='Editor Description' cols="60" rows="10"></textarea>
                                                <input className={`editor_order_no${classext}`} type="hidden" value={index} />
                                                
                                                <input className={`editor_images${classext}`} placeholder='Editor Image' type="file"/>
                                           </span>
                                        )
                                    }
    
                                }
                                else{
                                    let editor_name,editor_description;
                                    try{
                                        editor_name = journal_editor_values.editor_names[index]
                                    }
                                    catch{
                                        editor_name = ""
                                    }
                                    try{
                                        editor_description = journal_editor_values.editor_descriptions[index]
                                    }
                                    catch{
                                        editor_description =""
                                    }
                                    if(index==0){
                                        return(
                                            <span key={`add${index}`}>
                                                <h2>Chief Editor</h2>
                                                <input defaultValue={editor_name} type="text" placeholder='Editor Name' className={`editor_names${classext}`}/>
                                                <textarea defaultValue={editor_description} className={`editor_descriptions${classext}`} placeholder='Editor Description' cols="60" rows="10"></textarea>
                                                <input className={`editor_order_no${classext}`} type="hidden" value={index} />
                                                
                                                <input className={`editor_images${classext}`} placeholder='Editor Image' type="file"/>
                                           </span>
                                        )
                                    }
                                    else if(index==1){
                                        return(
                                            <span key={`add${index}`}>
                                                <h2>Managing Editor</h2>
                                                <input defaultValue={editor_name} type="text" placeholder='Editor Name' className={`editor_names${classext}`}/>
                                                <textarea defaultValue={editor_description} className={`editor_descriptions${classext}`} placeholder='Editor Description' cols="60" rows="10"></textarea>
                                                <input className={`editor_order_no${classext}`} type="hidden" value={index} />
                                                
                                                <input className={`editor_images${classext}`} placeholder='Editor Image' type="file"/>
                                           </span>
                                        )
                                    }
                                    else{
                                        return(
                                            <span key={`add${index}`}>
                                                <h2>Associate Editor {index-1}</h2>
                                                <input defaultValue={editor_name} type="text" placeholder='Editor Name' className={`editor_names${classext}`}/>
                                                <textarea defaultValue={editor_description} className={`editor_descriptions${classext}`} placeholder='Editor Description' cols="60" rows="10"></textarea>
                                                <input className={`editor_order_no${classext}`} type="hidden" value={index} />
                                                <input className={`editor_images${classext}`} placeholder='Editor Image' type="file"/>
                                           </span>
                                        )
                                    }
                                   
                                }
                            }
                            )
                        }
                        
                    </div>  
                </section>
                <section>
                    <div id="editor_add">
                        <h2>Add Indexing</h2>
                        <button type='button' onClick={()=>add_indexing()}>
                            <BiAddToQueue size='40px' color='#90e0ef'/>
                        </button>
                        <button type='button' onClick={()=>delete_indexing()}>
                            <AiFillDelete size='40px' color='#ef233c'/>
                        </button>
                    </div>
                    <div id="indexing_cont">
                        {
                            indexing_count.map((obj,index)=>{
                                if(classext==='add'){
                                    return(
                                        <span key={`add_indexing${index}`}>
                                            <h2>Indexing {index+1}</h2>
                                            <input type="text" className={`indexing_label${classext}`} placeholder="Label"/>
                                            <input type="text" className={`indexing_url${classext}`} placeholder="URL" />
                                            <input type="file" className={`indexing_image${classext}`} />
                                        </span>
                                    )
                                }
                                else{
                                    let indexing_label,indexing_url;
                                    try{
                                        indexing_label = indexing_values.indexing_labels[index]
                                    }
                                    catch{
                                        indexing_label = ""
                                    }
                                    try{
                                        indexing_url = indexing_values.indexing_urls[index]
                                    }
                                    catch{
                                        indexing_url = ""
                                    }
                                    return(
                                        <span key={`indexing${indexing_label}${index}`}>
                                            <h2>Indexing {index+1}</h2>
                                            <input defaultValue={indexing_label} type="text" className={`indexing_label${classext}`} placeholder="Label"/>
                                            <input defaultValue={indexing_url} type="text" className={`indexing_url${classext}`} placeholder="URL" />
                                            <input type="file" className={`indexing_image${classext}`} />
                                        </span>
                                    )
                                }
                            })
                        }
                    
                    </div>  
                </section>
                <input className='button' type="submit" value="Submit" />
            </form>
    )
    function add_indexing(){
        let arra = [...indexing_count]
        arra.push('yoo')
        set_indexing_count(arra)
    }
    function delete_indexing(){
        let arr = [...indexing_count]
        if(arr.length>0){
            arr.pop()
            set_indexing_count(arr)
        }
    }
    function add_editor(){
        console.log('im here')
        let arra = [...editors_count]
        arra.push('yoo')
        set_editors_count(arra)
    }
    function delete_editor(){
        let arr = [...editors_count]
        if(arr.length>0){
            arr.pop()
            set_editors_count(arr)
        }
    }
    function submit_journal(cats_obj,e,classext,set_update_journal,id=null){
        window.show_loader();
        e.preventDefault();
        const journal_cover_img = document.getElementsByClassName(`upload_img${classext}`)[0].files[0];
        const journal_title = document.getElementsByClassName(`title${classext}`)[0].value;
        const journal_slug = document.getElementsByClassName(`slug${classext}`)[0].value;
        const frequency = document.getElementsByClassName(`frequency${classext}`)[0].value;
        const journal_abbrevation = document.getElementsByClassName(`abbrevation${classext}`)[0].value;
        const journal_issn = document.getElementsByClassName(`issn${classext}`)[0].value;
        const journal_link = document.getElementsByClassName(`issn_link${classext}`)[0].value;
        const journal_chief_editor = document.getElementsByClassName(`chief${classext}`)[0].value;
        const journal_language = document.getElementsByClassName(`language${classext}`)[0].value;
        const journal_origin = document.getElementsByClassName(`origin${classext}`)[0].value;
        const journal_publisher = document.getElementsByClassName(`publisher${classext}`)[0].value;
        const normal_fee = document.getElementsByClassName(`normal_fee${classext}`)[0].value;
        const fast_track_fee = document.getElementsByClassName(`fast_track_fee${classext}`)[0].value;
        const journal_cat = document.getElementsByClassName(`cat${classext}`)[0].value;

        //getting journal cat id with journal cat
        let journal_cat_id;
        for(let i =0;i<cats_obj.length;i++){
            if(cats_obj[i].category===journal_cat){
                journal_cat_id = cats_obj[i].id;
            }
        }
        const normal_fee_usd = document.getElementsByClassName(`normal_fee_usd${classext}`)[0].value;
        const fast_track_fee_usd = document.getElementsByClassName(`fast_track_fee_usd${classext}`)[0].value;
        const impact_factor = document.getElementsByClassName(`impact_factor${classext}`)[0].value;
        const date = document.getElementsByClassName(`date${classext}`)[0].value;
        
        //additional content editors board and indexing
        //new changes before commit safe point see git changes
        let indexing_labels = document.getElementsByClassName(`indexing_label${classext}`)
        let indexing_urls = document.getElementsByClassName(`indexing_url${classext}`)
        let indexing_images = document.getElementsByClassName(`indexing_image${classext}`)
        let indexing_labels_arr = [],indexing_urls_arr = [],indexing_file_name_arr=[]
        let editor_names = document.getElementsByClassName(`editor_names${classext}`)
        let editor_descriptions = document.getElementsByClassName(`editor_descriptions${classext}`)
        let editor_images = document.getElementsByClassName(`editor_images${classext}`)
        let editor_order_no = document.getElementsByClassName(`editor_order_no${classext}`)
        let editor_names_arr =[],editor_descriptions_arr=[],editor_file_name_arr=[],editor_order_nos=[];
        
        let formdata = new FormData(); 
        formdata.append('title',journal_title);
        formdata.append('slug',journal_slug);
        formdata.append('frequency',frequency);
        formdata.append('abbrevation',journal_abbrevation);
        formdata.append('issn',journal_issn);
        formdata.append('link',journal_link);
        formdata.append('chief_editor',journal_chief_editor);
        formdata.append('language',journal_language);
        formdata.append('origin',journal_origin);
        formdata.append('publisher',journal_publisher);
        formdata.append('normal_fee',normal_fee);
        formdata.append('fast_track_fee',fast_track_fee);
        formdata.append('journal_cat_id',journal_cat_id);
        formdata.append('normal_fee_usd',normal_fee_usd);
        formdata.append('fast_track_fee_usd',fast_track_fee_usd);
        formdata.append('impact_factor',impact_factor);
        formdata.append('date',date);

        if(classext==='add'){
            formdata.append('description',description_for_add);
            formdata.append('manuscript_guideline',manuscript_for_add);
        
            let journo_id;
            axios.post(`${window.server_url}/journals`,formdata)
            .then(response=>{
                journo_id = response.data[0].max_id;

                const formdata2 = new FormData();
                //journal cover img
                if(journal_cover_img!==undefined)
                    formdata2.append('journal_resources',journal_cover_img,`${journo_id}.png`);
                //indexing
                for (let i =0;i<indexing_labels.length;i++){
                    indexing_labels_arr.push(indexing_labels[i].value)
                    indexing_urls_arr.push(indexing_urls[i].value)
                    let input_file = indexing_images[i].files[0]
                    let indexing_file_name = `indexing_image${journo_id}.${i}.png`
                    if(input_file!==undefined){
                        formdata2.append('journal_resources',input_file,indexing_file_name)
                    }
                    indexing_file_name_arr.push(indexing_file_name)
                }
                formdata2.append('indexing_file_names',JSON.stringify(indexing_file_name_arr))
                formdata2.append('indexing_labels',JSON.stringify(indexing_labels_arr))
                formdata2.append('indexing_urls',JSON.stringify(indexing_urls_arr))
            
                //journal editors
                for (let i=0;i<editor_images.length;i++){
                    editor_order_nos.push(editor_order_no[i].value)
                    editor_names_arr.push(editor_names[i].value);
                    editor_descriptions_arr.push(editor_descriptions[i].value)
                    let input_file = editor_images[i].files[0];
                    let editor_file_name = `editor_image${journo_id}.${i}.png`
                    if(input_file!==undefined){
                        formdata2.append('journal_resources',input_file,editor_file_name)
                    }
                    editor_file_name_arr.push(editor_file_name)
                }
                formdata2.append('editor_order_nos',JSON.stringify(editor_order_nos))
                formdata2.append('editor_file_names',JSON.stringify(editor_file_name_arr));
                formdata2.append('editors_names',JSON.stringify(editor_names_arr));
                formdata2.append('editors_descriptions',JSON.stringify(editor_descriptions_arr))
                formdata2.append('id',journo_id);

                axios.post(`${window.server_url}/journal_accessories`,formdata2)
                .then(response=>{
                    set_update_journal(response.data)
                    let success_btn = document.getElementById('success_submitadd');
                    success_btn.style.zIndex=100
                    setTimeout(()=>success_btn.style.zIndex=-100,2000)
                    //clear all values
                    document.getElementsByClassName(`upload_img${classext}`)[0].value="";
                    document.getElementsByClassName(`title${classext}`)[0].value=""
                    document.getElementsByClassName(`slug${classext}`)[0].value=""
                    document.getElementsByClassName(`frequency${classext}`)[0].value=""
                    document.getElementsByClassName(`abbrevation${classext}`)[0].value=""
                    document.getElementsByClassName(`issn${classext}`)[0].value=""
                    document.getElementsByClassName(`issn_link${classext}`)[0].value=""
                    document.getElementsByClassName(`chief${classext}`)[0].value=""
                    document.getElementsByClassName(`language${classext}`)[0].value=""
                    document.getElementsByClassName(`origin${classext}`)[0].value=""
                    document.getElementsByClassName(`publisher${classext}`)[0].value=""
                    document.getElementsByClassName(`normal_fee${classext}`)[0].value=""
                    document.getElementsByClassName(`fast_track_fee${classext}`)[0].value=""
                    document.getElementsByClassName(`cat${classext}`)[0].value=""
                    document.getElementsByClassName(`normal_fee_usd${classext}`)[0].value=""
                    document.getElementsByClassName(`fast_track_fee_usd${classext}`)[0].value=""
                    document.getElementsByClassName(`impact_factor${classext}`)[0].value=""
                    document.getElementsByClassName(`date${classext}`)[0].value=""
                    description_for_add=""
                    manuscript_for_add=""
                    set_indexing_count([]);
                    set_editors_count([]);
                })

            })
            .catch(err=>console.log(err))

            
        
        
        }
        else if(classext==='edit'){
            formdata.append('description',description_for_edit);
            formdata.append('manuscript_guideline',manuscript_for_edit);
            formdata.append('id',id);
            let journo_id=id;
             //journal cover img
            if(journal_cover_img!==undefined)
                formdata.append('journal_resources',journal_cover_img,`${journo_id}.png`);
            //indexing
            for (let i =0;i<indexing_labels.length;i++){
                indexing_labels_arr.push(indexing_labels[i].value)
                indexing_urls_arr.push(indexing_urls[i].value)
                let input_file = indexing_images[i].files[0]
                let indexing_file_name = `indexing_image${journo_id}.${i}.png`
                indexing_file_name_arr.push(indexing_file_name)
                if(input_file!==undefined){
                    formdata.append('journal_resources',input_file,indexing_file_name)
                }
            }
            formdata.append('indexing_file_names',JSON.stringify(indexing_file_name_arr))
            formdata.append('indexing_labels',JSON.stringify(indexing_labels_arr))
            formdata.append('indexing_urls',JSON.stringify(indexing_urls_arr))
 
             //journal editors
            for (let i=0;i<editor_images.length;i++){
                editor_order_nos.push(editor_order_no[i].value)
                editor_names_arr.push(editor_names[i].value);
                editor_descriptions_arr.push(editor_descriptions[i].value)
                let input_file = editor_images[i].files[0];
                let editor_file_name = `editor_image${journo_id}.${i}.png`
                if(input_file!==undefined){
                    formdata.append('journal_resources',input_file,editor_file_name)
                }
                editor_file_name_arr.push(editor_file_name)
            }
            formdata.append('editor_order_nos',JSON.stringify(editor_order_nos))
            formdata.append('editor_file_names',JSON.stringify(editor_file_name_arr));
            formdata.append('editors_names',JSON.stringify(editor_names_arr));
            formdata.append('editors_descriptions',JSON.stringify(editor_descriptions_arr))

            axios.put(window.server_url+'/journals',formdata)
            .then(response=>{
                set_update_journal(response.data);
                let success_btn = document.getElementById('success_submitedit');
                success_btn.style.zIndex=100
                setTimeout(()=>success_btn.style.zIndex=-100,2000)
            })
            .catch(error=>console.log(error));
        }
        
    }
    
}

export default Back_journal;

