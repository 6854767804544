import '../../css/front_page/title.css'
import {useState,useEffect,Fragment} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Title = ()=>{
    //getting main banner title
    const [banner_titles,set_banner_titles] = useState([]);
    useEffect(()=>{
    window.show_loader()

        axios.get(window.server_url+'/titles/header_titles')
        .then(response=>{
            set_banner_titles(response.data);
            window.hide_loader()
        })
        .catch(err=>{
            console.log(err);
          
        })
    },[])

    return(
      <header id = "title">
          <div className="hero-container">
              <div style={{background:'url(/resources/front_page/main_banner.jpg)'}} className="hero hero-homepage-if">
                  <div className="hero__header">
                    {
                        banner_titles.length && banner_titles[0].title_content.split('\n').map((split_content,index)=>(
                            <Fragment key = {`tit ${index}`}>
                                {split_content}
                                <br/>
                            </Fragment>
                        ))
                    }


                  </div>
                  <div className="hero__description">
                    <Link to = '/impact_factors'id= "linkam" href="#" className="hero__description__link show-for-medium-up">
                        <i className="material-icons">arrow_forward</i>
                        {banner_titles.length && banner_titles[1].title_content}
                    </Link>
                  </div>
              </div>
          </div>
      </header>
    )
}

export default Title;
