import {Journal_list} from '../front_page/Main'
import Select from 'react-select';
import Header from '../front_page/header_front'
import axios from 'axios';
import {useState,useEffect} from 'react';
import '../../css/thesis/thesis.css';
import thesis_bro from '../../img/Thesis.png';
import Footer from '../front_page/footer';
import { Link } from 'react-router-dom';

const Thesis = ()=>{
    let options=[
        {
            label:'All',value:'All'
        }
    ];
    let repeated_index = []

    const [indexing_obj,set_indexing_obj] = useState([])
    const[cat_array,set_cat_array] = useState([]);
    const [journal_obj,set_journal_obj] = useState([]);
    const [filter_cat,set_filter_cat] = useState('All');
    useEffect(()=>{
        window.show_loader()
        axios.all([
            axios.get(window.server_url+'/cats'),
            axios.get(window.server_url+'/journals/journal_intro'),
            axios.get(`${window.server_url}/indexing`)

        ])
        .then(axios.spread((...responses)=>{
            set_cat_array(responses[0].data);
            set_journal_obj(responses[1].data);
            set_indexing_obj(responses[2].data);
            window.hide_loader()

        }))
        .catch(error=>console.log(error))
    },[])
    cat_array.map(obj=>{
        let option_obj={};
        option_obj.label=obj.category;
        option_obj.value=obj.category;
        options.push(option_obj);
    })

    return(
        <div>
            <Header/>
            
            <div id="main_tile" className="is-ancestor is-parent tile">
            <div className='tile is-3 is-parent is-vertical'>
                    <div id='main_first_tile_child' className='notification is-white is-child box'>
                        <h1>Open Access Journals</h1>
                        <Select id='main_Select' options={options} onChange={e=>{
                            if(e.value==='All')
                                set_filter_cat(e.value)
                            else{
                                for(let i =0;i<cat_array.length;i++){
                                    if(e.value===cat_array[i].category){
                                        set_filter_cat(cat_array[i].id)
                                    }
                                }
                            }
                        } 
                        }/>
                        {
                        // displaying journal names with icons in a loop gotten from the data
                            journal_obj.map(obj=>{
                                if(filter_cat==='All')
                                    return <Journal_list key={obj.id} journal_icon={obj.id} journal_abbrevation={obj.journal_abbrevation} journal_slug={obj.journal_slug}/>      
                                else if(filter_cat===obj.journal_cat_id)
                                    return <Journal_list key={obj.id} journal_icon={obj.id} journal_abbrevation={obj.journal_abbrevation} journal_slug={obj.journal_slug}/>      
                        })
                            
                    }
                </div>
                <div id="indexing_container" className="is-child notification is-white box">
                    {
                        indexing_obj.length>0&&indexing_obj.map((index_obj,index)=>{
                            let unique_url = true
                            if(index!==0){
                                for(let i = 0;i<repeated_index.length;i++){
                                    if(repeated_index[i]===index_obj.url){
                                        unique_url = false
                                        break
                                    }
                                }
                            }
                            if(unique_url){
                                repeated_index.push(index_obj.url)
                                return(
                                    <a key={`${index} indexing`} href={index_obj.url}>
                                        <img src={`/resources/journal_indexing/${index_obj.file_name}`} alt="" />
                                    </a>
                                )
                            }
                        })
                    }
                </div>
            </div>
                <div className='tile is-parent'>
                    <div id='thesis_container' className="is-child notification is-white">
                        <h1>Thesis/Dissertation Invited for Publication!!!</h1>
                        <p className='text_justify'>
                            Addaiyan Journal of Arts, Humanities and Social Sciences (AJAHSS) offers services to provide authors a wide global platform to showcase their valuable research works. A master’s/doctoral thesis may be published as open access literature disseminate their research experiences/findings by the scholar community worldwide belonging to the same or interdisciplinary field(s) that contribute a lot in the further pursuit of the research.
                            Please note that the author retains full ownership/copyright of the work.
                        </p>
                        <p className='text_justify'>
                            If you wish to publish your thesis/dissertation online as open access, please contact our service at email: ajahssthesis@gmail.com . For any query please feel free to contact at WhatsApp No. +918136044300.
                            AJAHSS publication is open access, which means that your thesis is available to anyone in the world to download / read for free directly from the website. Your thesis will be accessible from Google Scholar and Google via Title/ Author name etc.
                        </p>
                        <h4>How to submit your thesis?</h4>
                        <p className='text_justify'>
                            Please send your original work (If you are taking any ref. or topics from other sources please don't forget to mention source / ref. / author etc). Our thesis publication is online without page or size limitation. So, your thesis will not be rejected due to lack of space. If your thesis has already been published elsewhere, then you should require sending Permission/ Consent or Request letter to us for re-printing / publication. A submission that does not comply with our requirements will be rejected. Submissions must be supported by an ethical statement/declaration on behalf of author(s) as per format supplied by AJAHSS authority in due time after correspondence. Since the thesis/ dissertations are reviewed by recognized experts authorized by the university concerned, AJAHSS does not review it again. However, minor editing may be done for easy readability.
                        </p>
                        <p>
                            <strong>Submit your manuscript online </strong>by filling the online thesis <Link to='/submit' target='_blank'>submission form</Link> or via email: ajahssthesis@gmail.com
                        </p>
                        <h4>Processing Fee</h4>
                        <p className='text_justify'>
                            We do not take any subscription/publication charge. Authors/readers can view the work free of cost. However, to maintain service expenses such as staff salary, upload/ format and other services related to website a very nominal fee as ‘Processing Fee’ is charged from the author desirous to publish. Depending on the size/pages etc. the fee is fixed negotiating with the concerned author.
                        </p>
                        <div id="thesis_bro_container">
                            <div className='columns level'>
                                <img className='column level-item' src={thesis_bro} alt="" />
                                <h5 className='column level-item'><strong>Submitted by</strong><br/>Mirwais Ahmadzai</h5>
                            </div>
                            
                            <div id="thesis_bro_child2">
                                <a>EFFECT OF TRANSFORMATIONAL AND TRANSACTIONAL LEADERSHIP ON JOB SATISFACTION : A CASE OF AFGHANISTHAN INVESTEMENT SUPPORT AGENCY</a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Thesis;