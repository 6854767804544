import '../../../css/back_office/slider.css'
import axios from 'axios';
import {useState,useEffect} from 'react';


const Slider = ()=>{

    const [all_titles,set_all_titles] = useState([]);
    useEffect(()=>{
    window.show_loader();

        axios.get(`${window.server_url}/titles/all`)
        .then(response=>{
            set_all_titles(response.data);
            window.hide_loader();
        })
        .catch(err=>console.log(err))
    },[])
    if(all_titles.length){
        return(
            <div id='slider_container'>
            <div id="success_submit" className="button is-success is-large">Successfully Uploaded!!</div>
            <header className='is-size-2-tablet is-size-3-mobile'>Slider &amp; Titles</header>
                <form onSubmit={e=>submit_form(e)}>
                    <label htmlFor="slider_main_title">Main Title</label>
                    <textarea defaultValue={all_titles[0].title_content} id='slider_main_title' className='input' type="text"/>
                    <label htmlFor="slider_sub_title">Sub Title</label>
                    <input defaultValue={all_titles[1].title_content}id='slider_sub_title' className='input' type="text"/>
                    <label htmlFor="slider_main_banner">Main banner (1260px * 240px or 21 : 4 ratio)</label>
                    <input id='slider_main_banner' type="file"/>
                    <label htmlFor="slider_1_text">slider 1 text</label>
                    <input defaultValue={all_titles[2].title_content}id='slider_1_text' className='input' type="text"/>
                    <label htmlFor="slider_1_image">slider 1 Image (668px * 280px or 167 : 70 ratio)</label>
                    <input id='slider_1_image' type="file"/>
                    <label htmlFor="slider_2_text">slider 2 text</label>
                    <input defaultValue={all_titles[3].title_content}id='slider_2_text' className='input' type="text"/>
                    <label htmlFor="slider_2_image">slider 2 image (668px * 280px or 167 : 70 ratio)</label>
                    <input id='slider_2_image' type="file"/>
                    <label htmlFor="slider_3_text">slider 3 text</label>
                    <input defaultValue={all_titles[4].title_content}id='slider_3_text' className='input' type="text"/>
                    <label htmlFor="slider_3_image">slider 3 image (668px * 280px or 167 : 70 ratio)</label>
                    <input id='slider_3_image' type="file"/>
                    <label htmlFor="slider_4_text">slider 4 text</label>
                    <input defaultValue={all_titles[5].title_content}id='slider_4_text' className='input' type="text"/>
                    <label htmlFor="slider_4_image">slider 4 image (668px * 280px or 167 : 70 ratio)</label>
                    <input id='slider_4_image' type="file"/>
                    <label htmlFor="slider_5_text">slider 5 text</label>
                    <input defaultValue={all_titles[6].title_content}id='slider_5_text' className='input' type="text"/>
                    <label htmlFor="slider_5_image">slider 5 image (668px * 280px or 167 : 70 ratio)</label>
                    <input id='slider_5_image' type="file"/>
                    <label htmlFor="slider_footer_img">Footer Image (1260px * 400px or 63 : 20 ratio)</label>
                    <input type="file" name="" id="slider_footer_img" />
                    <input type="submit" value="Submit" />
                    
                </form>
            </div>
        )
    }
    else
        return null;
}

function submit_form(e){
    e.preventDefault();
    const slider_main_title = document.getElementById('slider_main_title').value;
    const slider_sub_title = document.getElementById('slider_sub_title').value;
    const slider_1_text = document.getElementById('slider_1_text').value;
    const slider_2_text = document.getElementById('slider_2_text').value;
    const slider_3_text = document.getElementById('slider_3_text').value;
    const slider_4_text = document.getElementById('slider_4_text').value;
    const slider_5_text = document.getElementById('slider_5_text').value;
    const slider_main_banner = document.getElementById('slider_main_banner');
    const slider_1_image = document.getElementById('slider_1_image');
    const slider_2_image = document.getElementById('slider_2_image');
    const slider_3_image = document.getElementById('slider_3_image');
    const slider_4_image = document.getElementById('slider_4_image');
    const slider_5_image = document.getElementById('slider_5_image');
    const slider_footer_img = document.getElementById('slider_footer_img');
    let pass_check =true;
    const slide_arra = [slider_main_banner,slider_1_image,slider_2_image,slider_3_image,slider_4_image,slider_5_image,slider_footer_img];
    let formdata = new FormData();

    wait();
    
    function load_file(image){
        return new Promise((resolve)=>{
            let reader = new FileReader();
            reader.readAsDataURL(image.files[0]);
            reader.onload = (event)=>{
                let img = new Image();
                img.src = event.target.result;
                img.onload = ()=>{
                    if(image.id==='slider_main_banner'&&img.width===img.height*5.25){
                        image.style.boxShadow='none';
                    }
                    else if(image.id==='slider_footer_img' && img.width===img.height*3.15){
                        image.style.boxShadow='none';
                    }
                    else if(image.id!=='slider_main_banner'&&img.width===img.height*2.3857142857142855){
                        image.style.boxShadow='none';
                    }
                    else{
                        pass_check=false;
                        image.style.boxShadow='red 0 0 10px 3px';
                        window.scroll(0,200);
                    }
                    resolve();
                }
            }
        })
    }
    async function wait(){
        for (let i of slide_arra){
            if(i.files[0]){
                if(i.files[0].type.split('/',1)[0]==='image'){
                    await load_file(i);
                }
                else{
                    pass_check=false;
                    i.style.boxShadow = 'red 0 0 10px 3px';
                }

                formdata.append('slider_images',i.files[0],i.id);   
                
            }
        }
        if(pass_check){
            window.show_loader();
            formdata.append('slider_main_title',slider_main_title);
            formdata.append('slider_sub_title',slider_sub_title);
            formdata.append('slider_1_text',slider_1_text);
            formdata.append('slider_2_text',slider_2_text);
            formdata.append('slider_3_text',slider_3_text);
            formdata.append('slider_4_text',slider_4_text);
            formdata.append('slider_5_text',slider_5_text);
    
            axios.put(`${window.server_url}/titles`,formdata)
            .then(response=>{
                window.hide_loader()
                document.getElementById('success_submit').style.zIndex=100
                setTimeout(()=>document.getElementById('success_submit').style.zIndex=-100,3200)
            })
            .catch(err=>console.log(err));
    
        }
    }
   
    
}


export default Slider;