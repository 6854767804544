import Header from '../front_page/header_front'
import {Journal_list} from '../front_page/Main'
import axios from 'axios';
import { useState,useEffect } from 'react';
import Select from 'react-select';
import '../../css/information/peer_review_policy.css'
import peer_review_tree from '../../img/peer_review_tree.png'
import Footer from '../front_page/footer'


const Peer_review_policy = ()=>{
    
    let options=[
        {
            label:'All',value:'All'
        }
    ];
    let repeated_index = []

    const [indexing_obj,set_indexing_obj] = useState([])
    const[cat_array,set_cat_array] = useState([]);
    const [journal_obj,set_journal_obj] = useState([]);
    const [filter_cat,set_filter_cat] = useState('All');
    useEffect(()=>{
        window.show_loader()
        axios.all([
            axios.get(window.server_url+'/cats'),
            axios.get(window.server_url+'/journals/journal_intro'),
            axios.get(`${window.server_url}/indexing`)

        ])
        .then(axios.spread((...responses)=>{
            set_cat_array(responses[0].data);
            set_journal_obj(responses[1].data);
            set_indexing_obj(responses[2].data);
            window.hide_loader()

        }))
        .catch(error=>console.log(error))
    },[])
    cat_array.map(obj=>{
        let option_obj={};
        option_obj.label=obj.category;
        option_obj.value=obj.category;
        options.push(option_obj);
    })
    return(
        <div>
            <Header/>
            <div id="main_tile" className="is-ancestor is-parent tile">
            <div className='tile is-3 is-parent is-vertical'>
                    <div id='main_first_tile_child' className='notification is-white is-child box'>
                        <h1>Open Access Journals</h1>
                        <Select id='main_Select' options={options} onChange={e=>{
                            if(e.value==='All')
                                set_filter_cat(e.value)
                            else{
                                for(let i =0;i<cat_array.length;i++){
                                    if(e.value===cat_array[i].category){
                                        set_filter_cat(cat_array[i].id)
                                    }
                                }
                            }
                        } 
                        }/>
                        {
                        // displaying journal names with icons in a loop gotten from the data
                            journal_obj.map(obj=>{
                                if(filter_cat==='All')
                                    return <Journal_list key={obj.id} journal_icon={obj.id} journal_abbrevation={obj.journal_abbrevation} journal_slug={obj.journal_slug}/>      
                                else if(filter_cat===obj.journal_cat_id)
                                    return <Journal_list key={obj.id} journal_icon={obj.id} journal_abbrevation={obj.journal_abbrevation} journal_slug={obj.journal_slug}/>      
                        })
                            
                    }
                </div>
                <div id="indexing_container" className="is-child notification is-white box">
                    {
                        indexing_obj.length>0&&indexing_obj.map((index_obj,index)=>{
                            let unique_url = true
                            if(index!==0){
                                for(let i = 0;i<repeated_index.length;i++){
                                    if(repeated_index[i]===index_obj.url){
                                        unique_url = false
                                        break
                                    }
                                }
                            }
                            if(unique_url){
                                repeated_index.push(index_obj.url)
                                return(
                                    <a key={`${index} indexing`} href={index_obj.url}>
                                        <img src={`/resources/journal_indexing/${index_obj.file_name}`} alt="" />
                                    </a>
                                )
                            }
                        })
                    }
                </div>
            </div>
                <div className='tile is-parent'>
                    <div id='peer_review_container' className="is-child notification is-white">
                        <h1>PEER- REVIEW POLICY</h1>
                        <p className='text_justify'>
                        Submitted manuscripts are approved by the editorial team and verified by the Managing Editor to determine whether the paper is acceptable or not. If found that the text does not comply with the journal's ethical policy, it will be rejected before peer-review and returned to the author for revision and resubmission. After these checks, the managing editor will consult the Journal Editor-in-chief or other Editor(s), who will assign them to reviewers. The Journals review process is double-blinded. Generally, the paper will be sent to two reviewers who are experts in the respective field to review the paper as per journal’s guidelines and features of a quality research paper. For papers that require modification, the same reviewers will be used to ensure that the quality of the revised paper is acceptable. The review process may take longer duration depending on the standard of the manuscript.
                        </p>
                        <h3>Normally there are three types of peer-review processing for journal publication,</h3>
                        <ul>
                            <li>
                                <strong>Single-blind:</strong>
                                Names of reviewers are not disclosed to authors.
                            </li>
                            <li>
                                <strong>Double-blind:</strong>
                                Names of reviewers and authors are not disclosed to each other
                            </li>
                            <li>
                                <strong>Open peer review:</strong>
                                Names of reviewers and authors are disclosed to each other

                            </li>
                        </ul>
                        <img id='peer_review_tree' src={peer_review_tree} alt="peer review tree" />
                        

                                          
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Peer_review_policy;