import Header from "./front_page/header_front";
import Footer from "./front_page/footer";
import { useEffect,useState } from "react";
import axios from 'axios';
import parse from 'html-react-parser';


const Impact_factors = ()=>{
    const [impact_factor_page,set_impact_factor_page] = useState('');
    useEffect(()=>{
    window.show_loader()

        axios.get(`${window.server_url}/impact_factor_page`)
        .then(response=>{
            set_impact_factor_page(response.data[0].content);
            window.hide_loader();
        })
        .catch(error=>console.log(error));
    },[])
    return(
        <div style={{backgroundColor:'white'}}>
            <Header/>
            {parse(impact_factor_page)}
            <Footer/>
        </div>
    )
}

export default Impact_factors;