import '../../../css/back_office/pending_manuscript.css'
import {GrOverview} from 'react-icons/gr'
import {TiTickOutline} from 'react-icons/ti'
import {AiOutlineClose,AiFillDelete} from 'react-icons/ai'
import axios from 'axios'
import { useEffect,useState } from 'react'
import { Link } from 'react-router-dom'


const Pending_manu = ()=>{
    // console.log(document.referrer)
    let session = sessionStorage.getItem('sub_page');
    const [pending_manuscripts,set_pending_manuscripts] = useState([])
    const [manuscript_show,set_manuscript_show] = useState(false);
    const [update,set_update] = useState();
    const [manuscript_obj,set_manuscript_obj] = useState([])
    
    useEffect(()=>{
        axios.get(window.server_url+'/manuscripts_intro/pending')
        .then(response=>set_pending_manuscripts(response.data))
        .catch(error=>console.log(error))
    },[update])

    
    useEffect(()=>{
        if(manuscript_show){
            axios.get(`${window.server_url}/manuscript/${manuscript_show}`)
            .then(response=>set_manuscript_obj(response.data))
            .catch(error=>console.log(error))
        }
        
    },[manuscript_show])
    if(session!=='admin_menu'){
        return(
            <h1 style={{fontSize:'100px'}}>error 403! Forbidden</h1>

        )
    }
    
    return(
        <section id="pending_manu_cont">
            <h1>Pending Manuscripts</h1>
            <Manuscript_list
                manuscript_intro_obj = {pending_manuscripts}
                update = {set_update}
                set_manuscript_show = {set_manuscript_show}
            />
            {manuscript_obj.length>0 && manuscript_show && <Manuscript_content manuscript_obj={manuscript_obj}/>}
        </section>
    )
}
const Manuscript_content = ({manuscript_obj})=>{
    return(
        <div id="manuscript_content">
            <h2>Manuscript Details</h2>
            <table className='back_journal_table'>
                <thead>
                    <tr>
                       <th>Heading</th>
                       <th>Detail</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Manuscript Title</td>
                        <td>{manuscript_obj[0].manuscript_title}</td>
                    </tr>
                    <tr>
                        <td>Processing Option</td>
                        <td>{manuscript_obj[0].processing_option}</td>
                    </tr>
                    <tr>
                        <td>Manuscript Type</td>
                        <td>{manuscript_obj[0].manuscript_type}</td>
                    </tr>
                    <tr>
                        <td>Stream Name</td>
                        <td>{manuscript_obj[0].stream_name }</td>
                    </tr>
                    <tr>
                        <td>Area of Research</td>
                        <td>{manuscript_obj[0].area_of_research }</td>
                    </tr>
                    <tr>
                        <td>First name</td>
                        <td>{manuscript_obj[0].first_name }</td>
                    </tr>
                    <tr>
                        <td>Last name</td>
                        <td>{manuscript_obj[0].last_name }</td>
                    </tr>
                    <tr>
                        <td>Address Line 1</td>
                        <td>{manuscript_obj[0].address1 }</td>
                    </tr>
                    <tr>
                        <td>Address Line 2</td>
                        <td>{manuscript_obj[0].address2 }</td>
                    </tr>
                    <tr>
                        <td>City</td>
                        <td>{manuscript_obj[0].city }</td>
                    </tr>
                    <tr>
                        <td>State/Province/Region</td>
                        <td>{manuscript_obj[0].state }</td>
                    </tr>
                    <tr>
                        <td>Country</td>
                        <td>{manuscript_obj[0].country }</td>
                    </tr>
                    <tr>
                        <td>Zip/postal code</td>
                        <td>{manuscript_obj[0].postal_code }</td>
                    </tr>
                    <tr>
                        <td>Email Address</td>
                        <td>{manuscript_obj[0].email }</td>
                    </tr>
                    <tr>
                        <td>Phone1</td>
                        <td>{manuscript_obj[0].phone1 }</td>
                    </tr>
                    <tr>
                        <td>Phone2</td>
                        <td>{manuscript_obj[0].phone2 }</td>
                    </tr>
                    <tr>
                        <td>Co author name 1</td>
                        <td>{manuscript_obj[0].co_author1_name }</td>
                    </tr>
                    <tr>
                        <td>Co author Organization 1</td>
                        <td>{manuscript_obj[0].co_author1_organization }</td>
                    </tr>
                    <tr>
                        <td>Co author email 1</td>
                        <td>{manuscript_obj[0].co_author1_email }</td>
                    </tr>
                    <tr>
                        <td>Co author name 2</td>
                        <td>{manuscript_obj[0].co_author2_name }</td>
                    </tr>
                    <tr>
                        <td>Co author Organization 2</td>
                        <td>{manuscript_obj[0].co_author2_organization }</td>
                    </tr>
                    <tr>
                        <td>Co author email 2</td>
                        <td>{manuscript_obj[0].co_author2_email }</td>
                    </tr>
                    <tr>
                        <td>Co author name 3</td>
                        <td>{manuscript_obj[0].co_author3_name }</td>
                    </tr>
                    <tr>
                        <td>Co author Organization 3</td>
                        <td>{manuscript_obj[0].co_author3_organization }</td>
                    </tr>
                    <tr>
                        <td>Co author email 3</td>
                        <td>{manuscript_obj[0].co_author3_email }</td>
                    </tr>
                    <tr>
                        <td>Co author name 4</td>
                        <td>{manuscript_obj[0].co_author4_name }</td>
                    </tr>
                    <tr>
                        <td>Co author organization 4</td>
                        <td>{manuscript_obj[0].co_author4_organization }</td>
                    </tr>
                    <tr>
                        <td>Co author email 4</td>
                        <td>{manuscript_obj[0].co_author4_email }</td>
                    </tr>
                </tbody>
            </table>
            <Link to={`/resources/manuscript_docs/${manuscript_obj[0].file_name}`} target="_blank" className='button is-rounded is-primary' id='manuscript_download_btn'>Download PDF</Link>
            <h2>Authors Message</h2>
            <textarea value={manuscript_obj[0].message} cols="70" rows="10"></textarea>

        </div>
    )
}

const Manuscript_list = ({manuscript_intro_obj,update,set_manuscript_show})=>{
    return(
        <table className="back_journal_table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Date</th>
                    <th>Manuscript Title</th>
                    <th>Options</th>
                </tr>
            </thead>
            <tbody>
                {
                    manuscript_intro_obj.map(obj=>(
                        <tr key={`${obj.id}`}>
                            <td>{obj.id}</td>
                            <td>{obj.date}</td>
                            <td>{obj.manuscript_title}</td>
                            <td>
                                <button onClick={()=>set_manuscript_show(obj.id)} className='button is-info'><GrOverview size={'20px'}/></button>
                                <button onClick={()=>approved(obj.id)} className="button is-success"><TiTickOutline size={'30px'}/></button>
                                <button onClick={()=>declined(obj.id)} className="button is-warning"><AiOutlineClose/></button>
                                <button onClick={()=>delete_manu(obj.id)} className="button is-danger"><AiFillDelete/></button>
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
    function delete_manu(id,set_update=update){
        let is_delete = window.confirm('do you really wana delete?')
        if(is_delete){
            axios.delete(`${window.server_url}/manuscripts/${id}`)
            .then(response=>set_update(response.data))
            .catch(eror=>console.log(eror))
        }
   
        
    }
    function declined(id,set_update=update){
         axios.put(`${window.server_url}/manuscripts/declined/${id}`)
        .then(response=>set_update(response.data))
        .catch(eror=>console.log(eror))
    }
    function approved(id,set_update=update){
        axios.put(`${window.server_url}/manuscripts/approved/${id}`)
        .then(response=>set_update(response.data))
        .catch(eror=>console.log(eror))

    }
}

export {Pending_manu,Manuscript_content,Manuscript_list}




