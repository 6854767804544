
import '../../css/about_page/about.css';
import {Journal_list} from '../front_page/Main'
import Select from 'react-select';
import { Collapser, Trigger, Panel } from 'react-collapser';
import Header from '../front_page/header_front'
import axios from 'axios';
import {useState,useEffect} from 'react';
import Footer from '../front_page/footer';

const About = ()=>{
    let options=[
        {
            label:'All',value:'All'
        }
    ];
    let repeated_index = []

    const [indexing_obj,set_indexing_obj] = useState([])
    const[cat_array,set_cat_array] = useState([]);
    const [journal_obj,set_journal_obj] = useState([]);
    const [filter_cat,set_filter_cat] = useState('All');
    useEffect(()=>{
        window.show_loader()
        axios.all([
            axios.get(window.server_url+'/cats'),
            axios.get(window.server_url+'/journals/journal_intro'),
            axios.get(`${window.server_url}/indexing`)

        ])
        .then(axios.spread((...responses)=>{
            set_cat_array(responses[0].data);
            set_journal_obj(responses[1].data);
            set_indexing_obj(responses[2].data);
            window.hide_loader()

        }))
        .catch(error=>console.log(error))
    },[])
    cat_array.map(obj=>{
        let option_obj={};
        option_obj.label=obj.category;
        option_obj.value=obj.category;
        options.push(option_obj);
    })
    return(
        <div>
            <Header/>
            
            <div id="main_tile" className="is-ancestor is-parent tile">
            <div className='tile is-3 is-parent is-vertical'>
                    <div id='main_first_tile_child' className='notification is-white is-child box'>
                        <h1>Open Access Journals</h1>
                        <Select id='main_Select' options={options} onChange={e=>{
                            if(e.value==='All')
                                set_filter_cat(e.value)
                            else{
                                for(let i =0;i<cat_array.length;i++){
                                    if(e.value===cat_array[i].category){
                                        set_filter_cat(cat_array[i].id)
                                    }
                                }
                            }
                        } 
                        }/>
                        {
                        // displaying journal names with icons in a loop gotten from the data
                            journal_obj.map(obj=>{
                                if(filter_cat==='All')
                                    return <Journal_list key={obj.id} journal_icon={obj.id} journal_abbrevation={obj.journal_abbrevation} journal_slug={obj.journal_slug}/>      
                                else if(filter_cat===obj.journal_cat_id)
                                    return <Journal_list key={obj.id} journal_icon={obj.id} journal_abbrevation={obj.journal_abbrevation} journal_slug={obj.journal_slug}/>      
                        })
                            
                    }
                </div>
                <div id="indexing_container" className="is-child notification is-white box">
                    {
                        indexing_obj.length>0&&indexing_obj.map((index_obj,index)=>{
                            let unique_url = true
                            if(index!==0){
                                for(let i = 0;i<repeated_index.length;i++){
                                    if(repeated_index[i]===index_obj.url){
                                        unique_url = false
                                        break
                                    }
                                }
                            }
                            if(unique_url){
                                repeated_index.push(index_obj.url)
                                return(
                                    <a key={`${index} indexing`} href={index_obj.url}>
                                        <img src={`/resources/journal_indexing/${index_obj.file_name}`} alt="" />
                                    </a>
                                )
                            }
                        })
                    }
                </div>
            </div>
                <div className='tile is-parent'>
                    <div id='about_ai' className="is-child notification is-white">
                        <h1>About Publishers</h1>
                        <p className='text_justify'>
                        <b>Addaiyan International Publishers</b> established in 2016 with the sole aim of providing a reliable platform for "Open Access" to the researcher’s information pertaining to different subjects and disciplines of modern scientific research all over the world and today we are one of the leading Publishers in the market. Addaiyan International Publishers is a privately owned publishing company and subsidiary unit of <b>Jardyan (OPC) Pvt. Ltd.</b> We maintain the highest standards of quality in all of the journals and books we publish. We collaborate closely with authors and editors to produce the best work possible in the fields we cover.
<b>Addaiyan International publishes</b> in-depth, well-informed and high-quality commentary and analysis on global higher education trends and issues. New books and other publications relevant to the global higher education community are also listed in each edition. 

Addaiyan International Publishers covering medical, dental, veterinary, nursing, allied sciences, Engineering, Arts, Humanities and Social Sciences written by Indian and international authors. Addaiyan International Publishers publishes E-Book, and Journals, on these subjects: Science, Medical, Nursing, Engineering and Arts, Humanities and Social Sciences. 
<br/>
<b>PUBLISHER DUTIES</b> 
<ul id='bullet_list'>
<li>
    In cases of any alleged or verified scientific delinquency, fake publication and plagiarism, the publisher will take all necessary steps to meet the situation. 
   </li>
   <li>
   The publisher and editors must take all reasonable steps to identify and prevent the publication of papers containing research misconduct, and they must never encourage or knowingly allow such misconduct to occur.

   </li>
</ul>

                        </p>
                        <h1>Frequently Asked Questions (FAQ's)</h1>
                  
                        <Collapser>
                            <Trigger id='footer_mobile_trigger'>Q. Why should I choose AI Publishers?</Trigger>
                            <Panel>
                                <p>Ans. AI Publishers is a growing, dedicated and knowledge-oriented organization in the service of scholar communities. It deals in the publication of online journals and books as well as holding academic events such as seminar, workshop, conference, etc. So, we think you are in the right place.</p>                                
                            </Panel>
                            <Trigger id='footer_mobile_trigger'>Q. What is the procedure to select the right journal for my article/manuscript?</Trigger>
                            <Panel>
                                <p>Ans. Follow this <a>link»</a> and choose the journal of AI Publisher as per the subject of your article. Manuscript of books of all disciplines may be submitted to myaipublisher@gmail.com</p>
                                
                            </Panel>
                            <Trigger id='footer_mobile_trigger'>Q. Can I make any changes to my article before or after publishing?</Trigger>
                            <Panel>
                                <p>Ans. The articles are generally sent to the author for final verification to the author before publication. After publication, any change is a difficult job. However, if so required, you may contact us and then it will be tried to solve through discussion.</p>
                                
                            </Panel>
                            <Trigger id='footer_mobile_trigger'>Q. When will my article be published online?</Trigger>
                            <Panel>
                                <p>Ans. Within 24 hours of sending back the article by the author after final verification.</p>
                                
                            </Panel>
                            <Trigger id='footer_mobile_trigger'>Q. When will I recieve online access to my article?</Trigger>
                            <Panel>
                                <p>Ans. As soon as the article is added in the current issue the author is enabled for online access.</p>
                                
                            </Panel>
                            <Trigger id='footer_mobile_trigger'>Q. When will my article be included in an issue and what will the page numbers be?</Trigger>
                            <Panel>
                                
                                <p>Ans. If your article has been reviewed and ready to be published, then it will be added to our current issue. We are able to provide volume and issue number before publishing but providing page numbers is a difficult job. However, in case of preference opted for page numbers, the author may contact us which may be settled through discussion.</p>
                            </Panel>
                            
                            
                        </Collapser>
                        
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
export default About;