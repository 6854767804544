import Article_back from '../admin_page/article_back';
import Back_journal from '../admin_page/back_journal';
import admin_panel_img from '../../../img/admin_panel.png';
import {RiArticleFill} from 'react-icons/ri';
import {IoIosJournal} from 'react-icons/io';
import {useState} from 'react';



const User_page = ()=>{
    const [sub_page,set_sub_page] = useState(<Back_journal/>);

    return(
        <div>
            <section id='back_page_navbar_container'>

            <nav id='front_page_navbar' className="navbar is-fixed-top">
                <div className="navbar-brand">
                    <img style={{maxHeight:'2.5rem',margin:'5px 0 0 20px'}} src={admin_panel_img} alt="" />
                    <h1 id='back_navbar_h1'>USER PANEL</h1>
                    <a onClick={navburger_toggle} id='navbar-burger2' className="navbar-burger">
                        <span aria-hidden='true'></span>
                        <span aria-hidden='true'></span>
                        <span aria-hidden='true'></span>
                    </a>
                </div>
                <div id='navbar-menu' className="navbar-menu">
                    <div id='back_navbar_start' className="navbar-start">
            
                        <div onClick={()=>set_sub_page(<Article_back/>)} className="navbar-item">
                            <span className="header_front_icon_cont">
                                <RiArticleFill color={'#e9c46a'}/>
                            </span>
                            Article
                        </div>
                        
                        <span onClick={()=>set_sub_page(<Back_journal/>)} className="navbar-item">
                            <span className="header_front_icon_cont">
                                <IoIosJournal color={'#d00000'}/>
                            </span>
                            Journals
                        </span>
                    </div>
                </div>
            </nav>
            </section>
            {
                sub_page
            }
          
        </div> 
    )
}

export default User_page;

function navburger_toggle(){
    let navbar_burger = document.getElementById('navbar-burger2');
    let navbar_menu = document.getElementById('navbar-menu');
    if(navbar_burger.className==='navbar-burger'){
        navbar_burger.className = 'is-active navbar-burger';
        navbar_menu.className = 'is-active navbar-menu';
    }
    else{
        navbar_burger.className='navbar-burger';
        navbar_menu.className='navbar-menu';
    }
}