import '../../../css/books_list.css'
import JoditEditor from "jodit-react";
import {AiFillDelete,AiFillEdit} from 'react-icons/ai';
import { useState,useEffect } from 'react';
import axios from 'axios';

const config = {
    uploader:{
        "insertImageAsBase64URI": true
    },
    toolbarAdaptive:false,
    toolbarSticky:false
    // toolbarStickyOffset:200
}
const Books_list = ()=>{
    let book_description_add="",book_addition_info_add="";
    let book_description_edit="",book_addition_info_edit=""
    const [books_obj,set_books_obj] = useState([])
    const [re_render,set_re_render] = useState()
    const [editBookId,setEditBookId] = useState()
    const [is_visible_edit_books,set_is_visible_edit_books] = useState('modal')
    useEffect(()=>{
        window.show_loader()
        axios.get(window.server_url+'/books')
        .then(response=>{
            set_books_obj(response.data)
            window.hide_loader()
        })
    },[re_render])

    function deleteBooks(id){
        if(window.confirm('Are you sure you want to delete this book?')){
            window.show_loader()
            axios.delete(window.server_url+'/books/'+id)
            .then(response=>{
                set_re_render(response.data)
                let success_btn = document.getElementById('success_submit');
                success_btn.style.zIndex=100
                setTimeout(()=>success_btn.style.zIndex=-100,3200)
            })
            .catch(err=>console.log(err))
        }
        
    }
    const AddBooks = ()=>{
        return(
            <div id='add_books_container' class="modal">
                <div class="modal-background"></div>
                <div id='modal_card' class="modal-card">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Add Journal Page !</p>
                        <button onClick={()=>{
                            document.getElementById('books_title').value="";
                            document.getElementById('author_name').value="";
                            document.getElementById('price').value="";
                            document.getElementById('summary').value="";

                            set_re_render(Math.floor(Math.random()*100))
                            document.getElementById('add_books_container').classList.remove('is-active')
                        }} class="delete" aria-label="close"></button>
                    </header>
                    <section class="modal-card-body">
                        {/* <!-- Content ... --> */}
                        <form onSubmit={(e)=>add_books(e)}>
                            <label  className='label' htmlFor="">Book Title</label>
                            <input required={true} id='books_title' type="text" className='input mb-1' />
                            <label  className='label' htmlFor="">Author Name</label>
                            <input required={true} id='author_name' type="text" name=""  className="input mb-1" />
                            <label  className='label' htmlFor="">Price</label>
                            <input required={true} id='price' type="number" className="input mb-1"  />
                            <label  className='label' htmlFor="">Summary</label>
                            <textarea required={true} name="" id="summary" cols="40" rows="5" className="textarea mb-1"></textarea>
                            <label className='label mb-1'  htmlFor="">Description</label>
                            <JoditEditor
                                config={config}
                                onChange={(e)=>book_description_add=e}
                            />
                            <label className='label my-3' htmlFor="">Additional Information</label>
                            <JoditEditor
                                config={config}
                                onChange={(e)=>book_addition_info_add=e}
                            />
                            <label  className='label mb-1' htmlFor="">Preview Image</label>
                            <input required={true} id='preview_image' className='input'  accept="image/*" type="file"/>
                            <label  className='label mb-1' htmlFor="">Secondary Image</label>
                            <input required={true} id='secondary_image'  className='input' accept="image/*" type="file"/>
                            <labe className="label mb-1">Book pdf</labe>
                            <input type="file" id="books_pdf" className="input" />
                            <h1 className='my-4' id='image_warning'>Please choose only Image Types !!</h1>
                            <button  className="button mt-3 is-primary is-medium">Submit</button>
                            {/* <h1 className='my-4' id='pdf_warning'>Please choose only Document Types !!</h1> */}
                        </form>
                    </section>
                    
                </div>
            </div>
          
        )
        function add_books(e){
            e.preventDefault()
            let books_title = document.getElementById('books_title').value;
            let author_name = document.getElementById('author_name').value;
            let price = document.getElementById('price').value;
            let summary = document.getElementById('summary').value;
            let preview_image = document.getElementById('preview_image').files[0];
            let secondary_image = document.getElementById('secondary_image').files[0];

            let books_pdf = document.getElementById('books_pdf').files[0]
            let preview_image_type = preview_image.type.substr(0,5);
            let secondary_image_type = secondary_image.type.substr(0,5);
            

            if(preview_image_type==='image' && secondary_image_type==='image'){
                //validation passed (image types)
                window.show_loader()
                document.getElementById('image_warning').style.display='none'
                
                const formdata = new FormData()
                formdata.append("books_title",books_title)
                formdata.append("author_name",author_name)
                formdata.append("price",price)
                formdata.append("summary",summary)
                const preview_image_name = `${author_name}_${books_title}_${preview_image.name}`
                const secondary_image_name = `${author_name}_${books_title}2_${secondary_image.name}`
                formdata.append('preview_image_name',preview_image_name)
                formdata.append('secondary_image_name',secondary_image_name)
                formdata.append("books_resources",preview_image,preview_image_name)
                formdata.append("books_resources",secondary_image,secondary_image_name)
                formdata.append('book_description',book_description_add)
                formdata.append('book_additional_info',book_addition_info_add)

                if(books_pdf){
                    const books_pdf_name = `${author_name}_${books_title}book_${books_pdf.name}`
                    formdata.append('books_pdf_name',books_pdf_name)
                    formdata.append('books_resources',books_pdf,books_pdf_name)
                }
                
                

                axios.post(window.server_url+'/books',formdata)
                .then(response=>{
                    set_re_render(response.data)
                    let success_btn = document.getElementById('success_submit');
                    success_btn.style.zIndex=100
                    setTimeout(()=>success_btn.style.zIndex=-100,3200)
                })
                .catch(err=>console.log(err))
            }
            else{
                document.getElementById('image_warning').style.display='block'
            }
        }
    }

    const EditBooks = ({id})=>{
        for(let i = 0;i<books_obj.length;i++){
            if(books_obj[i].id===id){
                book_description_edit=books_obj[i].description 
                book_addition_info_edit = books_obj[i].additional_information
                var title = books_obj[i].title
                var author = books_obj[i].author
                var summary = books_obj[i].summary
                var price = books_obj[i].price

            }
        }
        return(
            <div id='edit_books_container' class={is_visible_edit_books}>
                <div class="modal-background"></div>
                <div id='modal_card' class="modal-card">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Add Journal Page !</p>
                        <button onClick={()=>{
                            set_is_visible_edit_books('modal')
                        }} class="delete" aria-label="close"></button>
                    </header>
                    <section class="modal-card-body">
                        {/* <!-- Content ... --> */}
                        <form onSubmit={(e)=>edit_books(e)}>
                            <label  className='label' htmlFor="">Book Title</label>
                            <input defaultValue={title} id='books_title_edit' type="text" className='input mb-1' />
                            <label  className='label' htmlFor="">Author Name</label>
                            <input defaultValue={author} id='author_name_edit' type="text" name=""  className="input mb-1" />
                            <label  className='label' htmlFor="">Price</label>
                            <input defaultValue={price} id='price_edit' type="number" className="input mb-1"  />
                            <label  className='label' htmlFor="">Summary</label>
                            <textarea defaultValue={summary} name="" id="summary_edit" cols="40" rows="5" className="textarea mb-1"></textarea>
                            <label className='label mb-1'  htmlFor="">Description</label>
                            <JoditEditor
                                config={config}
                                onChange={(e)=>book_description_edit=e}
                                value={book_description_edit}
                            />
                            <label className='label my-3' htmlFor="">Additional Information</label>
                            <JoditEditor
                                config={config}
                                onChange={(e)=>book_addition_info_edit=e}
                                value={book_addition_info_edit}
                            />
                            <label  className='label mb-1' htmlFor="">Preview Image</label>
                            <input id='preview_image_edit' className='input'  accept="image/*" type="file"/>
                            <label  className='label mb-1' htmlFor="">Secondary Image</label>
                            <input id='secondary_image_edit'  className='input' accept="image/*" type="file"/>
                            <labe className="label mb-1">Book pdf</labe>
                            <input type="file" id="books_pdf_edit" className="input" />
                            <h1 className='my-4' id='image_warning_edit'>Please choose only Image Types !!</h1>
                            <button  className="button mt-3 is-primary is-medium">Submit</button>
                        </form>
                    </section>
                    
                </div>
            </div>
          
        )
        
        function edit_books(e){
            e.preventDefault()
            let verification = true
            let books_title = document.getElementById('books_title_edit').value;
            let author_name = document.getElementById('author_name_edit').value;
            let price = document.getElementById('price_edit').value;
            let summary = document.getElementById('summary_edit').value;
            let preview_image = document.getElementById('preview_image_edit').files[0];
            let secondary_image = document.getElementById('secondary_image_edit').files[0];

            let books_pdf = document.getElementById('books_pdf_edit').files[0]
            
            
            if(preview_image){
                let preview_image_type = preview_image.type.substr(0,5);
                if(preview_image_type!=='image'){
                    verification = false
                    document.getElementById('image_warning_edit').style.display='block'
                }
            }
            if(secondary_image){
                let secondary_image_type = secondary_image.type.substr(0,5);

                if(secondary_image_type!=='image'){
                    verification=false
                    document.getElementById('image_warning_edit').style.display='block'

                }


            }
            if(verification){
                window.show_loader()
                document.getElementById('image_warning_edit').style.display='none'
                const formdata = new FormData()
                formdata.append("books_title",books_title)
                formdata.append("author_name",author_name)
                formdata.append("price",price)
                formdata.append("summary",summary)
                formdata.append('book_description',book_description_add)
                formdata.append('book_additional_info',book_addition_info_add)
                if(books_pdf){
                    const books_pdf_name = `${author_name}_${books_title}book_${books_pdf.name}`
                    formdata.append('books_pdf_name',books_pdf_name)
                    formdata.append('books_resources',books_pdf,books_pdf_name)
                }
                if(preview_image){
                    const preview_image_name = `${author_name}_${books_title}_${preview_image.name}`
                    formdata.append('preview_image_name',preview_image_name)
                    formdata.append("books_resources",preview_image,preview_image_name)
                }
                if(secondary_image){
                    const secondary_image_name = `${author_name}_${books_title}2_${secondary_image.name}`
                    formdata.append('secondary_image_name',secondary_image_name)
                    formdata.append("books_resources",secondary_image,secondary_image_name)
                }
                axios.put(window.server_url+'/books/'+id,formdata)
                .then(response=>{
                    set_re_render(response.data)
                    let success_btn = document.getElementById('success_submit');
                    success_btn.style.zIndex=100
                    setTimeout(()=>success_btn.style.zIndex=-100,3200)
                })
                .catch(err=>console.log(err))
            
            }
        }
    }
    return(
        <div id='books_list_cont'>
            <div id={`success_submit`} className="button is-large is-success">Successfully Uploaded!!</div>

            <button onClick={()=>{
                document.getElementById('add_books_container').classList.add('is-active')
            }} id="add_new_journal_page" className='button is-warning'>
                Add New Journal Page
            </button>
            <table className="back_journal_table">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Title</th>
                        <th>Options</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        books_obj.map(obj=>(
                            <tr key={obj.id}>
                                <td>{obj.id}</td>
                                <td>{obj.title}</td>
                                <td>
                                    <button onClick={()=>{
                                        setEditBookId(obj.id)
                                        set_is_visible_edit_books('modal is-active')
                                        }} className="button is-info">
                                        <AiFillEdit/>
                                    </button>
                                    <button onClick={()=>deleteBooks(obj.id)} className="button is-danger">
                                        <AiFillDelete/>
                                    </button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            
            <AddBooks/>
            <EditBooks
                id={editBookId}
            />
        </div>
    )

}

export default Books_list;


