import axios from 'axios';
import { useEffect, useState } from 'react';
import { Collapser, Panel, Trigger } from 'react-collapser';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Select from 'react-select';
import '../../css/front_page/footer.css';
import {Link} from 'react-router-dom';
import {FaUserAlt,FaTwitter,FaFacebookF,FaLinkedinIn, FaJournalWhills} from 'react-icons/fa'
import {SiGmail} from 'react-icons/si'

const Footer = () =>{
    const [journal_obj,set_journal_obj] = useState([]);
    const [cats,set_cats] = useState([])
    const [desktop_journo_cat,set_desktop_journo_cat] = useState()
    const [mobile_journo_cat,set_mobile_journo_cat] = useState()

    useEffect(()=>{
        window.show_loader()

        axios.all([
            axios.get(window.server_url+'/journals/journal_intro'),
            axios.get(window.server_url+'/cats')
        ])
        .then(axios.spread((...responses)=>{
            set_journal_obj(responses[0].data)
            set_cats(responses[1].data)
            set_desktop_journo_cat(responses[1].data[0].id)
            set_mobile_journo_cat(responses[1].data[0].id)

            window.hide_loader()
        }))
        .catch(error=>console.log(error))
    },[])
    let options=[]
    cats.map(obj=>{
        let option_obj = {}
        option_obj.label=obj.category;
        option_obj.value=obj.id;
        options.push(option_obj);
    })

    return(
        <footer id='footer_container'>
            <div id='success_submit' className="button is-large is-success">Successfully Uploaded!!</div>

            {/* pop up form for feedback */}
            <div id='feedback_popup' className="modal">
                <div className="modal-background"></div>
                <div className="modal-content">
                <div className="card">
                    <div className="card-content">
                        <form onSubmit={(e)=>submit_feedback(e)} className="content">
                            <h1 className="title">Feedback &amp; Suggestions</h1>
                            <div className="field">
                                <label htmlFor="" className="label">Name</label>
                                <div className="control has-icons-left">
                                    <input required={true} type="text" name="" id="name" className="input" />
                                    <span className="icon is-small is-left">
                                        <FaUserAlt color='#00b4d8'/>
                                    </span>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="" className="label">E-mail</label>
                                <div className="control has-icons-left">
                                    <input required={true} type='email' name="" id="email" className="input" />
                                    <span className="icon is-small is-left">
                                        <SiGmail color='red'/>
                                    </span>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="" className="label">Journal related feedback</label>
                                <div className="control has-icons-left">
                                    <div className="select">
                                        <select required={true} name="" id="journal_name">
                                            {
                                                journal_obj.map((obj,index)=>(
                                                    <option key={`journal_name ${index}`} value={obj.journal_name}>{obj.journal_name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <span className="icon is-small is-left">
                                        <FaJournalWhills color='#8ac926'/>
                                    </span>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="" className="label">Your feedback</label>
                                <div className="control">
                                    <textarea required={true} id='feedback_msg' className="textarea" />
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                <label htmlFor="agree_conditions" className="checkbox">
                                    <input required={true} type="checkbox" name="" id="agree_conditions" />
                                    &nbsp; I agree A.I.P can publish my testimonial and my name on the web site.

                                </label>
                                </div>
                    
                            </div>
                            <button className="button is-success">Submit</button>
                        </form>
                    </div>
                </div>
                
                </div>
                <button onClick={()=>{
                    document.getElementById('feedback_popup').classList.remove('is-active')
                }} className="modal-close is-large"></button>
            </div>
            {/* //////////////////////////// */}
            <div style={{backgroundImage:'url(/resources/front_page/footer.jpg)'}} id='footer_img'></div>
            <Carousel  infiniteLoop={true} showThumbs={false} >
                <div id='footer_carousel'>
                    <h1>Feedback</h1>
                    <p>We are keen to hear what you think about Ai Publishers.</p>
                    <button onClick={()=>{
                        document.getElementById('feedback_popup').classList.add('is-active')
                    }}>Feedback, Suggestions, questions?</button>
                </div>
                <div id='footer_carousel'>
                    <h1>About Ai Publishers</h1>
                    <p>AiPublisher.org is a platform for peer-reviewed, scientific open-access journals operated by Ai Publishers</p>
                    <Link to='/about'><button>About Ai Publishers</button></Link>
                </div>
            </Carousel>
                {/* two components in the div for mobile and desktop view */}
            <div id='footer_journal_index'>
                <div style={{marginTop:'10px'}} className='columns' id='footer_journal_index_desktop'>
                    <div className='column is-4'>
                        <h1>Journals by Subject</h1>
                        {
                            cats.map(obj=>{
                                return(
                                    <h2 key={`${obj.id}`} onClick={()=>{
                                        set_desktop_journo_cat(obj.id)
                                    }}>{obj.category}</h2>
                                )
                                
                            })
                        }
                    </div>
                    <div className='column'>
                        <div  className='columns is-multiline'>

                            {
                                journal_obj.map((obj,index)=>{
                                    if(desktop_journo_cat===obj.journal_cat_id){
                                        return(
                                            <div key={`footer_desktop ${obj.id}`} className="column is-one-third">
                                                <Journal_list  journal_icon={obj.id} journal_abbrevation={obj.journal_abbrevation} journal_slug={obj.journal_slug} is_desktop={true}/>      
                                            </div>
                                        )
                                    }
                                       
                                        
                                   
                                })
                            }
                        </div>
                     

                    </div>
                </div>
            
                <div id="footer_journal_index_mobile">
                    
                    <Collapser >
                        <Trigger id='footer_mobile_trigger'>Journals by subject</Trigger>
                        <Panel>
                            <Select options={options} onChange={e=>{
                                set_mobile_journo_cat(e.value)
                            }}/>
                            {
                            journal_obj.map(obj=>{
                                if(obj.journal_cat_id===mobile_journo_cat){
                                    return(
                                        <Journal_list key={`footer_mobile ${obj.id}`} journal_icon={obj.id} journal_abbrevation={obj.journal_abbrevation} journal_slug={obj.journal_slug} is_desktop={false}/>      

                                    )
                                }
                            })
                        }
                            
                        </Panel>
                        
                    </Collapser>
                    {/* inline styling collapser , trigger cause couldn't set them individually */}
                    {/* this is actually the styling for about.js real styling is commented below, real styling commented because its affecting about.js style */}
                    
                    
                    <style>{`
                            .collapse {
                                color: rgba(0, 0, 0, 0.65);
                                line-height: 1.5;
                                background-color: #454c66;
                                // border: 1px solid #d9d9d9;
                                border-radius: 3px;
                                border-bottom: 0;
                                
                                
                              }
                            .collapse-trigger {
                                font-family:monospace;
                                position: relative;
                                padding: 18px 16px;
                                color: white;
                                line-height: 22px;
                                // border-bottom: 1px solid #d9d9d9;
                                transition: 0.3s;
                                cursor: pointer;
                                font-size:1rem;
                                // font-weight:500;
                                text-indent: -51px;
                                margin-left: 51px;
                                
                                
                                
                              }
                            .collapse-trigger:hover {
                                color: white;
                              }
                            .collapse-trigger:before {
                                content: '';
                                border: solid white;
                                border-width: 0 1px 1px 0;
                                display: inline-block;
                                padding: 4.5px;
                                margin-right: 12px;
                                transform: rotate(-45deg);
                                position: relative;
                                top: -4px;
                                left:-2px;
                                transition: 0.3s;
                                


                              }
                            .collapse-trigger[aria-expanded=true]:before {
                                transform: rotate(45deg);
                                left: 0;
                              }
                            .collapse-trigger[aria-disabled=true] {
                                opacity: 0.4;
                                cursor: auto;
                              }
                            .collapse-panel {
                                padding: 1px 0px;
                                overflow: hidden;
                                color: rgba(0, 0, 0, 0.65);
                                background-color: #fff;
                                transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
                              }
                            .collapse-panel[aria-expanded=true] {
                                border-bottom: 1px solid #d9d9d9;
                                padding: 16px;
                              }
                        `}
                    </style>

                </div>
            </div>
       
            <div style={{margin:'0'}} className='columns is-multiline' id='footer_footer'>
                <div className="column one-fifths footer_footer_containers">
                    <h1>About AiPublishers</h1>
                    <p className='text_justify'>Addaiyan International Publishers established in 2016 with the sole aim of providing a reliable platform for "Open Access" to the reasearcher's information pertaining to different subjects and disciplines of modern scientific research all over the world and today we are one of the leading Publishers in the market.<a id='about_us_link' href='/about'>Read More</a> </p>
                </div>
                <div className="column one-fifths footer_footer_containers">
                    <h1>Information</h1>
                    <Link className='footer_link' to="/article-processing">Article Processing Charges</Link><br/>
                    <Link className='footer_link' to='/payments'>Payment Instructions</Link><br/>
                    <Link className='footer_link' to='/open-access-policy' href="">Open Access Policy</Link><br/>
                    <Link className='footer_link' to='/contact'>Contact</Link>
                    <br /><br />
                    <div>
                    <a rel='license' href="http://creativecommons.org/licenses/by/4.0/">
                        <img src="https://i.creativecommons.org/l/by/4.0/80x15.png" alt="Creative Commons License" style={{borderWidth:'0'}} />
                    </a>
                    <br />
                    This work is licensed under a 
                    <a style={{color:'#86aff1'}} rel="license" href="http://creativecommons.org/licenses/by/4.0/"> Creative Commons Attribution 4.0 International License</a>
                    </div>
                    
                </div>
                <div className="column one-fifths footer_footer_containers">
                    <h1>Important LINKS</h1>
                    <Link className='footer_link' to='/submit'>Submit Manuscript</Link><br/>
                    <Link className='footer_link' to="/peer-review-policy">Peer Review Process</Link><br/>
                    <Link className='footer_link' target='_blank' download to="/copyright_form.pdf">Copyright Form</Link><br/>
                    <Link className='footer_link' to='/privacy-policy'>Privacy Policy</Link><br/>
                    <Link className='footer_link' to='/join-as-editor'>Join As Editor</Link>
                </div>
                <div className="column one-fifths footer_footer_containers">
                    <h1>Our Contact Info</h1>
                    <p className='text_justify'>House No. 275, Uttar Ashinagar, Hojai, Assam (India) Post Box No. 782445</p>
                    <a href="">+91 6000746173</a><br/>
                    <a href='https://editor@aipublisher.org'>editor@aipublisher.org</a><br/>
                    <a href="https://myaipublisher@gmail.com">myaipublisher@gmail.com</a>
                </div>
                <div className="column one-fifths footer_footer_containers">
                    <h1>Follow</h1>
                    <ul id='contact_us2'>
                                    <li>
                                        <a href="https://twitter.com/aipublisher">
                                            <h2>
                                                Twitter
                                            </h2>
                                            <FaTwitter color='#1c9ceb'/> 
                                            
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://in.linkedin.com/in/addaiyan-international-664514191/?originalSubdomain=in">
                                            <h2>
                                                linkedin
                                            </h2>
                                            <FaLinkedinIn color='#0073b1'/> 

                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://facebook.com/aipublisher">
                                            <h2>
                                                facebook
                                            </h2>
                                            <FaFacebookF color='#38529a'/> 

                                        </a>
                                    </li>
                                </ul>
                </div>
                
            </div>
            <div id='footer_footer_last'>
                <h6>&#169; 2018 AI publishers</h6>
                <a href="">Disclaimer</a>
                <a href="">Terms &amp; Conditions</a>
                <Link to='/privacy-policy'>Privacy Policy</Link>
            </div>
        </footer>
    )
}

export default Footer;

function submit_feedback(e){
    e.preventDefault()
    window.show_loader()
    let name = document.getElementById('name').value;
    let email = document.getElementById('email').value;
    let journal_name = document.getElementById('journal_name').value;
    let feedback_msg = document.getElementById('feedback_msg').value;

    let jsondata = {
        name:name,
        email:email,
        journal_name:journal_name,
        feedback_msg:feedback_msg
    }
    axios.post(`${window.server_url}/feedback`,jsondata)
    .then(()=>{
        //clear all values and show success
        window.hide_loader()
        document.getElementById('success_submit').style.zIndex=100
        setTimeout(()=>document.getElementById('success_submit').style.zIndex=-100,2000)

        document.getElementById('name').value=""
        document.getElementById('email').value=""
        document.getElementById('journal_name').value=""
        document.getElementById('feedback_msg').value=""

    })
    .catch(err=>console.log(err))
}

const Journal_list = ({journal_icon,journal_abbrevation,journal_slug,is_desktop}) =>{
    if(is_desktop){
        return(
            <div id='footer_journal_piece'>
                <Link to={`/${journal_slug}/Home`}>
                    <div className="level">
                        <img src={`/resources/journal_icons/${journal_icon}.png`} alt="" />
                        <h4 className='level-item'>{journal_abbrevation}</h4>
                    </div>
                </Link>
            </div>
        )
    }
    else{
        return(
            <div id='footer_journal_piece_2'>
                <Link to={`/${journal_slug}/Home`}>
                    <div style={{justifyContent:'space-around'}}  className='level'>
                        <img src={`/resources/journal_icons/${journal_icon}.png`} alt="" />
                        <h4>{journal_abbrevation}</h4>
                    </div>
                </Link>
            </div>
        )
    }
    
}















