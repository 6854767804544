import '../../css/journals/journals.css';
import Header from '../front_page/header_front';
import Select from 'react-select';
import { useEffect,useState } from 'react';
import axios from 'axios';
import { Journal_list } from '../front_page/Main';
import { Link } from 'react-router-dom';
import Footer from '../front_page/footer';

const Journals=()=>{
    let options=[
        {
            label:'All',value:'All'
        }
    ];
    const[cat_array,set_cat_array] = useState([]);
    const [journal_obj,set_journal_obj] = useState([]);
    const [filter_cat,set_filter_cat] = useState('All');
    useEffect(()=>{
    window.show_loader()

        axios.all([
            axios.get(window.server_url+'/journals/journal_intro'),
            axios.get(window.server_url+'/cats')

        ])
        .then(axios.spread((journal_response,cat_response)=>{
            set_journal_obj(journal_response.data);
            set_cat_array(cat_response.data);
            window.hide_loader()
        }))
        .catch(error=>console.log(error))
    },[])
    cat_array.map(obj=>{
        let option_obj={};
        option_obj.label=obj.category;
        option_obj.value=obj.category;
        options.push(option_obj);
    })
    return(
        <div>
            <Header/>
            <div id="main_tile" className="is-ancestor is-parent tile">
                <div className='tile is-3 is-parent'>
                    <div id='main_first_tile_child' className='notification is-white is-child'>
                        <h1>Open Access Journals</h1>
                        <Select id='main_Select' options={options} onChange={e=>{
                            if(e.value==='All')
                                set_filter_cat(e.value)
                            else{
                                for(let i =0;i<cat_array.length;i++){
                                    if(e.value===cat_array[i].category){
                                        set_filter_cat(cat_array[i].id)
                                    }
                                }
                            }
                        } 
                    }/>
                    {
                        // displaying journal names with icons in a loop gotten from the data
                        journal_obj.map(obj=>{
                                if(filter_cat==='All')
                                    return <Journal_list key={obj.id} journal_icon={obj.id} journal_abbrevation={obj.journal_abbrevation} journal_slug={obj.journal_slug}/>      
                                else if(filter_cat===obj.journal_cat_id)
                                    return <Journal_list key={obj.id} journal_icon={obj.id} journal_abbrevation={obj.journal_abbrevation} journal_slug={obj.journal_slug}/>      
                                }
                               
                        )
                    }
                    </div>
                </div>
                <div className='tile is-parent'>
                    <div id='journals_list_cont' className="is-child">
                        <div>
                        <table id='journals_table'>
                            <thead>
                                <tr>
                                    <th><h2>#</h2></th>
                                    <th><h2>Journal Name</h2></th>
                                    <th style={{minWidth:'80px'}}><h2>ISSN</h2></th>
                                    <th><h2>Start</h2></th>
                                    <th><h2>Tot. Articles</h2></th>
                                    <th>
                                        <h2>Cover</h2>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    journal_obj.map((obj,index)=>{
                                        
                                            return(
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td style={{color:'green'}}>
                                                        <Link to={`/${obj.journal_slug}/Home`}>
                                                            {obj.journal_name}
                                                        </Link>   
                                                    </td>
                                                    <td style={{width:'100px'}}>{obj.issn}</td>
                                                    <td>{obj.date.substr(0,10)}</td>
                                                    <td>{obj.total_articles}</td>
                                                    <td>
                                                        <img className='journal_front_image' src={`/resources/journal_icons/${obj.id}.png`} alt="" />
                                                    </td>
                                                </tr>
                                            )
                                    })
                                }
                            </tbody>
                        </table>
                        </div>
                        
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Journals;