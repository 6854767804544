import JoditEditor from 'jodit-react';
import '../../../css/back_office/article_back.css'
import axios from 'axios';
import {BiAddToQueue} from 'react-icons/bi';
import {AiFillDelete} from 'react-icons/ai';
import { useState,useEffect } from 'react';
import AsyncSelect from 'react-select/async'

const Article_back = ()=>{

    const config = {
        uploader:{
            "insertImageAsBase64URI": true
        },
        toolbarAdaptive:false
    }
    const abstract_config = {
        toolbarAdaptive:false
    }
    const [doi_num_list,set_doi_num_list] = useState([]);
    const [journal_name_and_id,set_journal_name_and_id] = useState([]);
    const [check_doi,set_check_doi] = useState(null);
    const [re_render,set_re_render] = useState();
    const [volume_and_issue,set_volume_and_issue] = useState([])
    useEffect(()=>{
    window.show_loader();
        axios.all([
            axios.get(`${window.server_url}/journals/journal_name_and_id`),
            axios.get(`${window.server_url}/article_db/doi_numbers`),
            axios.get(window.server_url+'/volume_and_issue')
        ])
        .then(axios.spread((...responses)=>{
            set_journal_name_and_id(responses[0].data);
            set_doi_num_list(responses[1].data);
            set_volume_and_issue(responses[2].data)
            window.hide_loader();
        }))
        .catch(err=>console.log(err))
    },[check_doi])

    console.log(volume_and_issue)
    return(
        <div id='article_back_container'>
            <div id='success_submit' className="button is-large is-success">Successfully Uploaded!!</div>
            <h1>Add Article</h1>
            <form onSubmit={(e)=>submit_form(e,doi_num_list,set_check_doi,journal_name_and_id)}>
                <label htmlFor="art_journo_name">Journal Name</label>
                <select onChange={()=>set_re_render(Math.random)} className='input' id="art_journo_name">
                    {
                        journal_name_and_id.map(journal_name_obj=>(
                            <option key={`${journal_name_obj.journal_name}`} value={`${journal_name_obj.id}`}>{`${journal_name_obj.journal_name}`}</option>
                        ))
                    }
                </select>
                <label htmlFor="select_art_volume">Volume</label>
                <select onChange={()=>set_re_render(Math.random)} className='dyno_select select' id="select_art_volume">
                    {
                        volume_and_issue.length && volume_and_issue.map(obj=>{
                            let selected_journal_id = document.getElementById('art_journo_name').value
                            if(obj.journal_id==selected_journal_id){
                                return(
                                    <option value={obj.volume} key={`${obj.volume}`}>{obj.volume}</option>
                                )
                            }
                        })
                    }
                    
                </select>
                <label htmlFor="select_art_volume">Issue</label>

                <select className='dyno_select select' id="select_art_issue">
                    {
                        volume_and_issue.length && volume_and_issue.map(obj=>{
                            let selected_volume = document.getElementById("select_art_volume").value
                            let selected_journal_id = document.getElementById('art_journo_name').value

                            if(selected_volume==obj.volume && selected_journal_id==obj.journal_id){
                                let results = []
                                for(let i = 1;i<=obj.max_issue;i++){
                                    results.push(
                                        <option key={i} value={i} >{i}</option>
                                    )
                                }
                                return results;
                            }
                        })
                    }
                </select>
                <label htmlFor='art_year'>Year</label>
                <input required = {true} className='input' type="number" name="" id="art_year"/>
                <label htmlFor="art_month">Month</label>
                <input required = {true} className='input' type="text" name="" id="art_month" />
                <label htmlFor="art_volume">Add new volume</label>
                <input className='input' type="text" id="art_volume" />
                <label htmlFor="art_issue">Add new issue</label>
                <input className='input' type="text" id="art_issue" />
                <label htmlFor="art_title">Article Title</label>
                <input required = {true} className='input' type="text" name="" id="art_title" />
                <label htmlFor="art_author_name">Author Name</label>
                <input required = {true} className='input' type="text" name="" id="art_author_name" />
               
                <label htmlFor="">Date of Issue</label>
                <input required = {true} className='input' type="date" name="" id="art_date" />
                <label htmlFor="">Page No.</label>
                <input type="text" required={true} className='input' id='page_no' />
                <label htmlFor="">Abbrevation</label>
                <input type="text" className="input" id="article_abbrevation" />
                
                <label htmlFor="">Abstract</label>
                <JoditEditor
                    config={abstract_config}
                    onChange = {e=>abstract_content=e}
                    value={abstract_content}
                />
                <label htmlFor="art_doi">DOI Number</label>
                <input required={true} className='input' type="text" name="" id="art_doi" />
                <div id="used_doi_num">DOI is already used (give specific DOI's for each Article)</div>
                <label htmlFor="">Upload HTML CODE</label>
                <JoditEditor
                    config={config}
                    onChange={e=>html_content=e}
                    value={html_content}
                />
                <label htmlFor="">Upload pdf of the File</label>
                <input className='input' type="file" name="" id="art_file" />
                <h3 id="incorrect_extension">Extension is not Supported!</h3>
                <section>
                    <div id="art_add_cont">
                        <h2>Upload carousel Images seperately</h2>
                        <button type='button' onClick={()=>add_fileinput()}>
                            <BiAddToQueue size='40px' color='#90e0ef'/>
                        </button>
                        <button type='button' onClick={()=>delete_fileinput()}>
                            <AiFillDelete size='40px' color='#ef233c'/>
                        </button>
                    </div>
                    
                    <div id="art_multiple_input_cont">
                       
                    </div>

                </section>
                <input className='button is-danger mt-3' type="submit" value="Submit" />

            </form>
        </div>
    )
}
let file_counter = 0;
let abstract_content='',html_content='';
function add_fileinput(){
    let input_container = document.getElementById('art_multiple_input_cont');
    file_counter++;
    input_container.innerHTML+=`<span><label>Image ${file_counter}</label> <input class='art_slider' type="file"/><h3 class="art_slider_err_msg">Please upload Image files (png,jpg,jpeg...)</h3></span>`;
}
function delete_fileinput(){
    let input_container = document.getElementById('art_multiple_input_cont');
    if(input_container.children.length>0){
        input_container.removeChild(input_container.lastChild);
        file_counter--;

    }

}
const submit_form = (e,doi_num_list,set_check_doi,journal_name_and_id)=>{
    let validation = true;
    e.preventDefault();
    let select_volume = document.getElementById('select_art_volume').value;
    let select_issue = document.getElementById('select_art_issue').value;
    const year = document.getElementById('art_year').value;
    const month = document.getElementById('art_month').value;
    const page_no = document.getElementById('page_no').value
    const article_abbrevation = document.getElementById('article_abbrevation').value

    let new_volume;
    try{
        new_volume=document.getElementById('art_volume').value;
    }
    catch{
        new_volume=""
    }
    let new_issue;
    try{
    new_issue = document.getElementById('art_issue').value;
    }
    catch{
        new_issue=""
    }
    const article_title = document.getElementById('art_title').value;
    const author_name = document.getElementById('art_author_name').value;
    const journal_name = document.getElementById('art_journo_name').value;
    //comparing select volume issue and added volume issue values
    let volume,issue;
    if(new_volume==""||new_volume<select_volume)
        volume = select_volume;
    else
        volume=new_volume;
    if(new_issue==""||new_issue<select_issue)
        issue = select_issue
    else
        issue = new_issue;
    //setting journal id based on selected journal name
    let journal_id = journal_name ;
 
    const date = document.getElementById('art_date').value;
    const doi = document.getElementById('art_doi').value;
    const art_file = document.getElementById('art_file').files[0];
    
    let art_file_extension;
    //validating
    //validation of article file
    if(art_file!==undefined){
        let art_file_dot_array = art_file.name.split('.');
        art_file_extension = art_file_dot_array[art_file_dot_array.length-1];
        if(art_file_extension!=='doc' && art_file_extension!=='docm' && art_file_extension!=='docx' && art_file_extension!=='dotm' && art_file_extension!=='dotx' && art_file_extension!=='odt' && art_file_extension!=='pdf' && art_file_extension!=='xml'){
            validation=false;
            document.getElementById('incorrect_extension').style.display='block';
        }
        else
            document.getElementById('incorrect_extension').style.display='none';
    }
    else{
        validation=false;    
        document.getElementById('incorrect_extension').style.display='block';
    }
    
    // validation of DOI
    let doi_err_msg = document.getElementById('used_doi_num');
    for(let i = 0;i<doi_num_list.length;i++){
        if(doi===doi_num_list[i].doi_number){
            validation=false;
            doi_err_msg.style.display = 'block';
            break;
        }
        if(i===doi_num_list.length-1)
            doi_err_msg.style.display = 'none';            
    }
    //validation of article_slides
    let slider_inputs = document.getElementsByClassName('art_slider');
    let slider_inputs_err = document.getElementsByClassName('art_slider_err_msg');
    for(let i =0;i<slider_inputs.length;i++){
        let input_file = slider_inputs[i].files[0];
        if(input_file!==undefined && input_file.type.split('/')[0]!=='image'){
            validation=false;
            slider_inputs_err[i].style.display='block';
        }
        else
            slider_inputs_err[i].style.display='none';
    }
    if(validation){
        window.show_loader();
        let formdata = new FormData();
        formdata.append('article_abbrevation',article_abbrevation)
        formdata.append('page_no',page_no)
        formdata.append('year',year);
        formdata.append('month',month);
        formdata.append('volume',volume);
        formdata.append('issue',issue);
        formdata.append('article_title',article_title);
        formdata.append('author_name',author_name);
        // formdata.append('journal_name',journal_name);
        formdata.append('journal_id',journal_id)
        formdata.append('date',date);
        console.log('doidd',doi)
        formdata.append('doi',doi);
        formdata.append('article_resources',art_file,`article_doc${doi.replace(/\//g,'-')}.${art_file_extension}`);
        formdata.append('article_file_name',`article_doc${doi.replace(/\//g,'-')}.${art_file_extension}`)
        if(abstract_content.length===0)
            formdata.append('abstract_content','default abstract content');
        else{
            formdata.append('abstract_content',abstract_content)
        }
        
        //appending article slides
        //copied the exact code from article slider checking
        let slider_counter = 0;
        let slider_inputs = document.getElementsByClassName('art_slider');
        for(let i =0;i<slider_inputs.length;i++){
            let input_file = slider_inputs[i].files[0];
            if(input_file!==undefined){
                //this is the new one
                formdata.append('article_resources',input_file,`${doi}.${slider_counter}.png`);

                slider_counter++;
            }
        }
        formdata.append('slider_count',slider_counter);
        axios.post(`${window.server_url}/article_upload`,formdata)
        .then((response)=>{
            let recent_upload_article_id = response.data[0].max_id
            if(html_content.length===0)
                html_content='default full text'
            axios.put(`${window.server_url}/html_content/${recent_upload_article_id}`,{html_content:html_content})
            .then((response)=>{
                set_check_doi(response.data)

                document.getElementById('success_submit').style.zIndex=100
                setTimeout(()=>document.getElementById('success_submit').style.zIndex=-100,3200)
                // clear all values except journal,issue,volume
                document.getElementById('art_year').value=""
                document.getElementById('art_month').value=""
                document.getElementById('art_volume').value=""
                document.getElementById('art_issue').value=""
                document.getElementById('art_title').value=""
                document.getElementById('art_author_name').value=""
                document.getElementById('art_date').value=""
                document.getElementById('art_doi').value=""
                document.getElementById('art_file').value=null
                document.getElementById('page_no').value=""
                document.getElementById('article_abbrevation').value=""
                html_content=""
                abstract_content=""
                let input_container = document.getElementById('art_multiple_input_cont');
                while(input_container.children.length>0){
                    input_container.removeChild(input_container.lastChild);
                    file_counter--;
                }

            })
            .catch(err=>console.log(err))
        })
        .catch((err)=>console.log(err))
        
    }
}


export default Article_back;
