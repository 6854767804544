import admin_panel_img from '../../../img/admin_panel.png';
import {RiAdminFill,RiArticleFill} from 'react-icons/ri';
import {FaLayerGroup,FaAppStore} from 'react-icons/fa';
import {IoIosJournal} from 'react-icons/io';
import {BiSliderAlt} from 'react-icons/bi';
import {AiFillMacCommand,AiFillDelete} from 'react-icons/ai';
import {useState} from 'react';
import {BsFillEyeFill} from 'react-icons/bs';
import Admin_menu from './admin_menu';
import Journal_cats from './journal_categories';
import Back_journal from './back_journal';
import Slider from './slider'
import Impact_factor_back from './impact_factor_back';
import Article_back from './article_back';
import { Link } from 'react-router-dom';
import {Pending_manu} from './pending_manuscript'
import Declined_manu from './declined_manuscript'
import Approved_manu from './approved_manuscript'
import Newsletter from './newsletter'
import Feedbacks from './feedbacks';
import Editor_requests from './editor_requests'
import {ImBooks} from 'react-icons/im';
import Books_main from './books_main';
import Books_list from './books_list';

const Admin_page = ()=>{
    const [sub_page,set_sub_page] = useState(<Admin_menu/>);
    
    return(
        <div>
            <section id='back_page_navbar_container'>

            <nav id='front_page_navbar' className="navbar is-fixed-top">
                <div className="navbar-brand">
                    <a onClick={navburger_toggle} id='navbar-burger2' className="navbar-burger">
                        <span aria-hidden='true'></span>
                        <span aria-hidden='true'></span>
                        <span aria-hidden='true'></span>
                    </a>
                </div>
                <div id='navbar-menu' className="navbar-menu">
                    <div id='back_navbar_start' className="navbar-start">
                        <div onClick={()=>set_sub_page(<Admin_menu/>)} className="navbar-item">
                            <span className="header_front_icon_cont">
                                <RiAdminFill color={'#99d98c'}/>
                            </span>
                            Admin/user
                        </div>
                        <div onClick={()=>set_sub_page(<Article_back/>)} className="navbar-item">
                            <span className="header_front_icon_cont">
                                <RiArticleFill color={'#e9c46a'}/>
                            </span>
                            Article
                        </div>
                       
                        <span onClick={()=>set_sub_page(<Journal_cats/>)}  className="navbar-item">
                            <span className="header_front_icon_cont">
                                <FaLayerGroup color={'#219ebc'}/>
                            </span>
                            Journal Categories
                        </span>
                        <span onClick={()=>set_sub_page(<Back_journal/>)} className="navbar-item">
                            <span className="header_front_icon_cont">
                                <IoIosJournal color={'#d00000'}/>
                            </span>
                            Journals
                        </span>
                        <span onClick={()=>set_sub_page(<Slider/>)} className="navbar-item">
                            <span className="header_front_icon_cont">
                                <BiSliderAlt color={'yellowgreen'}/>
                            </span>
                            Slider
                        </span>
                        <span onClick={()=>set_sub_page(<Impact_factor_back/>)} className="navbar-item">
                            <span className="header_front_icon_cont">
                                <BsFillEyeFill color={'#3a0ca3'}/>
                            </span>
                            Impact factors
                        </span>
                        <div className="navbar-item has-dropdown is-hoverable">
                            <a href="" className="navbar-link">
                                <span className="header_front_icon_cont">
                                    <ImBooks color={'#d90429'}/>
                                </span>
                                Books
                            </a>
                            <div className="navbar-dropdown">
                                <span onClick={()=>set_sub_page(<Books_main/>)} className='navbar-item' href="">
                                    Books main
                                </span>
                                <span onClick={()=>set_sub_page(<Books_list/>)} className='navbar-item' href="">
                                    Books List
                                </span>
                               
                            </div>
                        </div>
                        <div className="navbar-item has-dropdown is-hoverable">
                            <a href="" className="navbar-link">
                                <span className="header_front_icon_cont">
                                    <AiFillMacCommand color={'#d90429'}/>
                                </span>
                                Manuscripts Status
                            </a>
                            <div className="navbar-dropdown">
                                <span onClick={()=>set_sub_page(<Pending_manu/>)} className='navbar-item' href="">
                                    Pending Manuscripts
                                </span>
                                <span onClick={()=>set_sub_page(<Approved_manu/>)} className='navbar-item' href="">
                                    Approved Manuscripts
                                </span>
                                <span onClick={()=>set_sub_page(<Declined_manu/>)} className='navbar-item' href="">
                                    Declined Manuscripts
                                </span>
                            </div>
                        </div>
                        <div className="navbar-item has-dropdown is-hoverable">
                            <a href="" className="navbar-link">
                                <span className="header_front_icon_cont">
                                    <FaAppStore color={'#0466c8'}/>
                                </span>
                                Miscellaneous
                            </a>
                            <div className="navbar-dropdown">
                                <span onClick={()=>set_sub_page(<Editor_requests/>)} className='navbar-item'>
                                    Editor requests
                                </span>
                                <span onClick={()=>set_sub_page(<Feedbacks/>)} className='navbar-item'>
                                    Feedbacks
                                </span>
                                <span onClick={()=>set_sub_page(<Newsletter/>)} className='navbar-item'>
                                    Newsletter
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            </section>
            {
                sub_page
            }
          
        </div> 
    )
}

export default Admin_page;


function navburger_toggle(){
    let navbar_burger = document.getElementById('navbar-burger2');
    let navbar_menu = document.getElementById('navbar-menu');
    if(navbar_burger.className==='navbar-burger'){
        navbar_burger.className = 'is-active navbar-burger';
        navbar_menu.className = 'is-active navbar-menu';
    }
    else{
        navbar_burger.className='navbar-burger';
        navbar_menu.className='navbar-menu';
    }
}