import { Manuscript_content,Manuscript_list } from "./pending_manuscript";
import {GrOverview} from 'react-icons/gr'
import {TiTickOutline} from 'react-icons/ti'
import {AiOutlineClose} from 'react-icons/ai'
import axios from 'axios'
import { useEffect,useState } from 'react'
import { Link } from 'react-router-dom'

const Declined_manu = ()=>{
    let session = sessionStorage.getItem('sub_page');
    const [declined_manuscripts,set_declined_manuscripts] = useState([])
    const [manuscript_show,set_manuscript_show] = useState(false);
    const [update,set_update] = useState();
    const [manuscript_obj,set_manuscript_obj] = useState([])
    
    useEffect(()=>{
        axios.get(window.server_url+'/manuscripts_intro/declined')
        .then(response=>set_declined_manuscripts(response.data))
        .catch(error=>console.log(error))
    },[update])

    
    useEffect(()=>{
        if(manuscript_show){
            axios.get(`${window.server_url}/manuscript/${manuscript_show}`)
            .then(response=>set_manuscript_obj(response.data))
            .catch(error=>console.log(error))
        }
        
    },[manuscript_show])
    if(session!=='admin_menu'){
        return(
            <h1 style={{fontSize:'100px'}}>error 403! Forbidden</h1>

        )
    }
    
    return(
        <section id="pending_manu_cont">
            <h1>Declined Manuscripts</h1>
            <Manuscript_list
                manuscript_intro_obj = {declined_manuscripts}
                update = {set_update}
                set_manuscript_show = {set_manuscript_show}
            />
            {manuscript_obj.length>0 && manuscript_show && <Manuscript_content manuscript_obj={manuscript_obj}/>}
        </section>
    )
}

export default Declined_manu