import { useState,useEffect } from 'react';
import axios from 'axios';
import {GrOverview} from 'react-icons/gr'
import { AiFillDelete } from 'react-icons/ai';
import { Fragment } from 'react';
import '../../../css/feedbacks.css'

const Feedbacks = ()=>{
    const [feedback_obj,set_feedback_obj] = useState([])
    const [re_render,set_re_render] = useState(null)

    useEffect(()=>{
        window.show_loader()
        axios.get(window.server_url+'/feedback')
        .then(response=>{
            set_feedback_obj(response.data)
            window.hide_loader()
        })
    },[re_render])
    return(
        <div id='feedback_cont'>
            <h1 className="pt-5 has-text-centered has-text-white title">Feedback &amp; Suggestions</h1>
            <table className="back_journal_table">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>Date</th>
                        <th>name</th>
                        <th>email</th>
                        <th>journal name</th>
                        <th>feedback message</th>
                        <th>Options</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        feedback_obj.map(obj=>(
                            <tr>
                                <td>{obj.id}</td>
                                <td>{obj.date}</td>
                                <td>{obj.name}</td>
                                <td>{obj.email}</td>
                                <td>{obj.journal_name}</td>
                                <td style={{minWidth:'500px'}}>{obj.feedback_msg}</td>
                                <td>
                                    <button onClick={()=>delete_feedback(obj.id,set_re_render)} className="button is-danger"><AiFillDelete/></button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}
function delete_feedback(id,set_re_render){
    window.show_loader()
    axios.delete(`${window.server_url}/feedback/${id}`)
    .then(response=>set_re_render(response.data))
    .catch(err=>console.log(err))
}

export default Feedbacks;